<ng-template #otherPopupContainer>
  <div
    id="otherProgramMob"
    #otherProgramMob
    class="modal-fade width-100 margin-bottom-20"
    *ngIf="openModal"
  >
    <div class="card-wrapper wrapper-background-color width-100">
      <div
        class="card-sub-wrapper card-border"
        *ngFor="let program of otherProgramInfo.otherPrograms"
      >
        <div class="card-title">
          {{ program.program }}
        </div>
        <div class="card-other-info-warpper width-100 flex-wrap">
          <div
            class="card-other-info"
            *ngFor="let label of otherProgramInfo.labelInfo"
          >
            <div class="card-header">
              <span>
                {{ label.name }}
              </span>
            </div>
            <div class="card-data">
              <span>{{ program[label.value] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #arrowContainer>
  <div class="total-card-sub-wrapper">
    <div
      class="arrow-wrapper"
      [ngClass]="{
        'open-arrow-wrapper': isOpenTotal,
        'closed-arrow-wrapper': !isOpenTotal
      }"
    >
      <div
        class="arrow-sub-wrapper"
        *ngIf="otherProgramInfo.totalYearWiseInfo.length && openModal"
      >
        <div
          [ngClass]="{
            'open-mode': isOpenTotal,
            'closed-mode': !isOpenTotal
          }"
        >
          <div class="icon-container" (click)="openTotalInfo()">
            <span class="icon-arrow-down"> </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper" *ngIf="isOpenTotal">
      <div class="card-title">{{ otherProgramInfo.popupTotalTitle }}</div>
      <div
        class="card-other-info-warpper width-100"
        [ngClass]="{ 'flex-wrap': otherProgramInfo.isNeedScroll }"
      >
        <div
          class="card-other-info"
          [ngClass]="{ 'width-100': !otherProgramInfo.isNeedScroll }"
          *ngFor="let label of otherProgramInfo.labelInfo; let i = index"
        >
          <div class="card-header">
            <span>
              {{ label.name }}
            </span>
          </div>
          <div class="card-data">
            <span>{{ otherProgramInfo?.totalYearWiseInfo[i] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
