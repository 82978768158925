<div class="faq-query-popup" *ngIf="showPopup" #popupContainer>
  <div class="faq-query-popup-sub-wrap">
    <div class="modal-header">
      <div class="modal-title">
        <h4>Add Organization</h4>
      </div>
      <button (click)="closePopup()">
        <span class="icon-close-icon close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-field">
        <label for="">Enter GUID</label>
        <input type="text" class="form-control" [(ngModel)]="guid" />
      </div>
      <div class="text-success" *ngIf="successMsg">
        <span class="text-success">{{ successContent }}</span>
      </div>
      <div class="text-danger" *ngIf="errorMsg">
        <span class="text-danger">{{ errorContent }}</span>
      </div>
      <div class="form-button">
        <button (click)="insertOrg()">Submit</button>
      </div>
    </div>
  </div>
</div>
