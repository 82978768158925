import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NgTinyUrlModule } from 'ng-tiny-url';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { PpNavComponent } from './core/components/pp-nav/pp-nav.component';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { SFTokenInterceptor } from './core/interceptors/sf-token.interceptor';
import { AuthService } from './core/services/auth.service';
import { CustomReuseStrategy } from './custome-route-reuse-strategy';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ForgotpasswordComponent } from './modules/login/forgotPassword/forgotpassword.component';
import { LoginComponent } from './modules/login/login.component';
import { InformationMaterialsComponent } from './modules/dashboard/lad-information-materials/information-materials/information-materials.component';
import { LadInformationMaterialsComponent } from './modules/dashboard/lad-information-materials/lad-information-materials.component';
import { AlumniProgramComponent } from './modules/dashboard/partner-dashboard/alumni-program/alumni-program.component';
import { ApplicantsToEnrollmentComponent } from './modules/dashboard/partner-dashboard/applicant-enrollment/applicants-to-enrollment.component';
import { LearnerAnalyticsComponent } from './modules/dashboard/partner-dashboard/learner-analytics/learner-analytics.component';
import { PartnerDashboardComponent } from './modules/dashboard/partner-dashboard/partner-dashboard.component';
import { StudentEnrollmentsComponent } from './modules/dashboard/partner-dashboard/student-enrollments/student-enrollments.component';
import { StudentPersistenceComponent } from './modules/dashboard/partner-dashboard/student-persistence/student-persistence.component';
import { ListOfPartnershipComponent } from './modules/dashboard/partnership-overview/list-of-partnership/list-of-partnership.component';
import { PartnershipOverviewComponent } from './modules/dashboard/partnership-overview/partnership-overview.component';
import { ProgramsSliderComponent } from './modules/dashboard/partnership-overview/programs-slider/programs-slider.component';
import { EmailVerficationComponent } from './modules/login/email-verfication/email-verfication.component';
import { SfLoaderComponent } from './modules/sf-loader/sf-loader.component';
import { SharedModule } from './shared/shared.module';
import { UrlTransformPipe } from './shared/pipe/url-transform.pipe';
import { FaqComponent } from './modules/dashboard/faq/faq.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AnticipatedGradDateComponent } from './modules/dashboard/partner-dashboard/anticipated-grad-date/anticipated-grad-date.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from './modules/admin/admin/admin.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PpNavComponent,
    HeaderComponent,
    DashboardComponent,
    PartnerDashboardComponent,
    LoginComponent,
    AlumniProgramComponent,
    ApplicantsToEnrollmentComponent,
    StudentPersistenceComponent,
    LearnerAnalyticsComponent,
    PartnershipOverviewComponent,
    ListOfPartnershipComponent,
    ProgramsSliderComponent,
    StudentEnrollmentsComponent,
    LadInformationMaterialsComponent,
    InformationMaterialsComponent,
    ForgotpasswordComponent,
    EmailVerficationComponent,
    SfLoaderComponent,
    FaqComponent,
    AnticipatedGradDateComponent,
    AdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgTinyUrlModule,
    NgSelectModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    DragScrollModule,
    NgScrollbarModule.withConfig({
      visibility: 'hover',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DragDropModule,
    AngularFireModule,
    AngularFireAnalyticsModule,
    NgbModule,
    FormsModule,
  ],
  providers: [
    AuthService,
    UrlTransformPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SFTokenInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
