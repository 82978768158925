<app-title-divider
  title="Learner Insights"
  [tooltipContent]="tooltipContent"
></app-title-divider>
<ng-container cdkDropListGroup>
  <div
    class="dropList"
    cdkDropList
    [cdkDropListDisabled]="isDropListDisabled"
    (cdkDropListDropped)="drop($event)"
    *ngIf="!contactLoader"
  >
    <div class="edit-cancel-wrap">
      <button class="edit-btn" *ngIf="editOrderTrue" (click)="editOrder()">
        <span class="icon-edit-button me-2"></span>Edit Cards
      </button>
      <button
        class="edit-btn cancelBtn"
        *ngIf="cancelOrderFalse"
        (click)="cancelOrder()"
      >
        Cancel Edit
      </button>
    </div>
    <div
      class="row mt-4"
      [style.order]="state.order"
      *ngFor="let state of items; let i = index"
      cdkDrag
      (cdkDragStarted)="onDragStarted($event, i)"
      [ngClass]="{
        student_enrollment: state.component === 'student_enrollment',
        'mb-20': disableExport,
        anticipated_grad: state.component === 'anticipated_grad_date'
      }"
    >
      <ng-container *ngIf="this.currentWindowWidth <= 1199; else elsetemplate">
        <app-page-container
          *ngIf="
            state.component === 'learner' ||
            state.component === 'applicant' ||
            state.component === 'student_enrollment' ||
            state.component === 'anticipated_grad_date' ||
            state.component === 'alumni' ||
            state.component === 'student'
          "
          [templateRef]="getTemplateRef(state.component)"
        >
        </app-page-container>
      </ng-container>
      <ng-template #elsetemplate>
        <app-page-container
          *ngIf="
            state.component === 'learner' ||
            state.component === 'applicant' ||
            state.component === 'alumni' ||
            state.component === 'student'
          "
          [templateRef]="getTemplateRef(state.component)"
        >
        </app-page-container>
      </ng-template>
    </div>
  </div>
</ng-container>
<div class="loader">
  <div class="spinner-border" role="status" *ngIf="contactLoader"></div>
</div>
<app-footer></app-footer>

<ng-template #leanerTemplate>
  <app-learner-analytics
    [learnerAnalyticsData]="filteredData?.learnerAnalytics"
  ></app-learner-analytics>
</ng-template>
<ng-template #applicantTemplate>
  <div class="col-md-12 d-flex graph-resp">
    <div
      class="col-lg-12 col-md-12 col-xl-6 align-self-stretch applicant-enrollment-wrap"
    >
      <div class="card h-100">
        <app-applicant-enrollment
          [applicantsToEnrollmentData]="filteredData?.applicantsToEnrollment"
        ></app-applicant-enrollment>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-12 col-md-12 col-xl-6 align-self-stretch student-enrollments stud_web"
    >
      <div class="card">
        <app-student-enrollments
          [studentEnrollmentData]="filteredData?.studentEnrollment"
        ></app-student-enrollments>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #studentEnrollmentTemplate>
  <div
    class="col-xl-6 col-lg-12 col-md-12 align-self-stretch student-enrollments stud_mob"
  >
    <div class="card">
      <app-student-enrollments
        [studentEnrollmentData]="filteredData?.studentEnrollment"
      ></app-student-enrollments>
    </div>
  </div>
</ng-template>
<ng-template #studentTemplate>
  <div class="col-md-12 align-self-stretch student-persistence">
    <div class="card">
      <app-student-persistence
        [studentPersistenceData]="filteredData?.studentPersistence"
      ></app-student-persistence>
    </div>
  </div>
</ng-template>
<ng-template #anticipatedGradDateTemplate>
  <div
    class="col-xl-6 col-lg-12 col-md-12 align-self-stretch applicant-enrollment-wrap anti-mob"
  >
    <div class="card h-100">
      <app-anticipated-grad-date
        [anticipatedGradDateData]="filteredData?.anticipatedGradDate"
      ></app-anticipated-grad-date>
    </div>
  </div>
</ng-template>
<ng-template #alumniTemplate>
  <div class="col-md-12 d-flex graph-resp">
    <div
      class="col-xl-6 col-lg-12 col-md-12 align-self-stretch applicant-enrollment-wrap anti-web non-alumni-wrap"
    >
      <div class="card h-100">
        <app-anticipated-grad-date
          [anticipatedGradDateData]="filteredData?.anticipatedGradDate"
        ></app-anticipated-grad-date>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-12 col-md-12 align-self-stretch student-enrollments"
    >
      <div class="card h-100">
        <app-alumni-program
          [alumniData]="filteredData?.alumni"
        ></app-alumni-program>
      </div>
    </div>
  </div>
</ng-template>
