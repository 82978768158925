import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { DataAgoPipe } from './pipe/data-ago.pipe';
import { DoughnutChartComponent } from './components/info-graphic/doughnut-chart/doughnut-chart.component';
import { FilterChipsComponent } from './components/filter-chips/filter-chips.component';
import { FilterPopupModalComponent } from './components/filter-popup-modal/filter-popup-modal.component';
import { HorizontalBarChartComponent } from './components/info-graphic/horizontalbar-chart/horizontal-bar-chart.component';
import { HorizontalBarSpChartComponent } from './components/info-graphic/horizontalbar-sp-chart/horizontal-bar-sp-chart.component';
import { InfoGraphicComponent } from './components/info-graphic/info-graphic.component';
import { MobileFloatingNavComponent } from './components/mobile-floating-nav/mobile-floating-nav.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { PpButtonComponent } from './components/pp-button/pp-button.component';
import { RadioComponent } from './components/radio/radio.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SecondaryNavComponent } from './components/secondary-nav/secondary-nav.component';
import { ProductTourComponent } from './components/product-tour/product-tour.component';
import { SortPipe } from './pipe/sort.pipe';
import { TitleDividerComponent } from './components/title-divider/title-divider.component';
import { UrlTransformPipe } from './pipe/url-transform.pipe';
import { VerticalBarChartComponent } from './components/info-graphic/vertical-bar-chart/vertical-bar-chart.component';
import { FaqModalComponent } from './components/faq-modal/faq-modal.component';
import { FunnelChartComponent } from './components/info-graphic/funnel-chart/funnel-chart.component';
import { PdfModalComponent } from './components/pdf-modal/pdf-modal.component';
import { HalfDoughnutComponent } from './components/info-graphic/half-doughnut/half-doughnut.component';
import { CustomCardFooterComponent } from './components/custom-card-footer/custom-card-footer.component';
import { OtherPopupModalComponent } from './components/other-popup-modal/other-popup-modal.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { OtherProgramMobileFloatComponent } from './components/other-program-mobile-float/other-program-mobile-float.component';
import { BubbleChartComponent } from './components/info-graphic/bubble-chart/bubble-chart.component';
import { VerticalRoundedBarChartComponent } from './components/info-graphic/vertical-rounded-bar-chart/vertical-rounded-bar-chart.component';
import { ExportFilterComponent } from './components/export-filter/export-filter.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ClinicalFilterChipComponent } from './components/clinical-filter-chip/clinical-filter-chip.component';
import { FooterComponent } from '../core/components/footer/footer.component';
import { YearFormatComponent } from './components/year-format/year-format.component';
import { DiversityButtonComponent } from './components/diversity-button/diversity-button.component';
import { DiversityModalComponent } from './components/diversity-modal/diversity-modal.component';
import { AddOrgComponent } from '../modules/admin/admin/add-org/add-org.component';
import { SeFilterPopupComponent } from './components/se-filter-popup/se-filter-popup.component';
import { SeRadioComponent } from './components/se-radio/se-radio.component';

@NgModule({
  declarations: [
    CommonModalComponent,
    DataAgoPipe,
    DoughnutChartComponent,
    FilterChipsComponent,
    FilterPopupModalComponent,
    HalfDoughnutComponent,
    HorizontalBarChartComponent,
    HorizontalBarSpChartComponent,
    InfoGraphicComponent,
    MobileFloatingNavComponent,
    MobileNavComponent,
    PopupModalComponent,
    PpButtonComponent,
    RadioComponent,
    RangeSliderComponent,
    SecondaryNavComponent,
    SortPipe,
    TitleDividerComponent,
    UrlTransformPipe,
    VerticalBarChartComponent,
    ProductTourComponent,
    FaqModalComponent,
    FunnelChartComponent,
    PdfModalComponent,
    CustomCardFooterComponent,
    OtherPopupModalComponent,
    PageContainerComponent,
    OtherProgramMobileFloatComponent,
    BubbleChartComponent,
    VerticalRoundedBarChartComponent,
    ExportFilterComponent,
    DatePickerComponent,
    ClinicalFilterChipComponent,
    FooterComponent,
    YearFormatComponent,
    DiversityButtonComponent,
    DiversityModalComponent,
    AddOrgComponent,
    SeFilterPopupComponent,
    SeRadioComponent,
  ],
  exports: [
    CommonModalComponent,
    DataAgoPipe,
    DoughnutChartComponent,
    FilterChipsComponent,
    FilterPopupModalComponent,
    HalfDoughnutComponent,
    HorizontalBarChartComponent,
    HorizontalBarSpChartComponent,
    InfoGraphicComponent,
    MobileFloatingNavComponent,
    MobileNavComponent,
    PopupModalComponent,
    PpButtonComponent,
    ProductTourComponent,
    RadioComponent,
    RangeSliderComponent,
    SecondaryNavComponent,
    SortPipe,
    TitleDividerComponent,
    UrlTransformPipe,
    VerticalBarChartComponent,
    FaqModalComponent,
    PdfModalComponent,
    CustomCardFooterComponent,
    OtherPopupModalComponent,
    PageContainerComponent,
    OtherProgramMobileFloatComponent,
    ExportFilterComponent,
    ClinicalFilterChipComponent,
    FooterComponent,
    YearFormatComponent,
    DiversityButtonComponent,
    DiversityModalComponent,
    AddOrgComponent,
    SeFilterPopupComponent,
    SeRadioComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    NgScrollbarModule.withConfig({
      visibility: 'hover',
    }),
    ScrollingModule,
    RouterLinkWithHref,
    RouterLinkActive,
    NgSelectModule,
    NgbDatepickerModule,
  ],
})
export class SharedModule {}
