<div
  class="divider mb-2"
  [ngClass]="{
    'info-tab': exportVisible === false,
    'line-width': disableExport
  }"
>
  <h6>
    <small *ngIf="isShowTooltip"> {{ tooltipContent }} </small
    >{{ title | uppercase }}
  </h6>
  <button
    class="export-btn"
    (click)="openPdfDownloadPopup()"
    *ngIf="exportVisible"
    [disabled]="disableExport"
  >
    <span class="icon-download"></span> Export
  </button>
</div>
