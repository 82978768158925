import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { EmailRequest } from '../../components/model/customtypes-model.interface';
import { environment } from '../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.mailUrl;

  constructor(private http: HttpClient) {}

  public sendEmail(emailRequest: EmailRequest): Observable<any> {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.mailHeader).set(
      'Content-Type',
      'application/json'
    );
    return this.http
      .post(`${this.apiUrl}sendMail`, emailRequest, { headers: apiHeader })
      .pipe(map((response) => response));
  }
}
