import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ExcelData } from '../../components/model/customtypes-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  public async exportToExcel(page: string, collectedData: ExcelData[]) {
    const fileName = `${page}.xlsx`;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    if (collectedData.length) {
      await collectedData.forEach(async (sheet: ExcelData) => {
        let sheetWs = await this.createSheet(sheet.data);
        XLSX.utils.book_append_sheet(wb, sheetWs, sheet.sheetName);
      });

      const excelBuffer: any = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      await this.downloadAsExcelFile(excelBuffer, fileName);
    }
  }

  // Creating the new sheet with header
  public async createSheet(data: any[]): Promise<XLSX.WorkSheet> {
    const header = Object.keys(data[0]);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
    return ws;
  }

  // Download function
  public async downloadAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, fileName);
  }
}
