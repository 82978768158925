import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DOCUMENT, Location } from '@angular/common';

@Component({
  selector: 'app-mobile-floating-nav',
  templateUrl: './mobile-floating-nav.component.html',
  styleUrls: ['./mobile-floating-nav.component.scss'],
})
export class MobileFloatingNavComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription = new Subscription();
  public message: string;
  public activeNav: any = [];
  public env = environment.assetDirectory;
  public pdf = false;
  public subNavItems: any[] = [
    {
      route: `/dashboard/learner-insights/${this.sharedService.getOrgId()}`,
      img: `${this.env}/assets/images/dashboard-icon.svg`,
      key: 'learner-insights',
      name: 'Learner Insights',
      isRoute: true,
    },
    {
      route: '/dashboard/partnership-overview',
      img: `${this.env}/assets/images/partnership-icon.svg`,
      key: 'partnership-overview',
      name: 'Partnership Overview',
      isRoute: true,
    },
    {
      isRoute: false,
      img: `${this.env}/assets/images/contact-icon.svg`,
      name: 'Adtalem Contact',
      contact: true,
    },
    {
      isRoute: false,
      img: `${this.env}/assets/images/profile-settings.svg`,
      name: 'Adtalem Settings',
      settings: true,
    },
    {
      isRoute: false,
      img: `${this.env}/assets/images/profile-icon.svg`,
      name: 'Adtalem Profile',
      profile: true,
    },
  ];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ar: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    private location: Location
  ) {}

  public popUp(type: string, index: number) {
    this.activeNav = [];
    this.activeNav[index] = true;
    this.sharedService.isNgScrollReachedMiddle.next(false);
    if (type === 'profile') {
      this.sharedService.menuBarEvent.next(false);
      this.document
        .querySelector(`.mob-burger-wrap>ul>li>a.active`)
        ?.classList?.remove('active');
      this.sharedService.mobNavEvent.next({
        contact: false,
        profile: true,
        settings: false,
      });
    }
    if (type === 'settings') {
      this.sharedService.menuBarEvent.next(false);
      this.document
        .querySelector(`.mob-burger-wrap>ul>li>a.active`)
        ?.classList?.remove('active');
      this.sharedService.mobNavEvent.next({
        contact: false,
        profile: false,
        settings: true,
      });
    }
    if (type === 'contact') {
      this.sharedService.menuBarEvent.next(false);
      this.document
        .querySelector(`.mob-burger-wrap>ul>li>a.active`)
        ?.classList?.remove('active');
      this.sharedService.mobNavEvent.next({
        contact: true,
        profile: false,
        settings: false,
      });
    }
    if (type === 'close') {
      this.document
        .querySelector(
          `.mob-burger-wrap>ul>li>a.${this.subNavItems[index]?.key}.active`
        )
        ?.classList?.remove('active');
      this.document
        .querySelector(
          `.mob-burger-wrap>ul>li>a.${this.subNavItems[index]?.key}`
        )
        ?.classList?.add('active');
      const popup: any = this.document.querySelector('.modal-fade');
      popup?.remove();
      this.sharedService.menuBarEvent.next(false);
      this.sharedService.mobNavEvent.next({
        contact: false,
        profile: false,
        settings: false,
      });
    }
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.sharedService.mobNavEvent.subscribe((value: any) => {
        if (value?.profile) {
          const index = this.subNavItems.findIndex(
            (x: any) => x.name === 'Adtalem Profile'
          );
          this.activeNav = [];
          this.activeNav[index] = true;
        }
      })
    );
    this.activeNav[0] = true;
  }
  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  @HostListener('document : closePopup', ['$event'])
  public onClosePopup(event: any) {
    this.document
      .querySelector(`.mob-burger-wrap>ul>li>a.active`)
      ?.classList?.remove('active');
    const currentUrl = this.location.path();
    if (this.subNavItems[0]?.route == currentUrl) {
      this.document
        .querySelector(`.mob-burger-wrap>ul>li>a.${this.subNavItems[0]?.key}`)
        ?.classList?.add('active');
    } else if (this.subNavItems[1]?.route == currentUrl) {
      this.document
        .querySelector(`.mob-burger-wrap>ul>li>a.${this.subNavItems[1]?.key}`)
        ?.classList?.add('active');
    }
    const popup: any = this.document.querySelector('.modal-fade');
    popup?.remove();
    this.sharedService.menuBarEvent.next(false);
    this.sharedService.mobNavEvent.next({
      contact: false,
      profile: false,
    });
  }
}
