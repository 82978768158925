<ng-container [ngSwitch]="chartType">
  <ng-container *ngSwitchCase="'doughnut'">
    <app-doughnut-chart [doughnutChartData]="datasets"></app-doughnut-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'funnel'">
    <app-funnel-chart [funnelChartData]="datasets"></app-funnel-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'bar'">
    <app-vertical-bar-chart
      [chartDatSets]="datasets"
      [otherProgramDetails]="otherProgramDetails"
      [totalInfo]="totalInfo"
      [totalYearWiseInfo]="totalYearWiseInfo"
      [selectedFilters]="selectedFilters"
    ></app-vertical-bar-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'horizontalbar'">
    <app-horizontal-bar-chart
      class="height-sync"
      [chartDatSets]="datasets"
      [otherProgramDetails]="otherProgramDetails"
    ></app-horizontal-bar-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'horizontalbarsp'">
    <app-horizontal-bar-sp-chart
      [chartDatSets]="datasets"
      [otherProgramDetails]="otherProgramDetails"
    ></app-horizontal-bar-sp-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'halfdoughnut'">
    <app-half-doughnut
      [chartDatSets]="datasets"
      [otherProgramDetails]="otherProgramDetails"
      [labelInfo]="labelInfo"
      [totalYearWiseInfo]="totalYearWiseInfo"
    ></app-half-doughnut>
  </ng-container>
  <ng-container *ngSwitchCase="'bubblebar'">
    <app-bubble-chart
      [labelInfo]="labelInfo"
      [totalInfo]="totalInfo"
      [otherProgramDetails]="otherProgramDetails"
      [totalYearWiseInfo]="totalYearWiseInfo"
      [chartDatSets]="datasets"
    >
    </app-bubble-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'verticalbar'">
    <app-vertical-rounded-bar-chart
      [chartDatSets]="datasets"
      [otherProgramDetails]="otherProgramDetails"
      [labelInfo]="labelInfo"
      [totalInfo]="totalInfo"
      [totalYearWiseInfo]="totalYearWiseInfo"
    ></app-vertical-rounded-bar-chart>
  </ng-container>
</ng-container>
