<div [ngClass]="customClass" class="d-flex chip-container">
  <ng-container *ngFor="let chips of chip; let i = index">
    <div class="chip">
      {{ chips.value }}
      <button
        *ngIf="chips?.closable"
        (click)="removeFilter(chips, i)"
        type="button"
        class="btn-close icon-close-icon"
      ></button>
    </div>
  </ng-container>
</div>
