<div class="bubble-chart-wrap">
  <div class="bubble-chart-left">
    <ul>
      <li *ngFor="let program of programList">{{ program }}</li>
      <div
        class="question-align pointer-cursor margin-left-10 question-icon-container"
        (click)="openOtherProgramPopup()"
      >
        <span class="question-icon-wraper icon-Others-Icon"> </span>
      </div>
    </ul>
  </div>
  <div class="bubble-chart-right">
    <div class="custom-total">
      <div class="total">Total</div>
    </div>
    <div class="bubble-canvas-wrap">
      <div class="bubble-canvas" (scroll)="onScroll($event)">
        <ng-container
          *ngFor="let item of filteredData | keyvalue; let key = index"
        >
          <ul [ngClass]="{ disableColor: colorAccessibilityMode }">
            <h5>{{ calculateSum(item.value) }}</h5>
            <li
              class="{{ program.program }}"
              *ngFor="let program of item.value"
            >
              <span class="nullClass" *ngIf="program.count == 0">{{
                program.count
              }}</span>
              <span
                class="extraSmallClass"
                *ngIf="program.count >= 1 && program.count < 10"
                >{{ program.count }}</span
              >
              <span
                class="smallClass"
                *ngIf="program.count >= 10 && program.count < 50"
                >{{ program.count }}</span
              >
              <span
                class="midClass"
                *ngIf="program.count >= 50 && program.count < 100"
                >{{ program.count }}</span
              >
              <span class="bigClass" *ngIf="program.count >= 100">{{
                program.count
              }}</span>
            </li>
            <small>{{ item.key }}</small>
          </ul>
        </ng-container>
      </div>
    </div>
    <div class="arrow-wrapper" *ngIf="disableArrowTrue">
      <div
        class="arrow arrow-left"
        [ngClass]="{ disableLeft: disableLefttrue }"
        (click)="scroll(-1)"
      >
        <span class="icon-arrow-down"></span>
      </div>
      <div
        class="arrow arrow-right"
        [ngClass]="{ disableRight: disableRighttrue }"
        (click)="scroll(1)"
      >
        <span class="icon-arrow-down"></span>
      </div>
    </div>
  </div>
</div>
