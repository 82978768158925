import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { environment } from '../../../../environments/environment';
import { GeneralConfigurationService } from '../../services/general-config/general-config-service';
import { GeneralConfigurarion } from '../../services/entity/shared.entity';
@Component({
  selector: 'app-product-tour',
  templateUrl: './product-tour.component.html',
  styleUrls: ['./product-tour.component.scss'],
})
export class ProductTourComponent implements OnInit {
  public productTourActive: boolean = false;
  public productTourNotNowActive: boolean = false;
  public showTourWelcome: boolean = true;
  public imageUrl: string = environment.assetDirectory.concat(
    '/assets/images/not-now-popup.png'
  );
  public generalConfigurartion: GeneralConfigurarion =
    new GeneralConfigurarion();
  public hideButtons: boolean = false;
  constructor(
    private sharedService: SharedService,
    private generalConfigService: GeneralConfigurationService
  ) { }

  public async ngOnInit() {
    const role = localStorage.getItem('role');
    if (role == 'admin') {
      this.hideButtons = true;
    }
    this.generalConfigurartion =
      await this.generalConfigService.getGeneralConfiguration(false);
    setTimeout(() => {
      if (
        !sessionStorage.getItem('showTour') &&
        this.generalConfigurartion.loggedInCount < 3
      ) {
        this.productTourActive = true;
      }
    }, 45000);
  }

  public productTourPopupOpen() {
    this.sharedService.productTourEvent.next(true);
    this.productTourActive = false;
    this.productTourNotNowActive = false;
    sessionStorage.setItem('showTour', 'true');
  }

  public notNowPopupOpen() {
    this.productTourActive = false;
    this.productTourNotNowActive = false;
    sessionStorage.setItem('showTour', 'true');
  }

  public notNowPopupClose() {
    this.productTourActive = false;
    this.productTourNotNowActive = false;
  }

  public closePopup() {
    this.showTourWelcome = false;
  }
}
