import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartNumberData,
  Program,
  ChartOtherProgramInfo,
  OtherProgramTitle,
} from '../../model/customtypes-model.interface';
import {
  doughnutLabelsLine,
  backgroundColorPlugin,
} from '../chart-custom-plugins';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../../shared/services/other.program.service';
import { ChartType } from '../../../services/enum/shared.enum';
@Component({
  selector: 'app-half-doughnut',
  templateUrl: './half-doughnut.component.html',
  styleUrls: ['./half-doughnut.component.scss'],
})
export class HalfDoughnutComponent implements OnInit {
  public totalCounts: Array<number>;
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  public ChartLabels: Array<string> = ['554', '5533', '31231'];
  public chartData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: [
          '#88BC40',
          '#CE64B6',
          '#F48322',
          '#2A79D9',
          '#00807F',
        ],
        hoverBackgroundColor: [
          '#88BC40',
          '#CE64B6',
          '#F48322',
          '#2A79D9',
          '#00807F',
        ],
        hoverBorderColor: 'white',
        borderWidth: 3,
      },
    ],
  };

  public chartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';
  public chartPlugin: any = [
    ChartDataLabels,
    doughnutLabelsLine,
    backgroundColorPlugin,
  ];
  public chartOption: any = {
    animation: false,
    layout: {
      padding: window.innerWidth > 700 ? 40 : 25,
    },
    maintainAspectRatio: false,
    plugins: {
      align: 'start',
      border: false,
      datalabels: {
        display: false,
        color: 'black',
        font: {
          size: window.innerWidth > 700 ? 12 : 10,
          weight: 'bold',
        },
        anchor: 'end',
        align: 'end',
      },
      legend: {
        display: false,
      },
      text: false,
      text1: false,
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    cutout: '75%',
    circumference: 180,
    rotation: 270,
  };
  public graphBackgroundColor;
  public graphLightColor;
  public programInfo: Program[];
  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public labelInfo: string[] = [];
  @Input() public totalInfo: any[] = [];
  @Input() public totalYearWiseInfo: any[] = [];
  @Input() public chartNumbers: ChartNumberData[] = [];
  constructor(
    private sharedService: SharedService,
    private otherProgramService: OtherProgramService
  ) {}

  @Input() set totals(data: any) {
    this.totalCounts = data;
  }

  // Getting dataset chart number

  @Input() set chartDatSets(data: any) {
    if (data) {
      const chartData = data.chartData?.map((x: any) => x.data);
      this.chartData.datasets[0].data = chartData;
      this.chart?.chart?.update();
    } else {
      this.chartData.datasets[0].data = [0, 0, 0, 0];
      this.chart?.chart?.update();
    }
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      if (NavVal === true) {
        this.graphBackgroundColor = [
          '#D0AC62',
          '#9BE2BE',
          '#9797B9',
          '#E9838B',
          '#85D6EC',
        ];
        this.graphLightColor = true;
      } else {
        this.graphBackgroundColor = [
          '#84C7F0',
          '#F3D278',
          '#FA9885',
          '#D9A9F4',
          '#F5B171',
        ];
        this.graphLightColor = false;
      }
      this.chartData.datasets[0].backgroundColor = this.graphBackgroundColor;
      this.chartData.datasets[0].hoverBackgroundColor =
        this.graphBackgroundColor;
      this.chart?.chart?.update();
    });

    this.programInfo = this.otherProgramService.getProgramWithContent();
  }

  public async openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }

    let labelInfo = await this.checkLastYearIncluded();
    this.getTotalOtherProgramCount(labelInfo);
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: 'Alumni By Year',
      popupTitle: ChartType.alumni,
    };
    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      labelInfo,
      this.totalInfo,
      this.totalYearWiseInfo,
      true,
      false
    );
  }

  public async checkLastYearIncluded() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const labelInfo = JSON.parse(JSON.stringify(this.labelInfo));
    let labelInfoLength = labelInfo.length;

    if (labelInfo[labelInfoLength - 1] == currentYear) {
      labelInfo[labelInfoLength - 1] = `${labelInfo[labelInfoLength - 1]}* YTD`;
    } else {
      if (labelInfoLength === currentYear % 10) {
        labelInfo[currentYear % 10] = `${currentYear}* YTD`;
      }
    }

    labelInfo.push('Total');
    return labelInfo;
  }

  public getTotalOtherProgramCount(labelInfo) {
    this.totalInfo = Array.from({ length: labelInfo.length }, () => '');
    let accumulatedSum = this.otherProgramDetails.reduce(
      (total: number, obj: any) => total + (obj['Total'] || 0),
      0
    );
    this.totalInfo.push('Total ' + `${accumulatedSum}`);

    if (labelInfo.length + 1 != this.totalYearWiseInfo.length) {
      this.totalYearWiseInfo.push(0);
    }

    this.totalYearWiseInfo.push(accumulatedSum);
  }
}
