<div class="anticipated-grad-wrap">
  <div class="filter-main-wrap">
    <div class="filter-title-wrap">
      <h5>Anticipated Graduation Date</h5>
      <p>Expected Graduation Time of Employees</p>
    </div>
    <div class="d-flex flex-column align-self-lg-stretch filter-sec">
      <div class="filter d-flex justify-content-end align-items-center">
        <app-filter-popup-modal
          [loading]="loading"
          (popUpState)="getState($event)"
          (reset)="reset($event)"
          [title]="'Anticipated Graduation Date'"
          [data]="filterLabels"
          [hospitals]="hospitalList"
          (selectedValues)="getSelectedValues($event)"
        >
        </app-filter-popup-modal>
      </div>
    </div>
  </div>
  <app-filter-chips
    [currentYear]="'hide'"
    class="chip-container mt-3 mb-3"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <app-info-graphic
    [chartData]="chartDataCount"
    [chartType]="'verticalbar'"
    [otherProgramDetails]="otherProgramDetails"
    [totalInfo]="totalInfo"
    [labelInfo]="labelInfo"
    [totalYearWiseInfo]="totalYearWiseInfo"
  >
  </app-info-graphic>
</div>
