<div class="chart-main-wrap d-flex">
  <div class="chart-data-main">
    <canvas
      #canvasEl
      width="520"
      height="297"
      [data]="chartData"
      [plugins]="chartPlugin"
      [options]="chartOption"
      [type]="chartType"
      baseChart
    >
    </canvas>
    <div
      class="question-align pointer-cursor margin-left-10 question-icon-container"
      (click)="openOtherProgramPopup()"
    >
      <span class="question-icon-wraper icon-Others-Icon"> </span>
    </div>
  </div>
  <div *ngIf="isActive" class="col-md-3 pe-0 ps-0 side-data">
    <div class="conversion-wrap">
      <span
        >Employees who have started classes as a percentage of employees who
        have applied (started or submitted an application)</span
      >
      <p>Conversion Rate</p>
    </div>
    <div class="side-data-inner-wrap">
      <ng-container *ngFor="let data of datas?.chartData; let i = index">
        <div class="d-flex flex-row align-items-center justify-content-end">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="data-percent">
              {{
                ((data?.data?.Enrollment || 0) /
                  (data?.data?.Applicants || 1)) *
                  100 | number : "1.0-0"
              }}%
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!isActive" class="col-md-3 pe-0 ps-0 side-data">
    <div class="conversion-wrap">
      <span
        >Employees who have started classes as a percentage of employees who
        have applied (started or submitted an application)</span
      >
      <p>Conversion Rate</p>
    </div>
    <div class="side-data-inner-wrap">
      <ng-container *ngFor="let data of emptyFilter; let i = index">
        <div
          class="d-flex flex-row mb-4 align-items-center justify-content-end"
        >
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="data-percent">{{ data.Total }}%</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  #tooltip
  class="tooltip"
  [style.left.px]="tooltipPositionX"
  [style.top.px]="tooltipPositionY"
>
  {{ tooltipContent }}
</div>
