import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent {
  public forgotPasswordForm: FormGroup = new FormGroup<any>({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9.]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,}$'),
    ]),
  });

  public email: string;
  public component: FormControl<{ email: string }>;
  public hostError: boolean = false;
  public hostErrorMsg: string;
  public logoUrl: string = environment.assetDirectory.concat(
    '/assets/images/adtalem_logo.svg'
  );
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  constructor(
    public router: Router,
    public service: ApiService,
    private analytics: AngularFireAnalytics
  ) {}

  public submitEmail() {
    this.analytics.logEvent('click_forgot_password');
    if (!this.forgotPasswordForm.invalid) {
      const formValues = this.forgotPasswordForm.getRawValue();
      const auth = getAuth();
      this.email = formValues.email;
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          // Password reset email sent!
          this.router.navigate(['/verifyEmail']);
        })
        .catch((error) => {
          const errorCode = error.code;
          if (
            errorCode === 'auth/user-not-found' ||
            error.code === 'auth/invalid-email'
          ) {
            this.forgotPasswordForm.controls['email'].setErrors({
              invalidEmail: true,
            });
          } else {
            const splitted = error?.message.split(':');
            this.hostErrorMsg = splitted[1];
            this.hostError = true;
          }
        });
    }
  }
  public close() {
    this.router.navigate(['/login']);
  }
}
