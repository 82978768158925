<div class="student-enrollments-wrap card">
  <div class="filter-main-wrap">
    <div class="filter-title-wrap">
      <h5>Student Progress</h5>
      <p>
        Current status of employees who have started with Chamberlain University
        or Walden University
      </p>
    </div>
    <div class="d-flex flex-column align-self-lg-stretch filter-sec">
      <div
        class="filter w-100 d-flex justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center"
      >
        <app-filter-popup-modal
          [loading]="loading"
          (popUpState)="getPopUpState($event)"
          (reset)="reset($event)"
          [title]="'Student Progress'"
          class="specific-class"
          (selectedValues)="getSelectedValues($event)"
          [data]="filterLabels"
          [hospitals]="hospitalList"
        ></app-filter-popup-modal>
      </div>
    </div>
  </div>
  <app-filter-chips
    class="chip-container mt-3 mb-3"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <app-info-graphic
    [chartData]="chartDataCount"
    [chartType]="'horizontalbarsp'"
    [otherProgramDetails]="otherProgramDetails"
  ></app-info-graphic>
</div>
