import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ElementRef,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  concat,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SharedService } from '../../services/shared.service';
import { FilterItemTooltip, FilterItem } from '../../services/enum/shared.enum';
import {
  FilterItems,
  LearnerAnalytics,
} from '../model/customtypes-model.interface';
import { CdnService } from '../../services/cdn.service';
import { HospitalSharedService } from '../../services/hospital/hospital.service';
import { getFormattedCurrentDateTime } from '../../services/functional-handler/functional-handler';
import { AnalyticsService } from '../../services/analytics/analytics.service';
@Component({
  selector: 'app-filter-popup-modal',
  templateUrl: './filter-popup-modal.component.html',
  styleUrls: ['./filter-popup-modal.component.scss'],
})
export class FilterPopupModalComponent implements OnInit, AfterViewInit {
  public searchInput$: Subject<string> = new Subject<string>();
  @ViewChild('button', { read: ElementRef }) public button: ElementRef;
  @ViewChild('filterDropdown') public filterDropdown: TemplateRef<any>;
  @ViewChild('hospitalMobilefilter')
  public hospitalMobilefilter: TemplateRef<any>;
  @ViewChild('defaultFilterComp', { read: ViewContainerRef })
  public defaultFilterComp: ViewContainerRef;
  @ViewChild('customAppend') public customAppend: TemplateRef<any>;
  @ViewChild(NgSelectComponent, { read: ElementRef })
  public ngSelectComponent!: NgSelectComponent;
  @Output() public selectedValues = new EventEmitter();
  @Input() public title: string;
  @Input() public alphaGroup: string;
  public showPopup = false;
  public ngDropdown: any;
  public filterData: any;
  public listOfHospitals: any;
  public items$: Observable<any>;
  public selectedHospital: any;
  public hospitalsLoading: boolean;
  public selectStyle: string;
  public showMobileFilterPopup = false;
  public selectedValueFromMobileSelect: any;
  public mobilePopupClose: boolean = false;
  public filterLabel;
  @Output() public reset = new EventEmitter();
  @Output() public popUpState = new EventEmitter();
  @Input() public loading = false;
  @Input() public appendToBody: HTMLDivElement;
  public currentWindowWidth: number;
  public clickedValue: string;
  public mobileHospitalSelected: boolean = false;
  public viewContainerRef: ViewContainerRef;
  public filteredItems: any = {};
  public indexVal: any;
  public calenderShowTrue: boolean = false;
  public calenderdate: string;
  public initialStartYear: any;
  public closeButtonUrl: string = environment.assetDirectory.concat(
    '/assets/images/Close.svg'
  );
  public filteredStudentDetails: any;
  public userName: string;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    viewContainerRef: ViewContainerRef,
    private analytics: AngularFireAnalytics,
    public sharedService: SharedService,
    private cdnService: CdnService,
    public hospitalSharedService: HospitalSharedService,
    public analyticsService: AnalyticsService
  ) {
    this.viewContainerRef = viewContainerRef;
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.showPopup = false;
        this.popClose();
      }
    });
  }

  @Input() set startYear(data: any) {
    const formattedDate = new Date(data).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    this.initialStartYear = formattedDate;
  }

  @Input() set selectedDate(data: any) {
    this.calenderdate = data;
  }

  @Input() set data(data: any) {
    if (data) {
      this.filterData = data;
      this.assignTooltipToFilterLabel(this.filterData);
      this.sortModalityByNumber();
      this.filterData = this.checkParentOrgDuplication(this.filterData);
    }
  }
  @Input() set hospitals(data: any) {
    if (data) {
      this.listOfHospitals = JSON.parse(JSON.stringify(data));
      this.setParentOrgName(this.listOfHospitals);
      if (this.title === 'Learner Analytics') {
        this.listOfHospitals = this.removeRepeatingHospitals(
          this.listOfHospitals
        );
      }
    }
  }

  public ngAfterViewInit(): void {
    this.selectStyle =
      window.innerWidth < 499 ? 'ha-search' : 'me-4 col-4 ha-search';
  }

  public ngOnInit(): void {
    this.document.addEventListener('click', (event: any) => {
      const filterWrapper = this.document.querySelector('.filter-card');
      if (
        filterWrapper &&
        this.showPopup &&
        !this.showMobileFilterPopup &&
        !this.mobileHospitalSelected &&
        !this.button.nativeElement.contains(event.target)
      ) {
        if (!filterWrapper?.contains(event.target)) {
          if (this.title === 'Learner Analytics') {
            if (this.selectedHospital || this.selectedValueFromMobileSelect) {
              if (this.ngDropdown && !this.ngDropdown.contains(event.target)) {
                filterWrapper.remove();
                this.showPopup = false;
              }
            } else {
              filterWrapper.remove();
              this.showPopup = false;
            }
          } else {
            this.defaultFilterComp.remove();
            this.showPopup = false;
          }
        }
      }
    });
    this.loadHospitals();
    // Event for close popup when click keypress
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.showPopup = false;
      }
    });
    this.currentWindowWidth = window.innerWidth;

    this.sharedService.mobileFilterPopupEvent$.subscribe((NavVal: any) => {
      this.showMobileFilterPopup = NavVal;
    });

    this.sharedService.calenderPopupEvent$.subscribe((calenderVal: any) => {
      this.calenderShowTrue = calenderVal;
    });

    this.sharedService.calenderDateEvent$.subscribe((dateVal: any) => {
      this.calenderdate = dateVal;
    });
    // getting filter data Clinical insights
    this.filteredStudentDetails = this.cdnService.getTheInitialAllFilterData(
      'clinicalInsightsDynamicAll'
    );
    const defaultFilter =
      this.filteredStudentDetails?.data?.clinicalInsights[0];
    this.initialStartYear = new Date(
      defaultFilter?.filter?.startDate
    ).toLocaleDateString();
  }

  public assignTooltipToFilterLabel(filterItems: any[]) {
    filterItems.forEach((fItem) => {
      let itemFoundByValue = Object.keys(FilterItem).find(
        (key) => FilterItem[key] === fItem.filterLabel
      );

      if (itemFoundByValue != null && itemFoundByValue != undefined) {
        Object.assign(fItem, { tooltip: FilterItemTooltip[itemFoundByValue] });
      } else {
        Object.assign(fItem, { tooltip: '' });
      }
    });
  }

  public openMobileFilterPopup(i) {
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
      this.hospitalMobilefilter
    );
    if (embeddedViewRef != null) {
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
      this.showMobileFilterPopup = true;
      this.sharedService.mobileFilterPopupEvent.next(true);
    }
    this.filterLabel = this.filterData[i].filterLabel;
    this.indexVal = i;
    this.mobilePopupClose = false;
  }

  public closeHospitalPopup() {
    this.mobilePopupClose = true;
  }

  // Click event handler function
  public alphaFilter(buttonId: string) {
    this.clickedValue = buttonId;
  }

  public closePopup() {
    this.reset.emit(true);
    this.filteredItems = {};
    setTimeout(() => {
      this.applyFilters();
    }, 300);
    if (this.title?.includes('Clinical')) {
      this.calenderdate = this.initialStartYear;
    }
  }
  // Close popup
  public popClose() {
    this.showPopup = false;
    const dropdown: any = this.document.querySelector('.filter-card');
    dropdown?.remove();
  }
  // Open popup
  public openPopup($event: MouseEvent) {
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `${this.title} Graph`,
        time,
        sessionId
      );
    }

    this.clickedValue = 'A-D';
    // this.showPopup = true;
    this.showPopup = !this.showPopup;
    if (this.showPopup && this.currentWindowWidth <= 500) {
      const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
        this.filterDropdown
      );
      embeddedViewRef.detectChanges();
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    }
    this.popUpState.emit(this.showPopup ? 'open' : 'close');
  }

  // Apply filter on basis of seleted value/checkbox

  public applyFilters() {
    this.clickedValue = '';
    const obj: any = {};
    if (this.title?.includes('Clinical')) {
      if (this.calenderdate) {
        obj.startDate = this.calenderdate;
      } else {
        obj.startDate = this.initialStartYear
          ? this.initialStartYear
          : '01/01/2020';
      }
    }
    this.filterData?.forEach((x: any) => {
      obj[x.filterLabel] = x.selected;
    });
    this.selectedValues.emit(obj);
    this.selectedHospital = null;
    this.showPopup = false;
    const dropdown: any = this.document.querySelector('.filter-card');
    dropdown?.remove();
  }

  public getSelectedYear($event: any, index: number) {
    this.filterData[index].selected = $event;
  }

  public getFieldValuesFromMobile($event: any) {
    this.selectedValueFromMobileSelect = $event;
    this.ngDropdown = this.document.querySelector('ng-dropdown-panel');
  }

  public getSelectedValues($event: any) {
    this.ngDropdown = this.document.querySelector('ng-dropdown-panel');
    const obj: any = {};
    this.filteredItems = {};
    $event &&
      Object.keys($event.filter).forEach((x: any) => {
        switch (x) {
          case 'program_group':
            obj['program'] = 'all';
            break;
          case 'modality':
            obj['modality'] = 'all';
            break;
          case 'state':
            obj['location'] = $event.filter.state;
            break;
          default:
            obj[x] = $event.filter[x];
        }
      });
    this.filterData.forEach((x: any) => {
      if ($event) {
        x.selected =
          x.filterLabel === 'Institution'
            ? obj[x.filterLabel.toLowerCase()].toLowerCase()
            : obj[x.filterLabel.toLowerCase()];
      }
    });
    if ($event) {
      this.analytics.logEvent(
        `Hospital search - ${$event?.filter?.hospital}_${$event?.filter?.state}`
      );
    }
  }

  public loadHospitals() {
    this.items$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.hospitalsLoading = true)),
        switchMap((term) => {
          this.hospitalsLoading = false;
          return this.search(term);
        })
      )
    );
  }

  public search(term: string): Observable<any> {
    if (term?.length > 0) {
      return of(
        this.listOfHospitals.filter((obj) =>
          obj?.filter?.hospital.toLowerCase().includes(term.toLowerCase())
        )
      );
    } else {
      return of([]);
    }
  }

  public makeActiveAlphaBtn($event: any) {
    this.clickedValue = $event;
  }

  public mobileHospitalSelection(filter, indexVal) {
    this.filteredItems[indexVal] = filter;
    this.mobileHospitalSelected = true;
    this.closeHospitalPopup();
  }

  public iterateOptions(options, label) {
    if (label == 'Program') {
      const modalityFilter = this.filterData.find(
        (filter) => filter?.filterLabel === 'Modality'
      );
      if (
        modalityFilter.selected === 'all' ||
        modalityFilter.selected.find((option) => option === 'all')
      ) {
        return options;
      }

      return this.getStatusIterationOptions(options, modalityFilter);
    }
    return options;
  }

  public resetFilter(filter) {
    if (filter.filterLabel === 'Modality') {
      const programFilter = this.filterData.find(
        (filter) => filter?.filterLabel === 'Program'
      );
      programFilter.selected = 'all';
      this.filteredItems = {};
    }
  }

  public async sortModalityByNumber() {
    if (this.filterData) {
      const modalityFilterIndex = this.filterData.findIndex(
        (filter) => filter?.filterLabel === 'Modality'
      );

      if (modalityFilterIndex > -1) {
        let isContainsUnderScore = this.filterData[
          modalityFilterIndex
        ].filterItems.some(function (item) {
          return item.includes('_');
        });

        if (isContainsUnderScore) {
          let updatedItems = this.filterData[modalityFilterIndex].filterItems
            .map((str) => {
              const [numStr, letter] = str.split('_');
              return { num: parseInt(numStr), letter };
            })
            .sort((a, b) => a.num - b.num)
            .map((obj) => obj.letter);

          this.filterData[modalityFilterIndex].filterItems = [];
          this.filterData[modalityFilterIndex].filterItems = updatedItems;
        }
      }
    }
  }

  public calenderShow() {
    this.calenderShowTrue = true;
  }

  public getStatusIterationOptions(options: any, modalityFilter: any) {
    return options.filter((option) => {
      let status = false;
      modalityFilter.selected.forEach((selected) => {
        if (selected === 'Course based') {
          if (!option.includes('-TP')) {
            status = true;
          }
        } else if (selected === 'Competency based') {
          if (option.includes('-TP') || option === 'all') {
            status = true;
          }
        }
      });
      return status;
    });
  }

  public checkParentOrgDuplication(filterData: FilterItems[]) {
    let organization = this.hospitalSharedService.getParentOrganizationName();
    const searchTerm = new RegExp('p-', 'i');

    if (filterData.length) {
      let selectedHospitalList: number = filterData.findIndex(
        (labelInfo) => labelInfo.filterLabel === 'Hospital'
      );

      if (selectedHospitalList > -1) {
        filterData[selectedHospitalList].filterItems = filterData[
          selectedHospitalList
        ].filterItems?.map((str) => {
          if (searchTerm.test(str)) {
            const restOfString = str.split(searchTerm)[1];
            if (restOfString && restOfString.trim() === organization) {
              return this.hospitalSharedService.setParentHospitalName(
                restOfString
              );
            }
          }
          return str;
        });
      }
    }

    return filterData;
  }

  public async setParentOrgName(learnerAnalytics: LearnerAnalytics[]) {
    let organization = this.hospitalSharedService.getParentOrganizationName();
    let modifiedParentOrgName =
      this.hospitalSharedService.setParentHospitalName(organization);

    let parentOrgs = learnerAnalytics.filter(
      (filterObj) => filterObj.orgId == filterObj.filter.hospitalId
    );

    if (parentOrgs.length > 0) {
      parentOrgs.map(
        (learner: LearnerAnalytics) =>
          (learner.filter.hospital = modifiedParentOrgName)
      );
    }
  }

  public removeRepeatingHospitals(arr: LearnerAnalytics[]) {
    const result: any = [];
    const seen: any = {};
    arr &&
      arr.forEach((x: LearnerAnalytics) => {
        const key = x?.filter?.hospital + x?.filter?.state;
        if (!seen[key]) {
          result.push(x);
          seen[key] = true;
        }
      });
    return result;
  }
}
