<div class="card cardStyle mb-3">
  <div class="Partnership-tile-wrap">
    <h5>Partnerships</h5>
  </div>
  <ng-container
    *ngFor="
      let data of partnershipList;
      let i = index;
      trackBy: trackByContentKey
    "
  >
    <ng-container *ngIf="data.status != undefined">
      <div class="partnership-univercity-wrap">
        <div class="partnership-sub-wrap">
          <div class="logo-wrap">
            <img [src]="data?.imageUrl" alt="{{ data?.title }}" />
          </div>
          <div class="logo-title">
            <h6>{{ data?.title }}</h6>
          </div>
        </div>
        <div class="partnership-sub-wrap">
          <div
            class="education-wrap"
            *ngFor="let rStatus of data.status; trackBy: trackById"
          >
            <div class="flexSection">
              <div class="agreementSection">
                <div class="custom-legend">
                  <small>{{ rStatus?.agTooltip }}</small>
                  <p>{{ rStatus?.RecordType?.Name.substring(3) }}</p>
                </div>
                <div class="subsection">
                  <p class="statusValue">Status:</p>
                  <div>
                    <small>{{ rStatus?.statusTooltip }}</small>
                    <p
                      class="statusHead"
                      *ngIf="rStatus?.Stage__c; else naBlock"
                    >
                      {{ rStatus?.Stage__c }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="hospitalSection">
                <div class="custom-legend">
                  <p
                    *ngIf="
                      data?.title === 'Chamberlain University' &&
                      rStatus?.RecordType?.Name === 'CU Educational Agreement'
                    "
                    class="statusValue"
                    (click)="
                      hospitalList('CU Educational Agreement', data?.title)
                    "
                  >
                    View All Sites
                  </p>
                  <p
                    *ngIf="
                      data?.title === 'Chamberlain University' &&
                      rStatus?.RecordType?.Name === 'CU Clinical Agreement'
                    "
                    class="statusValue"
                    (click)="hospitalList('CU Clinical Agreement', data?.title)"
                  >
                    View All Sites
                  </p>
                  <p
                    *ngIf="data?.title === 'Walden University'"
                    class="statusValue"
                    (click)="
                      hospitalList('WU Educational Agreement', data?.title)
                    "
                  >
                    View All Sites
                  </p>
                </div>
                <div class="subsection">
                  <p class="statusValue">Renewal Date:</p>
                  <div>
                    <small
                      *ngIf="
                        rStatus?.Stage__c === 'Active' ||
                        rStatus?.Stage__c === 'Expired'
                      "
                      >{{ rStatus.renewalTooltip }}</small
                    >
                    <p
                      class="statusValueDate"
                      *ngIf="
                        rStatus?.Agreement_End_Date__c &&
                          (rStatus?.Stage__c === 'Active' ||
                            rStatus?.Stage__c === 'Expired');
                        else naBlock
                      "
                    >
                      {{ rStatus?.Agreement_End_Date__c | date : "MM-dd-yyyy" }}
                    </p>
                  </div>
                  <ng-template #naBlock>
                    <p class="statusHead">N/A</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let rStatus of data.status">
            <ng-container
              *ngIf="
                data.status.length < 2 &&
                rStatus?.RecordType?.Name.includes('CU')
              "
            >
              <div class="education-wrap">
                <div class="flexSection">
                  <div class="agreementSection">
                    <div class="custom-legend">
                      <small>{{ rStatus?.agTooltip }}</small>
                      <p>Clinical Agreement</p>
                    </div>
                    <div class="subsection">
                      <p class="statusValue">Status:</p>
                      <div>
                        <small>{{ agreementStatusTooltip.noAgreement }}</small>
                        <p class="statusHead">No Agreement</p>
                      </div>
                    </div>
                  </div>
                  <div class="hospitalSection">
                    <div class="custom-legend">
                      <p
                        class="statusValue"
                        (click)="
                          hospitalList('CU Clinical Agreement', data?.title)
                        "
                      >
                        View All Sites
                      </p>
                    </div>
                    <div class="subsection">
                      <p class="statusValue">Renewal Date:</p>
                      <div>
                        <small
                          *ngIf="
                            data?.status?.Stage__c === 'Active' ||
                            data?.status?.Stage__c === 'Expired'
                          "
                          >{{ renewalTooltip.notAvailable }}</small
                        >
                        <p class="statusHead">N/A</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="education-wrap" *ngIf="data.status.length == 0">
            <div class="flexSection">
              <div class="agreementSection">
                <div class="custom-legend">
                  <small>{{ agreementTooltip.educational }}</small>
                  <p>Educational Agreement</p>
                </div>
                <div class="subsection">
                  <p class="statusValue">Status:</p>
                  <div>
                    <small>{{ agreementStatusTooltip.noAgreement }}</small>
                    <p class="statusHead">No Agreement</p>
                  </div>
                </div>
              </div>
              <div class="hospitalSection">
                <div class="custom-legend">
                  <p
                    *ngIf="data?.title === 'Chamberlain University'"
                    class="statusValue"
                    (click)="
                      hospitalList('CU Educational Agreement', data?.title)
                    "
                  >
                    View All Sites
                  </p>
                  <p
                    *ngIf="data?.title === 'Walden University'"
                    class="statusValue"
                    (click)="
                      hospitalList('WU Educational Agreement', data?.title)
                    "
                  >
                    View All Sites
                  </p>
                </div>
                <div class="subsection">
                  <p class="statusValue">Renewal Date:</p>
                  <div>
                    <small
                      *ngIf="
                        data?.status?.Stage__c === 'Active' ||
                        data?.status?.Stage__c === 'Expired'
                      "
                      >{{ renewalTooltip.notAvailable }}</small
                    >
                    <p class="statusHead">N/A</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #popupContainer>
  <div class="change-password-popup modal-fade">
    <div class="change-password-popup-sub-wrap">
      <div class="modal-header">
        <div
          class="modal-title"
          [ngClass]="{
            customTitle:
              popupTitle === 'WU Clinical Agreement' ||
              popupTitle === 'WU Educational Agreement'
          }"
        >
          <h4>{{ popupTitle ? popupTitle : "CU Educational Agreement" }}</h4>
          <p>Total Sites {{ childHierarchyStatus?.length }}</p>
        </div>
        <button (click)="closePopup()" class="backtohome-wrap">
          <span class="icon-arrow-down"></span>
          <img alt="close" [src]="closeButtonUrl" />
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-spinner">
          <span
            class="spinner-border"
            role="status"
            *ngIf="hospitalListLoader"
          ></span>
        </div>
        <ng-scrollbar
          style="height: 75vh"
          [track]="'vertical'"
          class="my-scrollbar"
        >
          <ng-container *ngIf="childHierarchyStatus; else elsetemplate">
            <ng-container *ngFor="let rStatus of childHierarchyStatus">
              <div class="modal-sub-block">
                <div class="sub-block-title">
                  <h4>{{ rStatus?.selectedOrganizationName }}</h4>
                  <p>
                    {{
                      rStatus?.selectedBillAddress &&
                      !rStatus.selectedBillAddress.includes("null") &&
                      rStatus.selectedBillAddress.trim() !== ""
                        ? rStatus.selectedBillAddress
                        : "N/A"
                    }}
                  </p>
                </div>
                <div class="sub-block-content">
                  <div class="content-sub-block">
                    <p>Partnership Status</p>
                  </div>
                  <div class="content-sub-block">
                    <ng-container
                      *ngIf="
                        popupTitle === 'WU Educational Agreement' &&
                        universityTitle === 'Walden University'
                      "
                    >
                      <p
                        class="status {{
                          rStatus?.WUChildEducationalAgreementStatus
                        }}"
                      >
                        <span></span>
                        <span>{{
                          rStatus?.WUChildEducationalAgreementStatus
                        }}</span>
                      </p>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        popupTitle === 'CU Educational Agreement' &&
                        universityTitle === 'Chamberlain University'
                      "
                    >
                      <p
                        class="status {{
                          rStatus?.CUChildEducationalAgreementStatus
                        }}"
                      >
                        <span></span>
                        <span>{{
                          rStatus?.CUChildEducationalAgreementStatus
                        }}</span>
                      </p>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        popupTitle === 'CU Clinical Agreement' &&
                        universityTitle === 'Chamberlain University'
                      "
                    >
                      <p
                        class="status {{
                          rStatus?.CUChildClinicalAgreementStatus
                        }}"
                      >
                        <span></span>
                        <span>{{
                          rStatus?.CUChildClinicalAgreementStatus
                        }}</span>
                      </p>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        popupTitle === 'CU Clinical Agreement' &&
                        rStatus?.agreement === null
                      "
                    >
                      <p class="status No Agreement">
                        <span></span>
                        <span class="no-status">No Agreement</span>
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #elsetemplate>
            <p>{{ emptyMassage }}</p>
          </ng-template>
        </ng-scrollbar>
      </div>
    </div>
  </div>
</ng-template>
