<div
  class="d-flex justify-content-center align-items-center forgotPassword-page"
>
  <div
    class="col-lg-8 col-md-10 col-sm-10 col-10 shadow forgotPassword-container"
  >
    <div class="row">
      <div class="forgotPassword-form-container">
        <figure>
          <img class="m-auto" [src]="logoUrl" alt="adtalem_logo" />
        </figure>
        <h4 class="title mt-4">Check your mail</h4>
        <h4 class="sub-title mt-4">Reset password link sent to your email</h4>
        <form>
          <input
            type="hidden"
            name="CSRFToken"
            value="OWY4NmQwODE4ODRjN2Q2NTlhMmZlYWEwYzU1YWQwMTVhM2JmNGYxYjJiMGI4MjJjZDE1ZDZMGYwMGEwOA=="
          />
          <div class="mt-5">
            <button
              type="submit"
              class="btn btn-primary"
              (click)="backToLogin()"
            >
              Back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
