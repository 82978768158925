<ng-template #otherPopupTemp>
  <div
    id="otherProgram"
    #otherProgram
    class="other-prg-wrapper"
    *ngIf="showOtherProgram"
    (click)="onClickPopupWrapper()"
  >
    <div
      class="other-prg-wrapper-sub-wrap"
      (click)="onClickPopupSubWrapper($event)"
    >
      <div class="modal-body">
        <div class="display-flex-row justify-space-between border-bottom-solid">
          <div class="popup-title-wrap">
            <h5>{{ otherProgramInfo.popupTitle }} - Other Programs</h5>
            <p class="subtitle">
              Total {{ otherProgramInfo.totalProgramCount }} Programs
            </p>
          </div>
          <div class="modal-header">
            <button (click)="closePopup()">
              <img alt="close" [src]="closeButtonUrl" />
            </button>
          </div>
        </div>
        <div class="table-wrap">
          <table #otherProgramTable id="otherProgramTable" class="tables">
            <caption></caption>
            <thead>
              <tr class="table-row-height">
                <th
                  *ngFor="
                    let header of otherProgramInfo.labelInfo;
                    let isFirst = first;
                    let i = index
                  "
                  [id]="'other_prg_' + header.value"
                >
                  <div
                    class="width-100"
                    [ngClass]="{
                      'display-flex-row': otherProgramInfo.isNeedScroll
                    }"
                  >
                    <span
                      [ngClass]="{
                        'display-flex-row': isFirst,
                        'display-flex-header-center-aligned': !isFirst,
                        'column-min-width': otherProgramInfo.isNeedScroll
                      }"
                    >
                      <span [ngClass]="getLabelClass(header.name)"> </span>
                      {{ header.name }}
                    </span>
                    <div
                      *ngIf="otherProgramInfo.isNeedScroll"
                      class="arrow-wrapper"
                      (click)="scroll(-1)"
                      [ngClass]="{ 'disable-arrow': isDisableLeftArrow }"
                    >
                      <div *ngIf="isFirst" class="arrow arrow-left">
                        <span class="icon-arrow-down"></span>
                      </div>
                    </div>
                  </div>
                </th>
                <th *ngIf="otherProgramInfo.isNeedScroll" id="rightArrow">
                  <div
                    class="arrow-wrapper"
                    (click)="scroll(1)"
                    [ngClass]="{ 'disable-arrow': isDisableRightArrow }"
                  >
                    <div class="arrow arrow-right">
                      <span class="icon-arrow-down"></span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let rowData of otherProgramInfo.otherPrograms;
                  let isLast = last
                "
                class="table-row-height"
                [ngClass]="{ 'table-row-last': isLast }"
              >
                <td *ngFor="let header of otherProgramInfo.labelInfo">
                  {{ rowData[header.value] }}
                </td>
                <td *ngIf="otherProgramInfo.isNeedScroll"></td>
              </tr>
              <tr class="total-number table-row-height">
                <th
                  *ngFor="let total of otherProgramInfo.totalInfo"
                  [id]="'other_prg_' + total"
                >
                  <span *ngIf="otherProgramInfo.isNeedSummary">
                    {{ total }}</span
                  >
                </th>
                <th
                  *ngIf="otherProgramInfo.isNeedScroll"
                  id="rightArrowTotal"
                ></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
