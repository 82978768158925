<ng-scrollbar>
  <cdk-virtual-scroll-viewport
    #scrollContainer
    itemSize="0"
    [orientation]="'vertical'"
    scrollViewport
  >
    <ng-container *ngIf="!groupByAlphabet; else alphaGroup">
      <div *ngIf="!shouldApplyAlphasort">
        <div class="after-border" *ngFor="let option of filteredData">
          <label class="filter-checkbox mb-3 pb-3">
            <input
              id="filter_{{ filterLabel + option }}"
              (change)="selectOption($event)"
              [checked]="checkBoxChecked(option)"
              [value]="option"
              class="d-none"
              type="checkbox"
              name="filter"
            />
            <span class="checkbox"></span>
            {{ label ? option[label]?.toString() : option?.toString() }}
          </label>
        </div>
      </div>
      <div *ngIf="shouldApplyAlphasort">
        <div
          class="after-border"
          *ngFor="let option of filteredData | alphasort"
        >
          <label class="filter-checkbox mb-3 pb-3">
            <input
              id="filter_{{ filterLabel + option }}"
              (change)="selectOption($event)"
              [checked]="checkBoxChecked(option)"
              [value]="option"
              class="d-none"
              type="checkbox"
              name="filter"
            />
            <span class="checkbox"></span>
            {{ label ? option[label]?.toString() : option?.toString() }}
          </label>
        </div>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-scrollbar>
<ng-template #alphaGroup>
  <div
    [attr.data-group]="groups"
    #alphaSection
    *ngFor="let groups of alphabetGroupIterator"
  >
    <ng-container *ngIf="alphabetGroups[groups]?.length">
      <div
        *ngFor="let option of alphabetGroups[groups] | alphasort"
        class="after-border"
      >
        <label class="filter-checkbox mb-3 pb-3">
          <input
            id="filter_{{ filterLabel + option }}"
            (change)="selectOption($event)"
            [checked]="checkBoxChecked(option)"
            [value]="option"
            class="d-none"
            type="checkbox"
            name="filter"
          />
          <span class="checkbox"></span>
          {{ label ? option[label]?.toString() : option?.toString() }}
        </label>
      </div>
    </ng-container>
  </div>
</ng-template>
