<div class="datepicker-main-wrap">
  <span *ngIf="greaterDate" class="text-danger"
    >You are selected the future date</span
  >
  <div class="datepicker-wrap">
    <ngb-datepicker
      [minDate]="minDate"
      [maxDate]="maxDate"
      (dateSelect)="onDateSelect($event)"
    ></ngb-datepicker>
  </div>
  <div class="datepicker-footer-wrap">
    <button class="reset me-2" (click)="cancelCalender($event)">Cancel</button>
    <button class="apply" (click)="adddate($event)">Done</button>
  </div>
</div>
