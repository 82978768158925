<app-popup-modal
  [scrollDirection]="'vertical'"
  [openModal]="openModal"
  [url]="'contact'"
  [subtitle]="'Adtalem Contacts'"
  [title]="'Strategic Workforce Solutions'"
  (closeModal)="closeModal($event)"
>
  <div class="d-flex align-content-around">
    <div class="card-wrap">
      <div
        class="card card-style"
        *ngFor="let cards of popUpDataObj; let i = index"
      >
        <div class="card-main-wrap">
          <div class="heading-style">
            <p>{{ cards?.RoleInTerritory2 }}</p>
          </div>
          <div class="row mb-3">
            <div class="col">
              <div class="card-block">
                <p class="title-color">{{ cards?.User?.Name }}</p>
              </div>
            </div>
          </div>
          <div class="card-content-wrap mb-0">
            <div class="row">
              <div class="col">
                <div class="card-block">
                  <p class="title-color fw-semibold m-auto font-head pt-2">
                    Email
                  </p>
                  <a
                    href="mailto:{{ cards.User.Email }}"
                    class="h-link font-child"
                    >{{ cards.User.Email }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-popup-modal>
