import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PartnerDashboardComponent } from '../../../modules/dashboard/partner-dashboard/partner-dashboard.component';

@Component({
  selector: 'app-custom-card-footer',
  templateUrl: './custom-card-footer.component.html',
  styleUrls: ['./custom-card-footer.component.scss'],
})
export class CustomCardFooterComponent implements OnInit {
  @ViewChild(PartnerDashboardComponent)
  public childComponent: PartnerDashboardComponent;
  public items: any[] = [];
  public cancelOrderFalse: boolean = false;

  constructor(public sharedService: SharedService) {}

  public ngOnInit(): void {
    this.sharedService.CancelOrderEvent$.subscribe((NavVal: any) => {
      this.cancelOrderFalse = NavVal;
    });
  }

  public saveChildOrder() {
    this.sharedService.EditOrderEvent.next(true);
    this.sharedService.CancelOrderEvent.next(false);
    this.cancelOrderFalse = false;
    const event = new CustomEvent('SaveEvent', {});
    // Dispatch the custom event
    document.dispatchEvent(event);
  }

  public cancelOrder() {
    const event = new CustomEvent('CancelEvent', {});
    // Dispatch the custom event
    document.dispatchEvent(event);
    this.sharedService.EditOrderEvent.next(true);
    this.sharedService.CancelOrderEvent.next(false);
    this.cancelOrderFalse = false;
  }

  public resetOrder() {
    const event = new CustomEvent('ResetEvent', {});
    // Dispatch the custom event
    document.dispatchEvent(event);
  }

  public orderReset() {
    this.resetOrder();
  }
}
