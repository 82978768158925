import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartDataItems,
  chartDataSet,
  StaticFilterData,
  DataSetResponse,
  ChartNumberData,
  Program,
  ChartOtherProgramInfo,
  OtherProgramTitle,
} from '../../model/customtypes-model.interface';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../../shared/services/other.program.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';

@Component({
  selector: 'app-horizontal-bar-chart',
  styleUrls: ['./horizontal-bar-chart.component.scss'],
  templateUrl: './horizontal-bar-chart.component.html',
})
export class HorizontalBarChartComponent implements OnInit {
  @ViewChild('canvasEl') public canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('tooltip', { static: true }) public tooltip: ElementRef;
  public bgColorsForLegend: string[] = ['#56D0E0', '#4C7DED'];
  public ChartLabels: string[] = [];
  public chartData: ChartData<'bar'> = {
    datasets: [
      this.createDataset(['#88BC40', '#88BC40'], '#88BC40', 'Enrollments'),
      this.createDataset(['#2A79D9', '#2A79D9'], '#2A79D9', 'Applicants'),
    ],
    labels: this.ChartLabels,
  };
  public chartType: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartPlugin: any = [ChartDataLabels];
  public chartOption: any = {
    animation: false,
    indexAxis: 'y',
    layout: {
      padding: 0,
    },
    maintainAspectRatio: false,
    plugins: {
      border: false,
      datalabels: {
        color: 'white',
        font: {
          size: 10,
          weight: 'normal',
        },
      },
      legend: {
        align: 'start',
        display: false,
        labels: {
          boxHeight: 15,
          boxWidth: 15,
          color: '#526289',
          pointStyle: 'rectRounded',
          textAlign: 'center',
          usePointStyle: true,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      tooltip: {
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        bodyColor: '#000000',
        enabled: true,
        footerColor: '#000000',
        titleColor: '#000000',
        xAlign: 'bottom',
        yAlign: 'center',
      },
    },
    responsive: this.getResponsiveStatus(),
    scales: {
      x: {
        barPercentage: 2,
        border: {
          borderDash: [1, 1],
          borderDashOffset: 2,
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.75)',
          lineWidth: 0,
          offset: true,
          drawOnChartArea: false,
        },
        suggestedMax: 10,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
          },
        },
        title: {
          align: 'center',
          color: '#526289',
          display: true,
          text: 'No.of Students',
        },
      },
      y: {
        border: {
          borderDash: [1, 1],
          borderDashOffset: 2,
          color: '#082469',
          display: true,
          width: 1,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.75)',
          drawOnChartArea: false,
          lineWidth: 0,
          offset: false,
        },
        position: 'left',
        ticks: {
          color: '#1F356C',
          crossAlign: 'far',
          font: {
            weight: 'bold',
          },
          padding: 10,
        },
      },
    },
  };
  public graphBackgroundColor;
  public emptyFilter: StaticFilterData[] = [
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
    { Total: 0, Applicants: 0, Enrollment: 0 },
  ];
  @Input() public chartNumbers: ChartNumberData[] = [];
  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];

  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  public datas: chartDataSet;
  public isActive: boolean;
  public programInfo: Program[];
  public tooltipPositionX: number = 0;
  public tooltipPositionY: number = 0;
  public tooltipContent: string = '';
  constructor(
    private sharedService: SharedService,
    private otherProgramService: OtherProgramService
  ) {}

  // Getting chart data set number

  @Input() set chartDatSets(data: chartDataSet) {
    if (data) {
      this.datas = data;
      this.chartData.labels = data.labels;
      this.isActive = true;
      this.chartData.datasets[0].data = data.chartData.map(
        (x: ChartDataItems) => x.data?.Enrollment
      );
      this.chartData.datasets[1].data = data.chartData.map(
        (x: ChartDataItems) => x.data?.Applicants
      );
      const hasData = this.chartData.datasets.every(
        (q: DataSetResponse) =>
          q.data.length === 0 || q.data.every((a: number) => a === 0)
      );
      if (hasData) {
        this.chartData.datasets.forEach((x: DataSetResponse, i: number) => {
          this.chartData.datasets[i].data = x.data.map((x: number) => 0);
        });
      }
    } else {
      this.chartData.datasets.forEach((x: DataSetResponse, i: number) => {
        this.chartData.datasets[i].data = x.data.map((x: number) => 0);
      });
      this.chartData.labels = ['RNBSN', 'RNMSN', 'MSN', 'DNP', 'Other'];
      this.isActive = false;
    }
    this.chart?.chart?.update();
  }

  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      if (NavVal === true) {
        this.graphBackgroundColor = ['#C29D4D', '#4A5C76'];
      } else {
        this.graphBackgroundColor = ['#88BC40', '#2A79D9'];
      }
      this.chartData.datasets.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].backgroundColor =
            this.graphBackgroundColor[i];
          this.chartData.datasets[i].hoverBackgroundColor =
            this.graphBackgroundColor[i];
        }
      });
      this.chart?.chart?.update();
    });
    this.programInfo = this.otherProgramService.getProgramWithContent();
  }

  public ngAfterViewInit(): void {
    this.setupTooltip();
  }

  public createGradient(
    ctx: CanvasRenderingContext2D,
    colors: string[],
    startY: number,
    endY: number
  ) {
    const gradient = ctx.createLinearGradient(0, startY, 0, endY);
    colors.forEach((color, index) => {
      gradient.addColorStop(index, color);
    });
    return gradient;
  }

  public createDataset(
    backgroundColor: string[],
    hoverBackgroundColor: string,
    label: string
  ) {
    return {
      backgroundColor: function (context: any) {
        const chart = context.chart;
        const { chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return this.createGradient(context.chart.ctx, backgroundColor, 300, 0);
      }.bind(this),
      barThickness: 20,
      borderColor: 'white',
      borderRadius: 20,
      borderWidth: 0.1,
      data: [],
      hoverBackgroundColor,
      hoverBorderColor: 'white',
      label,
    };
  }

  public setupTooltip(): void {
    const canvasEl = this.canvas.nativeElement;
    const ctx = canvasEl.getContext('2d');
    canvasEl.addEventListener('mousemove', (event) => {
      const rect = canvasEl.getBoundingClientRect();
      let mouseX;
      if (window.innerWidth <= 755) {
        mouseX = event.clientX - rect.left + 40;
      } else {
        mouseX = event.clientX - rect.left + 20;
      }
      const mouseY = event.clientY - rect.top;
      const yAxisWidth = this.chart.chart.chartArea.left;
      if (mouseX <= yAxisWidth) {
        this.handleXAxisHover(event, rect, mouseY);
      } else {
        this.hideTooltip();
      }
    });

    canvasEl.addEventListener('mouseleave', () => {
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      this.hideTooltip();
      this.chart.chart.update();
    });
  }

  public filterProgramByName(name: string): Program {
    const defaultProgram: Program = {
      name: '',
      content: '',
    };

    return this.programInfo.find((prg) => prg.name === name)
      ? this.programInfo.find((prg) => prg.name === name)
      : defaultProgram;
  }

  public getResponsiveStatus(): boolean {
    if (window.innerWidth > 1199) {
      return false;
    } else if (window.innerWidth <= 1199 && window.innerWidth >= 600) {
      return true;
    } else {
      return false;
    }
  }

  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: '',
      popupTitle: ChartType.applicantsToEnrollment,
    };
    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      ['Enrollment', 'Applicants', 'Percentage'],
      [],
      [],
      false,
      false
    );
  }

  public handleXAxisHover(event, rect, mouseY) {
    const labels = this.chart.chart.scales['y'].getLabels();
    const labelIndex = this.calculateLabelIndex(rect, mouseY, labels.length);
    if (labelIndex < labels.length && labels[labelIndex]) {
      this.updateTooltipContent(labels[labelIndex]);
      this.updateTooltipPosition(event, rect, mouseY);
    } else {
      this.hideTooltip();
    }
  }

  public calculateLabelIndex(rect, mouseY, labelsLength) {
    if (window.innerWidth <= 755) {
      // For mobile devices or small screens
      return Math.floor(
        ((mouseY + 2 * this.chart.chart.chartArea.top) / rect.height) *
          labelsLength
      );
    } else {
      // For desktop or larger screens
      return Math.floor(
        ((mouseY - this.chart.chart.chartArea.top) /
          (this.chart.chart.chartArea.bottom -
            this.chart.chart.chartArea.top)) *
          labelsLength
      );
    }
  }

  public updateTooltipContent(label) {
    this.tooltipContent = this.filterProgramByName(label).content;
    this.tooltip.nativeElement.style.display = 'block';
    this.tooltip.nativeElement.classList.add('prg-tooltip');
  }

  public updateTooltipPosition(event, rect, mouseY) {
    this.tooltipPositionX = event.clientX - rect.left + 20;
    this.tooltipPositionY = this.calculateTooltipPositionY(mouseY);
  }

  public calculateTooltipPositionY(mouseY) {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1366) {
      return mouseY + 150;
    } else if (screenWidth < 1600 && screenWidth > 1450) {
      return mouseY + 180;
    } else if (screenWidth < 1750 && screenWidth > 1601) {
      return mouseY + 200;
    } else if (screenWidth < 1900 && screenWidth > 1751) {
      return mouseY + 220;
    } else if (screenWidth < 2100 && screenWidth > 1901) {
      return mouseY + 240;
    } else {
      return mouseY + 320;
    }
  }

  public hideTooltip() {
    this.tooltip.nativeElement.style.display = 'none';
    this.tooltip.nativeElement.classList.remove('prg-tooltip');
  }
}
