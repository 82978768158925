<div class="d-flex justify-content-center align-items-center login-page">
  <div class="col-lg-8 col-md-10 col-sm-10 col-10 shadow login-container">
    <div class="row">
      <div class="col-6 align-self-stretch cover"></div>
      <div class="col-md-6 col-sm-12 login-form-container">
        <figure>
          <img class="m-auto" [src]="logoUrl" alt="" />
        </figure>
        <h4 class="title mt-4">Welcome Back</h4>
        <form (ngSubmit)="login()" [formGroup]="loginForm">
          <input
            type="hidden"
            name="CSRFToken"
            value="OWY4NmQwODE4ODRjN2Q2NTlhMmZlYWEwYzU1YWQwMTVhM2JmNGYxYjJiMGI4MjJjZDE1ZDZMGYwMGEwOA=="
          />
          <div class="form-group">
            <label for="username">Username</label>
            <input
              id="username"
              [ngClass]="{
                'is-invalid':
                  loginForm.controls['email'].invalid &&
                  (loginForm.controls['email'].touched ||
                    loginForm.controls['email'].dirty)
              }"
              formControlName="email"
              class="form-control"
              type="text"
            />
            <ng-container
              *ngIf="
                loginForm.controls['email'].invalid &&
                (loginForm.controls['email'].touched ||
                  loginForm.controls['email'].dirty)
              "
            >
              <ng-container
                *ngIf="loginForm.controls['email'].hasError('required')"
              >
                <small class="text-danger">Username is required</small>
              </ng-container>
              <ng-container
                *ngIf="loginForm.controls['email'].hasError('invalidEmail')"
              >
                <small class="text-danger">Username isn’t registered</small>
              </ng-container>
              <ng-container
                *ngIf="loginForm.controls['email'].hasError('email')  || loginForm.controls['email'].errors?.['pattern']"
                #invalidUser
              >
                <small class="text-danger">Invalid email</small>
              </ng-container>
            </ng-container>
          </div>
          <div class="form-group form-group-password mb-3">
            <label for="password">Password</label>
            <input
              id="password"
              [type]="fieldPasswordType ? 'text' : 'password'"
              formControlName="password"
              [ngClass]="{
                'is-invalid':
                  loginForm.controls['password'].invalid &&
                  (loginForm.controls['password'].touched ||
                    loginForm.controls['password'].dirty)
              }"
              class="form-control"
              type="password"
            />
            <span
              [ngClass]="{
                'invalid-password':
                  loginForm.controls['password'].invalid &&
                  (loginForm.controls['password'].touched ||
                    loginForm.controls['password'].dirty)
              }"
              (click)="toggleTypePassword()"
            ></span>
            <ng-container
              *ngIf="
                loginForm.controls['password'].invalid &&
                (loginForm.controls['password'].touched ||
                  loginForm.controls['password'].dirty)
              "
            >
              <ng-container
                *ngIf="
                  loginForm.controls['password'].hasError('invalidPassword')
                "
              >
                <small class="text-danger"
                  >Username or Password you entered isn't correct</small
                >
              </ng-container>
              <ng-container
                *ngIf="loginForm.controls['password'].hasError('required')"
              >
                <small class="text-danger">Password is required</small>
              </ng-container>
            </ng-container>
          </div>
          <small class="text-danger" *ngIf="hostError">{{
            hostErrorMsg
          }}</small>
          <a class="router-text" routerLink="/forgotPassword"
            >Forgot Password ?</a
          >
          <div class="mt-5">
            <button
              type="submit"
              [disabled]="loginForm.invalid || (service.isLoading | async)"
              class="btn btn-primary"
            >
              Sign In
              <div
                *ngIf="service.isLoading | async"
                class="spinner-border spinner-border-sm"
                role="status"
              ></div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="toast-container position-fixed top-0 end-0 p-3">
  <div
    [ngClass]="{ show: auth.errorMessage }"
    id="liveToast"
    class="toast border-danger"
  >
    <div class="toast-header">
      <strong class="me-auto text-danger">Error</strong>
      <button
        (click)="auth.errorMessage = ''"
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">
      <span class="text-danger">{{ auth.errorMessage }}</span>
    </div>
  </div>
</div>
