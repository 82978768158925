import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  public async generatePdf(
    elementId: any,
    filename: string,
    backgroundColor: string,
    margin: number
  ) {
    const element = document.getElementById(elementId);
    html2canvas(element).then((canvas) => {
      const pdf =
        window.innerWidth > 599
          ? new jsPDF('p', 'mm', 'a4')
          : new jsPDF('p', 'mm', [200, 600]);
      pdf.setFillColor(backgroundColor);
      pdf.rect(
        0,
        0,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height,
        'F'
      );
      if (window.innerWidth > 599) {
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          margin,
          margin,
          pdf.internal.pageSize.width - 2 * margin,
          pdf.internal.pageSize.height - 2 * margin
        );
      } else {
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          30,
          5,
          pdf.internal.pageSize.width / 1.5,
          (canvas.height * 100) / canvas.width
        );
      }

      pdf.save(filename);
    });
  }
}
