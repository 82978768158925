<div class="student-enrollments-wrap">
  <div class="filter-main-wrap">
    <div class="d-flex filter">
      <div class="filter-title-wrap">
        <h5>Applicants to Enrollments</h5>
        <p>
          Employees who have applied to and started with<br />
          Chamberlain University or Walden University
        </p>
      </div>
      <div class="d-flex flex-column align-self-lg-stretch filter-sec">
        <div
          class="filter d-flex justify-content-end align-items-center"
          *ngIf="!isSmallerDevice; else diversityMobile"
        >
          <app-diversity-modal
            [chartType]="applicantsChartType"
            [diversityInfo]="diversityInfo"
            [loading]="false"
            [graphTitle]="'Applicants to Enrollments'"
          ></app-diversity-modal>
          <app-filter-popup-modal
            [alphaGroup]="'Hospital'"
            [loading]="loading"
            [title]="'Applicants to Enrollments'"
            (popUpState)="getState($event)"
            [data]="filterLabels"
            [hospitals]="hospitalList"
            (selectedValues)="getSelectedValues($event)"
            (reset)="reset($event)"
          ></app-filter-popup-modal>
        </div>
        <ng-template #diversityMobile>
          <div class="diversity-align">
            <app-filter-popup-modal
              [loading]="loading"
              (popUpState)="getState($event)"
              (reset)="reset($event)"
              [title]="'Applicants to Enrollment'"
              (selectedValues)="getSelectedValues($event)"
              [data]="filterLabels"
            ></app-filter-popup-modal>
            <app-diversity-modal
              [chartType]="applicantsChartType"
              [diversityInfo]="diversityInfo"
              [loading]="false"
              [graphTitle]="'Applicants to Enrollments'"
            ></app-diversity-modal>
          </div>
        </ng-template>
      </div>
    </div>
    <app-filter-chips
      class="chip-container mt-3 mb-3"
      (emitRemovedFilter)="closedFilters($event)"
      [selectedFilters]="selectedFilters"
    ></app-filter-chips>
    <div
      class="custom-legend horizontal"
      [ngClass]="{ lightColor: graphLightColor }"
    >
      <ul>
        <li *ngFor="let item of appToEntrolInfo">
          <span></span>
          <div class="tooltip-wrapper">
            <small>{{ item.content }}</small>
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="chart-section">
    <app-info-graphic
      [chartData]="chartDataCount"
      [chartType]="'horizontalbar'"
      [otherProgramDetails]="otherProgramDetails"
      [labelInfo]="labelInfo"
    ></app-info-graphic>
  </div>
</div>
