import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { OtherProgramInfo } from '../../services/entity/shared.entity';
import { TableHeader } from '../model/customtypes-model.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-other-program-mobile-float',
  templateUrl: './other-program-mobile-float.component.html',
  styleUrls: ['./other-program-mobile-float.component.scss'],
})
export class OtherProgramMobileFloatComponent implements OnInit, OnDestroy {
  @ViewChild('otherPopupContainer') public popupContainer: TemplateRef<any>;
  @ViewChild('arrowContainer') public arrowContainer: TemplateRef<any>;

  public viewContainerRef: ViewContainerRef;
  public openModal: boolean = false;
  public otherProgramInfo: OtherProgramInfo = new OtherProgramInfo();
  public isOpenTotal: boolean = false;
  public subscriptionRefArray: Subscription[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sharedService: SharedService,
    viewContainerRef: ViewContainerRef
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngOnInit() {
    this.sharedService.openOtherProgramMob$.subscribe(
      async (isOpen: boolean) => {
        this.openModal = isOpen;
        this.isOpenTotal = false;
        this.viewContainerRef.clear();

        if (this.openModal) {
          if (!document.getElementById('otherProgramMob')) {
            await this.getStorageData();
            await this.createProgramView();
          }
        }
      }
    );
  }

  public async createProgramView() {
    await this.createEmbeddedView(this.popupContainer);

    if (this.otherProgramInfo.totalYearWiseInfo.length) {
      await this.createEmbeddedView(this.arrowContainer);
    }
  }

  public async createEmbeddedView(viewContainer) {
    const embeddedViewRef =
      this.viewContainerRef.createEmbeddedView(viewContainer);

    if (embeddedViewRef) {
      for (const node of embeddedViewRef.rootNodes) {
        this.document.body.appendChild(node);
      }
    }
  }

  public async getStorageData() {
    this.subscriptionRefArray.push(
      this.sharedService.otherProgramData$.subscribe(
        async (programInfo: OtherProgramInfo) => {
          if (programInfo && Object.keys(programInfo).length) {
            this.otherProgramInfo = new OtherProgramInfo();
            this.otherProgramInfo = programInfo;
            await this.removeTheProgramLabel();
          } else {
            this.otherProgramInfo = new OtherProgramInfo();
          }
        }
      )
    );
  }

  public async removeTheProgramLabel() {
    this.otherProgramInfo.labelInfo = this.otherProgramInfo.labelInfo.filter(
      (x: TableHeader) => x.value !== 'program'
    );
    this.otherProgramInfo.totalYearWiseInfo =
      this.otherProgramInfo.totalYearWiseInfo.filter(
        (item) => item !== 'Total'
      );
  }

  public openTotalInfo() {
    this.isOpenTotal = !this.isOpenTotal;
  }

  public unSubscribeSubscription() {
    this.subscriptionRefArray.forEach((s) => s.unsubscribe());
  }

  public ngOnDestroy(): void {
    this.unSubscribeSubscription();
  }
}
