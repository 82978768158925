<div
  class="faq-query-popup"
  *ngIf="showQueryDiv"
  (click)="onClickPopupWrapper()"
  #popupContainer
>
  <div
    class="faq-query-popup-sub-wrap"
    (click)="onClickPopupSubWrapper($event)"
  >
    <ng-scrollbar [track]="'vertical'" class="my-scrollbar">
      <div class="modal-header">
        <button (click)="closePopup()">
          <span class="icon-close-icon close"></span>
        </button>
      </div>
      <div class="modal-body">
        <h4>Share Your Thoughts: Questions & Feedback</h4>
        <div class="support-mail">
          <span><b>Email to:</b> support.partnerportal@adtalem.com</span>
        </div>
        <div class="mob-filterItems">
          <div class="form-items">
            <label>Your Name</label>
            <input class="input" type="text" [(ngModel)]="userName" disabled />
          </div>
          <div class="form-items">
            <label>Your Email</label>
            <input class="input" type="text" [(ngModel)]="userEmail" disabled />
          </div>
        </div>
        <div class="query-filterItems textarea">
          <label>Questions/Feedback<span>*</span></label>
          <textarea
            [(ngModel)]="questions"
            (ngModelChange)="checkLength()"
            class="input-query"
            cols="30"
            rows="10"
            minlength="10"
            maxlength="1000"
            [ngClass]="{
              'empty-box': isTextareaEmpty,
              'red-box': smallFeedback
            }"
            placeholder="1000 characters limit"
          ></textarea>
          <div *ngIf="isTextareaEmpty">
            <span class="text-danger"
              >The Questions/Feedback cannot be empty!</span
            >
          </div>
          <div *ngIf="smallFeedback">
            <span class="text-danger">Minimum of 10 characters required</span>
          </div>
        </div>
        <div class="query-filterItems mb-2">
          <div class="upload-btn">
            <input
              type="file"
              #fileInput
              (change)="onFileSelected($event)"
              accept=".pdf, .png, .docx"
              max="5"
              multiple
              hidden
            />
            <button type="button" (click)="triggerFileInput()" class="file-btn">
              Add attachment
              <span class="icon-attachment"></span>
            </button>
            <span class="file-types" *ngIf="selectedFileNames.length < 1"
              >(.png, .pdf, .docx)</span
            >
          </div>
          <ul>
            <li *ngFor="let fileName of selectedFileNames; let i = index">
              {{ fileName.name }}
              <span class="icon-close-icon" (click)="removeFile(i)"></span>
            </li>
          </ul>
        </div>
        <div class="upload-error" *ngIf="noFile">
          <span class="text-danger"
            >Only PNG, PDF and DOCX files are allowed</span
          >
        </div>
        <div class="upload-error" *ngIf="fileSize">
          <span class="text-danger"
            >Uploaded file size should be less than 10MB</span
          >
        </div>
      </div>
      <div class="modal-footer">
        <div class="max-size">Maximum upload file size: 10 MB</div>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="
            disableBtn || isTextareaEmpty || smallFeedback || fileSize
          "
          (click)="querySubmission()"
        >
          <span *ngIf="buttonText">Submit</span>
          <span class="spinner-border" *ngIf="btnLoader"></span>
        </button>
      </div>
    </ng-scrollbar>
  </div>
</div>
<div class="faq-query-sub-popup" *ngIf="showQuerySubmission">
  <div class="faq-query-sub-popup-sub-wrap">
    <div class="modal-header">
      <button (click)="closePopup()">
        <span class="icon-close-icon close"></span>
      </button>
    </div>
    <div class="modal-body mb-0">
      <h4>Thanks for the feedback.</h4>
      <p class="text mb-2">
        Our representative may get in touch with you if required.
      </p>
    </div>
  </div>
</div>
