<div
  class="nav-container"
  [ngClass]="[
    productTourActive ? 'moreIndex' : '',
    headerActive ? 'isScroll' : '',
    popupData ? 'popupActive' : '',
    fiscalYearActive ? 'fiscalYear' : ''
  ]"
>
  <div class="s-nav">
    <app-secondary-nav
      [navLinks]="navItems"
      [activeLink]="'Partner Dashboard'"
    ></app-secondary-nav>
  </div>
  <app-product-tour></app-product-tour>
  <app-year-format></app-year-format>
</div>

<div class="page-container" [ngClass]="{ popupActive: popupData }">
  <div class="pages">
    <ng-scrollbar
      #scrollbarRef
      [track]="'vertical'"
      [autoHeightDisabled]="true"
      [autoWidthDisabled]="true"
      class="my-scrollbar"
      [ngClass]="{ isScrollDisable: exportClicked === true }"
      style="{{ 'height:' + headerAreaHeight + 'px' }}"
    >
      <div
        #top
        class="scroll-main-wrap pt-4"
        [ngClass]="{ 'p-15': disableExport, 'pad-0': !exportVisible }"
      >
        <router-outlet></router-outlet>
      </div>
    </ng-scrollbar>
    <ng-container *ngIf="currentScroll > 20 && isDisableScroll">
      <div
        (click)="scrollbarRef.scrollToElement(top)"
        [class.customTop]="!router.url.includes('lad')"
        class="to-top apple"
      >
        <span class="icon-arrow-down"></span>
      </div>
    </ng-container>
    <ng-container
      *ngIf="!router.url.includes('lad') && !router.url.includes('faq')"
    >
      <app-mobile-floating-nav
        *ngIf="floatingNavHide"
      ></app-mobile-floating-nav>
    </ng-container>
  </div>
</div>
<div *ngIf="loading" class="loader-container">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
