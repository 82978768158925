import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { TransferStateService } from '@scullyio/ng-lib';
import { ActivatedRoute } from '@angular/router';
import { CommonComponent } from '../common-component';
import { SharedService } from '../../../../shared/services/shared.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ChartType, YearQuarter } from '../../../../shared/services/enum/shared.enum';
import { CdnService } from '../../../../shared/services/cdn.service';
import {
  AnticipatedAllData,
  AnticipatedGradDateDynamic,
  AnticipatedGrateDate,
  AnticipatedStudentDetails,
  FilterData,
  FilterItems,
  GraduationProgramYear,
  ProgramDynamicFilter,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import { AnticipatedGradService } from '../../../../shared/services/diversity/anticipated-grad/anticipated-grad.service';

@Component({
  selector: 'app-anticipated-grad-date',
  templateUrl: './anticipated-grad-date.component.html',
  styleUrls: ['./anticipated-grad-date.component.scss'],
})
export class AnticipatedGradDateComponent
  extends CommonComponent
  implements OnInit {
  public chartDataCount: any;
  public selectedFilters: any;
  public filteredStudentDetails: AnticipatedStudentDetails = {};
  public otherProgramDetails: any[] = [];
  public labelInfo: any[] = [];
  public totalInfo: any;
  public totalYearWiseInfo: any = [];
  public filterLabels: any;
  public loading: boolean;
  public yearQuarters: string[] = ['Q1', 'Q2', 'Q3', 'Q4'];
  public filterData: FilterData;
  public userName: string;
  public hospitalList: AnticipatedGrateDate[];
  public sessionData: AnticipatedGradDateDynamic;
  public yearFormat: boolean = false;
  public anticipatedDynamicAll: AnticipatedAllData;
  public anticipatedGradYearChartType: ChartType = ChartType.anticipatedGradYear;
  public diversityInfo: any[] = [];
  public isSmallerDevice: Boolean = false;

  constructor(
    as: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private sharedService: SharedService,
    private analytics: AngularFireAnalytics,
    private cdnService: CdnService,
    private analyticsService: AnalyticsService,
    private anticipatedService:AnticipatedGradService
  ) {
    super(as, tss, ar);
  }

  @Input() set anticipatedGradDateData(data: any) {
    if (data) {
      this.filterData = data;
    }
  }

  // Get labels for current organisation for studentEnrollment
  public ngOnInit(): void {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData(
          'anticipatedGradDateFilter'
        ),
        format
      );
    });
    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );
    // getting filter data studentEnrollment
    this.anticipatedDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'anticipatedGradDateAll'
    );
    this.getYearFormat();
    const defaultFilter =
      this.filteredStudentDetails?.data?.anticipatedGradDate[0];

    this.setDiversityDetails(defaultFilter?.programYear);

    if (window.innerWidth < 575) {
      this.isSmallerDevice = true;
    }
    this.chartDataCount = this.defineChartObj(defaultFilter?.programYear);
    this.sessionData = this.cdnService.getSessionData('anticipatedGradDate');
    if (this.filterData) {
      this.getSelectedValues(this.filterData);
    }
  }
  // Get and arrange the data according to predefined programs, and calculate values of programs according to years
  public defineChartObj(allData: GraduationProgramYear[]) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    if (allData == undefined || allData == null || allData.length == 0) {
      return null;
    }

    const filterPrgmYears = this.filterProgramYears(allData);
    const formattedResponse =
      this.getTheFormattedDataIncludingMissingYears(filterPrgmYears);
    const result = this.generateResult(formattedResponse);
    const minChartData = this.checkMiniumDataLength(result);
    const data = minChartData || [];
    this.createOtherProgramDetails(data);
    const chartLabels = this.generateChartLabels(data);
    const programCounts = this.generateProgramCounts(data, predefinedPgms);
    const OTHERS = this.generateOthers(data, predefinedPgms);

    let chartData: any[] = this.constructChartData(programCounts);
    chartData.push({ labels: 'OTHERS', data: OTHERS });
    return {
      labels: chartLabels,
      chartData,
    };
  }

  public filterProgramYears(allData: GraduationProgramYear[]): any {
    return allData.reduce((acc, x: GraduationProgramYear) => {
      const anticipatedGradYear = x?.anticipatedGradYear;
      if (anticipatedGradYear != null) {
        if (!acc[anticipatedGradYear]) {
          acc[anticipatedGradYear] = {};
        }
        const anticipatedGradQuarter = x?.anticipatedGradQuarter;
        if (!acc[anticipatedGradYear][anticipatedGradQuarter]) {
          acc[anticipatedGradYear][anticipatedGradQuarter] = [];
        }
        acc[anticipatedGradYear][anticipatedGradQuarter].push(x);
      }
      return acc;
    }, {});
  }

  public generateResult(filterPrgmYears: any): any {
    const result: any[] = [];
    Object.keys(filterPrgmYears).forEach((year: any) => {
      const yearData = filterPrgmYears[year];
      Object.keys(yearData)
        .sort()
        .forEach((quarter: any) => {
          const quarterData = filterPrgmYears[year][quarter];
          result.push({
            enrollmentYear: quarterData[0]?.enrollmentYear,
            anticipatedGradQuarter: quarter,
            anticipatedGradYear: year,
            programsCount:
              this.sharedService.getOneOfAllSumForAnticipatedGradDate(
                quarterData
              ),
          });
        });
    });

    return result;
  }

  public generateProgramCounts(data: any[], predefinedPgms: string[]): any {
    return predefinedPgms.reduce((acc, program) => {
      acc[program] = data.map((entry) => entry.programsCount[program] || null);
      return acc;
    }, {});
  }

  public generateOthers(data: any[], predefinedPgms: string[]): any[] {
    return data.map((q: any) => {
      return Object.entries(q.programsCount)
        .filter((obj: any) => !predefinedPgms.includes(obj[0]))
        .map((b: any) => b[1])
        .reduce((accumulator: any, reducer: any) => accumulator + reducer, 0);
    });
  }

  public constructChartData(programCounts: any): any[] {
    return Object.keys(programCounts).map((program) => ({
      labels: program,
      data: programCounts[program],
    }));
  }

  // get selected filter data for user selected combinations
  public getSelectedValues($event: any) {
    this.selectedFilters = $event;
    let filterData: any = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['anticipatedGradDate'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Anticipated_Grad_Year_Filter_${$event?.Start_Year}_${$event?.Institution}_${$event?.Location}_${$event?.Hospital}`
    );
    const orderOfKeys = ['fiscal_year', 'institution', 'location', 'hospital'];
    if (this.filteredStudentDetails?.data?.anticipatedGradDate?.length === 1) {
      this.filteredStudentDetails?.data?.anticipatedGradDate?.push(
        ...this.sessionData?.data?.anticipatedGradDate
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: Object.values($event)[i] };
      });
    }

    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        this.filteredStudentDetails?.data?.anticipatedGradDate,
        orderOfKeys,
        'anticipatedGradDate',
        this.yearFormat
      )
      .then((filter: any) => {
        this.getDataForChart(filter);
      });
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Anticipated_Grad_Year_Filter_${this.analyticsService.convertArrayToString(
          $event?.Start_Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  public async getDataForChart(filter: any) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(filter?.programYear);
      if(filter.filteredData){
        let diversityInfo= 
        await this.anticipatedService.createAnticipatedGradYearDiversityDetails(
          JSON.parse(JSON.stringify(filter?.filteredData))
        )
        this.setDiversityDetails(diversityInfo);
      }
      else{
        this.setDiversityDetails(filter?.programYear)
      }
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }
  // Reset filter data
  public reset($event: any) {
    if ($event) {
      this.filterLabels = this.filterLabels?.map((x: any) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: any) {
    if ($event === 'open') {
      this.hospitalList = this.sessionData?.data.anticipatedGradDate;
      this.filterLabels = this.filterLabels?.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: any) {
    this.getSelectedValues({ ...this.selectedFilters, ...$event });
    const index: any = this.filterLabels?.findIndex(
      (x: any) => x.filterLabel === Object?.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(anticipatedGrad: GraduationProgramYear[]) {
    this.otherProgramDetails = this.getOtherProgramDetails(anticipatedGrad);
    this.labelInfo = [];
    anticipatedGrad.forEach((entry) => {
      let anticipatedGradQuarter = YearQuarter[entry['anticipatedGradQuarter']];
      this.labelInfo.push(
        anticipatedGradQuarter?.concat(' ', entry['anticipatedGradYear'])
      );
    });
    this.getTotalOtherProgramCount(this.labelInfo, this.otherProgramDetails);
  }

  // To filter all other program details
  public getOtherProgramDetails(data: GraduationProgramYear[]) {
    const exclusionList = new Set(['RNBSN', 'RNMSN', 'MSN', 'DNP']);

    // Create an object to store counts for each program across years
    const programCounts = {};

    // Initialize counts for each program for all years to zero
    data.forEach((entry) => {
      let anticipatedGradQuarter = YearQuarter[entry['anticipatedGradQuarter']];
      Object.keys(entry.programsCount)
        .filter((program) => !exclusionList.has(program))
        .forEach((program) => {
          if (!programCounts[program]) {
            programCounts[program] = {};
            let newKey = anticipatedGradQuarter?.concat(
              ' ',
              entry['anticipatedGradYear']
            );
            programCounts[program][newKey] = 0; // Initialize count to zero
          }
        });
    });

    // Update counts based on available data
    data.forEach((entry) => {
      let anticipatedGradQuarter = YearQuarter[entry['anticipatedGradQuarter']];
      Object.entries(entry.programsCount)
        .filter(([program]) => !exclusionList.has(program))
        .forEach(([program, count]) => {
          let newKey = anticipatedGradQuarter?.concat(
            ' ',
            entry['anticipatedGradYear']
          );
          programCounts[program][newKey] = count;
        });
    });

    // Construct the final array of objects
    const result = Object.entries(programCounts).map(([program, counts]) => {
      if (typeof counts !== 'object' || counts === null) {
        return { program }; // Only include program if counts is not an object
      }
      return {
        program,
        ...counts, // Spread counts if it's an object
      };
    });
    return result;
  }

  public getTotalOtherProgramCount(yearsToSum: number[], result: any[]) {
    this.totalInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
    this.totalYearWiseInfo = this.totalInfo;
    this.totalInfo.unshift('Total');
  }

  public generateChartLabels(data: GraduationProgramYear[]): any[] {
    return data.map((anticipatedGrad: GraduationProgramYear) => {
      let quarterString: string;
      switch (anticipatedGrad.anticipatedGradQuarter) {
        case 'Q1':
          quarterString = 'Jan-Mar';
          break;
        case 'Q2':
          quarterString = 'Apr-Jun';
          break;
        case 'Q3':
          quarterString = 'Jul-Sep';
          break;
        case 'Q4':
          quarterString = 'Oct-Dec';
          break;
        default:
          quarterString = 'Invalid quarter';
      }
      return `${quarterString} ${anticipatedGrad.anticipatedGradYear}`;
    });
  }

  public getTheFormattedDataIncludingMissingYears(data) {
    const currentYear = new Date().getFullYear();
    const currentQuarter = `Q${Math.ceil((new Date().getMonth() + 1) / 3)}`;

    // Get the last year and its quarters
    const lastYear = Math.max(...Object.keys(data).map(Number));
    const lastYearQuarters = Object.keys(data[lastYear]);

    // Populate data starting from current year and quarter
    const newData = {};
    const startQuarterIndex = this.yearQuarters.indexOf(currentQuarter);

    // Ensure the data structure is present for the current year and subsequent quarters
    function ensureDataForYearQuarter(year, quarter) {
      if (!data[year]) {
        data[year] = {};
      }
      if (!data[year][quarter]) {
        data[year][quarter] = [
          {
            enrollmentYear: '',
            anticipatedGradYear: year,
            anticipatedGradQuarter: quarter,
            programsCount: {},
          },
        ];
      }
    }

    for (let year = currentYear; year <= lastYear; year++) {
      newData[year] = {};
      const endQuarterIndex =
        year === lastYear
          ? this.yearQuarters.indexOf(
            lastYearQuarters[lastYearQuarters.length - 1]
          )
          : this.yearQuarters.length - 1;

      for (
        let quarterIndex = year === currentYear ? startQuarterIndex : 0;
        quarterIndex <= endQuarterIndex;
        quarterIndex++
      ) {
        const quarter = this.yearQuarters[quarterIndex];
        ensureDataForYearQuarter(year, quarter);
        newData[year][quarter] = data[year][quarter];
      }
    }

    return newData;
  }

  public checkMiniumDataLength(data) {
    while (data.length < 4) {
      const lastItem = data[data.length - 1];
      const currentQuarterIndex = this.yearQuarters.indexOf(
        lastItem.anticipatedGradQuarter
      );
      let nextQuarterIndex = currentQuarterIndex + 1;
      let nextGradYear = parseInt(lastItem.anticipatedGradYear);

      if (nextQuarterIndex >= this.yearQuarters.length) {
        nextQuarterIndex = 0;
        nextGradYear += 1;
      }

      const nextObject = {
        enrollmentYear: '',
        anticipatedGradQuarter: this.yearQuarters[nextQuarterIndex],
        anticipatedGradYear: nextGradYear.toString(),
        programsCount: {},
      };

      data.push(nextObject);
    }

    return data;
  }

  public checkYearFormat() {
    let tempAnticipatedAll = JSON.parse(
      JSON.stringify(this.anticipatedDynamicAll.data.anticipatedGradDate[0])
    );
    if (this.yearFormat) {
      delete tempAnticipatedAll.calendar;
      tempAnticipatedAll = {
        ...tempAnticipatedAll,
        programYear: tempAnticipatedAll.fiscal.programYear,
      };
      delete tempAnticipatedAll.fiscal;
    } else {
      delete tempAnticipatedAll.fiscal;
      tempAnticipatedAll = {
        ...tempAnticipatedAll,
        programYear: tempAnticipatedAll.calendar.programYear,
      };
      delete tempAnticipatedAll.calendar;
    }
    if (Object.keys(this.filteredStudentDetails).length == 0) {
      this.filteredStudentDetails = {
        data: {
          anticipatedGradDate: [tempAnticipatedAll],
        },
      };
    } else {
      const index =
        this.filteredStudentDetails.data.anticipatedGradDate.findIndex(
          (item) => {
            const filter: ProgramDynamicFilter = item.filter;
            return (
              filter.fiscal_year == 'all' &&
              filter.institution == 'all' &&
              filter.location == 'all' &&
              filter.hospital == 'all'
            );
          }
        );
      if (index > -1) {
        this.filteredStudentDetails.data.anticipatedGradDate[index] =
          tempAnticipatedAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }

  public async setDiversityDetails(sessionData: any) {
    this.diversityInfo = sessionData
  }
}
