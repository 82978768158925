import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../src/environments/environment';

@Pipe({
  name: 'urlTransform',
})
export class UrlTransformPipe implements PipeTransform {
  public transform(value: string) {
    const data = value.split('/');
    const channel = environment.channelId;
    const media = data[data.findIndex((x: any) => x === 'media') + 1];
    return `channel/${channel}/media/${media}`;
  }
}
