<app-title-divider
  *ngIf="isShowTitleDivider"
  title="Partnership Overview"
  [tooltipContent]="tooltipContent"
></app-title-divider>
<div class="overflow-x-hidden">
  <app-list-of-partnership
    [partnershipInfo]="partnershipInfo"
  ></app-list-of-partnership>
  <app-programs-slider [programInfo]="programInfo"></app-programs-slider>
  <app-footer></app-footer>
</div>
