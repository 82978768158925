<div
  class="d-flex justify-content-center align-items-center forgotPassword-page"
>
  <div
    class="col-lg-8 col-md-10 col-sm-10 col-10 shadow forgotPassword-container"
  >
    <div class="row">
      <div class="close" type="button" (click)="close()">
        <img alt="close" [src]="closeButtonUrl" />
      </div>
      <div class="forgotPassword-form-container">
        <figure>
          <img class="m-auto" [src]="logoUrl" alt="" />
        </figure>
        <h4 class="title mt-4">Forgot Password ?</h4>
        <form (ngSubmit)="submitEmail()" [formGroup]="forgotPasswordForm">
          <input
            type="hidden"
            name="CSRFToken"
            value="OWY4NmQwODE4ODRjN2Q2NTlhMmZlYWEwYzU1YWQwMTVhM2JmNGYxYjJiMGI4MjJjZDE1ZDZMGYwMGEwOA=="
          />
          <h4 class="sub-title mt-4">
            Enter the email address associated with your account
          </h4>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              [ngClass]="{
                'is-invalid':
                  forgotPasswordForm.controls['email'].invalid &&
                  (forgotPasswordForm.controls['email'].touched ||
                    forgotPasswordForm.controls['email'].dirty)
              }"
              formControlName="email"
              class="form-control"
              type="text"
            />
            <ng-container
              *ngIf="forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].errors?.['pattern']"
            >
              <small class="text-danger">Invalid email address</small>
            </ng-container>
            <ng-container
              *ngIf="
                forgotPasswordForm.controls['email'].touched &&
                forgotPasswordForm.controls['email'].hasError('required')
              "
            >
              <small class="text-danger">Email is required</small>
            </ng-container>
            <ng-container
              *ngIf="
                forgotPasswordForm.controls['email'].hasError('invalidEmail')
              "
            >
              <small class="text-danger">Invalid user</small>
            </ng-container>
          </div>
          <div class="mt-5">
            <button
              [disabled]="forgotPasswordForm.invalid"
              type="submit"
              class="btn btn-primary"
            >
              Submit
            </button>
          </div>
          <small
            class="text-danger d-block mt-2 text-center"
            *ngIf="hostError"
            >{{ hostErrorMsg }}</small
          >
        </form>
      </div>
    </div>
  </div>
</div>
