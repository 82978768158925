import { Component, OnInit } from '@angular/core';
import { DescriptionTabTooltip } from '../../../shared/services/enum/shared.enum';
import { ApiService } from '../../../shared/services/api.service';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-partnership-overview',
  styleUrls: ['./partnership-overview.component.scss'],
  templateUrl: './partnership-overview.component.html',
})
export class PartnershipOverviewComponent implements OnInit {
  public isShowTitleDivider: boolean = false;
  public tooltipContent: string = DescriptionTabTooltip.partnershipOverview;
  public partnershipInfo: any[] = [];
  public programInfo: any[] = [];

  constructor(
    private apiService: ApiService,
    private sharedService: SharedService
  ) {}

  public async ngOnInit() {
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;

    this.checkWindowWidth();
    this.fetchOverviewObservables(Id);
  }

  public checkWindowWidth(): void {
    if (window.innerWidth < 575) {
      this.isShowTitleDivider = true;
    }
  }

  public fetchOverviewObservables(Id: any): void {
    this.apiService.getOverviewObservables(Id).subscribe((data: any) => {
      this.processOverviewData(data);
    });
  }

  public processOverviewData(data: any): void {
    this.partnershipInfo = this.filterPartnershipInfo(data);
    this.sharedService.updateUniversityStatus(this.partnershipInfo);
    this.programInfo = this.filterProgramInfo(data);
  }

  public filterPartnershipInfo(data: any): any[] {
    return data[0].items
      .filter((items: any) => items.contentNodes?.excerpt?.value === 'Logo')
      .map((items: any) => ({
        ...items,
        status: data[1].contractList?.filter(
          (x: any) => 'CU' || 'WU' === x.RecordType.Name.split(' ')[0]
        ),
      }));
  }

  public filterProgramInfo(data: any): any[] {
    return data[0].items
      .filter(
        (items: any) => items.contentNodes?.Tab?.value === 'PartnershipOverivew'
      )
      .map((items: any) => ({
        ...this.apiService.removeSpecialCharsAndReturnObj(
          items?.contentNodes?.excerpt?.value
        ),
        Number: items?.contentNodes?.SerialNumber?.value,
        url: items?.contentNodes?.Image?.resourceUrl,
        pgmname: items?.contentNodes?.ProgramName?.value,
        university: items?.contentNodes?.University?.value,
        percentage: this.calculatePercentage(items, data),
      }));
  }

  public calculatePercentage(items: any, data: any): any {
    return items?.contentNodes?.University?.value === 'Chamberlain'
      ? data[1].contractList?.find(
          (x: any) =>
            items?.contentNodes?.RecordType?.value === x.RecordType.Name
        )?.Account?.[items?.contentNodes?.AccountType?.value]
      : data[1].contractList.find(
          (x: any) =>
            items?.contentNodes?.RecordType?.value === x.RecordType.Name
        )?.Reduction_Amount__c;
  }
}
