<div class="change-password-popup" *ngIf="ShowPdfDownload">
  <div class="change-password-popup-sub-wrap">
    <div class="modal-header">
      <button (click)="closePdfPopup()">
        <img alt="close" [src]="closeButtonUrl" />
      </button>
    </div>
    <div class="modal-body">
      <h4>
        Are you sure, you want to download insights from Partner Dashboard?
      </h4>
      <p>Partner Dashboard.PDF</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closePdfPopup()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="downloadPDF()">
        Download
      </button>
    </div>
  </div>
</div>
