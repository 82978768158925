<nav
  #topNav
  class="navbar navbar-expand-lg"
  [ngClass]="{ mobNavMenuActive: showResponsiveMenu }"
>
  <div
    class="menu-bar-wrap"
    (click)="openMobileMenu()"
    [ngClass]="{ show: showMenuBar }"
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
  <a class="navbar-brand" (click)="backTodashboard()">
    <img class="logo" [src]="adtalemLogo" alt="Adtalem_logo" />
  </a>
  <div class="user-info ms-auto d-flex">
    <div
      class="pe-3 border-pseudo align-items-center d-flex adtalem-contact-wrap"
      *ngIf="!hideButtons"
    >
      <button class="btn contact-button" (click)="openContact()">
        <span class="icon-adtalem-contacts me-2"></span>
        Adtalem Contacts
      </button>
    </div>
    <div
      class="settings"
      [ngClass]="{ settingsOpen: accountSettings, settingsClose: !settings }"
      *ngIf="!hideButtons"
    >
      <div class="settings-wrap">
        <span class="icon-Settings-icon"></span>
      </div>
      <div class="settings-dropdown-wrap">
        <ul>
          <span class="option-span">User Options</span>
          <li class="light-mode">
            <div class="nav-name">
              <span class="icon icon-Light-Color-Mode"></span>
              <span class="web-view">Color Accessibility Mode</span>
            </div>
            <div>
              <label
                class="light-mode-switch"
                for="checkbox"
                aria-label="color_accessibility"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="isLightColorMode"
                  id="checkbox"
                  (change)="lightColorMode($event)"
                />
                <div class="light-mode-slider round"></div>
              </label>
            </div>
          </li>
          <li class="year-format">
            <div class="year-label">
              <span class="icon-Calendar"></span>
              <span>Year Format</span>
            </div>
            <div class="toggle-btn">
              <input
                type="checkbox"
                id="toggle"
                class="toggleCheckbox"
                [(ngModel)]="isYearFormat"
                (change)="yearFormat($event)"
              />
              <label for="toggle" class="toggleContainer">
                <div>Calendar</div>
                <div>Fiscal</div>
              </label>
            </div>
          </li>
          <li (click)="openProductTourPopup()">
            <span class="icon icon-Product-tour"></span>
            <span>Product Tour</span>
          </li>
          <li class="faq-icon" (click)="faq()">
            <span class="icon icon-Others-Icon"></span>
            <span>FAQ</span>
          </li>
          <li class="question-section" (click)="feedback()">
            <span class="icon icon-Questions-Feedbacks"></span>
            <span>Questions/Feedback</span>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="account"
      [ngClass]="{ activeOpen: accountOpen, activeClose: !profile }"
    >
      <div class="avatar-wrap">
        <div (click)="makeProfileActive()" class="avatar">
          <img [src]="avatar" alt="profile" class="img-fluid" />
        </div>
        <span class="icon-Profile-Dropdown"></span>
      </div>
      <div class="account-dropdown-wrap">
        <div class="bg-image"></div>
        <ng-container *ngIf="loaderShow; else elsetemplate">
          <ul class="loader">
            <li class="spinner-border spinner-border-sm" role="status"></li>
          </ul>
        </ng-container>
        <ng-template #elsetemplate>
          <ul>
            <li class="profile-icon">
              <img [src]="profileAvatarUrl" alt="profile" class="img-fluid" />
            </li>
            <li
              class="userName"
              *ngFor="let item of usernameData; trackBy: trackByFn"
            >
              <p>{{ item.FirstName }} {{ item.LastName }}</p>
            </li>
            <li>
              <span class="icon-user-icon"></span>{{ auth.userData?.email }}
            </li>
            <li (click)="openChangePasswordPopup()">
              <span class="icon-change-password-icon"></span> Change Password
            </li>
            <li (click)="logout()">
              <span class="icon-logout-icon"></span>Logout
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
</nav>

<div
  *ngIf="showResponsiveMenu"
  [style.top]="defineHeight(topNav.offsetHeight)"
  class="mob-nav w-100"
>
  <ul class="col-12">
    <li class="nav-item dropdown">
      <a
        (click)="toggleDropdown()"
        href="javascript:void(0)"
        class="nav-link pp-bg-primary text-white dropdown-toggle"
      >
        Dashboard
      </a>
      <ul class="bg-white" *ngIf="dropdown">
        <li *ngFor="let links of navItems" class="border-bottom">
          <a
            (click)="sideNav()"
            [class.active]="router.url.includes(links?.key)"
            class="dropdown-item"
            [routerLink]="links?.route"
            >{{ links?.name }}</a
          >
        </li>
      </ul>
    </li>
  </ul>
</div>

<app-popup-modal
  [scrollDirection]="'horizontal'"
  [openModal]="openModal"
  [url]="'contact'"
  [subtitle]="'Adtalem Contacts'"
  [title]="'Strategic Workforce Solutions'"
  (closeModal)="closeModal($event)"
>
  <div *ngIf="!loading && openModal; else loader">
    <div class="d-flex align-content-around">
      <div class="card-wrap">
        <div
          class="card card-style"
          *ngFor="let cards of popUpDataObj; let i = index"
        >
          <div class="card-main-wrap">
            <div class="row mb-3">
              <div class="col card-content-wrap">
                <div class="card-block">
                  <p class="card-text subtitle-color card-text-title">
                    {{ cards?.RoleInTerritory2 }}
                  </p>
                  <p class="title-color">{{ cards?.User?.Name }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card-block">
                  <p class="title-color fw-semibold m-auto font-head pt-2">
                    Email
                  </p>
                  <a
                    href="mailto:{{ cards.User.Email }}"
                    class="h-link font-child"
                    >{{ cards.User.Email }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loader>
    <div class="popup-loader">
      <div class="spinner-border spinner-border-sm" role="status"></div>
    </div>
  </ng-template>
</app-popup-modal>

<app-common-modal></app-common-modal>
<app-faq-modal></app-faq-modal>
<app-pdf-modal></app-pdf-modal>
<app-custom-card-footer></app-custom-card-footer>
<app-other-popup-modal></app-other-popup-modal>
<app-other-program-mobile-float></app-other-program-mobile-float>
<app-export-filter></app-export-filter>
<app-add-org></app-add-org>
<app-se-filter-popup></app-se-filter-popup>
