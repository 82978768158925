<button
  [title]="''"
  [class]="loading ? 'remove-icon-right' : null"
  class="btn pp-button"
  (click)="openPopup()"
>
  {{ label }}
  <div
    *ngIf="loading"
    class="spinner-border spinner-border-sm"
    role="status"
  ></div>
</button>
