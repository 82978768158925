import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import {
  ProviderData,
  NavItem,
} from '../../shared/components/model/customtypes-model.interface';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { DescriptionTabTooltip } from '../../shared/services/enum/shared.enum';
import { AnalyticsService } from '../../shared/services/analytics/analytics.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnDestroy, OnInit {
  public navItems: NavItem[] = [
    {
      route: `/dashboard/partner-dashboard/${this.sharedService.getOrgId()}`,
      key: 'partner-dashboard',
      name: 'Partner Dashboard',
      content: DescriptionTabTooltip.partnerDashboard,
      isVisible: true,
    },
    {
      route: `/dashboard/clinical-insights/${this.sharedService.getOrgId()}`,
      key: 'clinical-insights',
      name: 'Clinical Insights',
      content: '',
      isVisible: true,
    },
    {
      route: '/dashboard/partnership-overview',
      key: 'partnership-overview',
      name: 'Partnership Overview',
      content: DescriptionTabTooltip.partnershipOverview,
      isVisible: true,
    },
    {
      route: '/dashboard/lad-information-materials',
      key: 'lad-information-materials',
      name: 'L&D Information Materials',
      content: '',
      isVisible: true,
    },
    {
      route: '/dashboard/admin/org-list',
      key: 'admin',
      name: 'Admin',
      content: '',
      isVisible: false,
    },
  ];

  public currentScroll: number;
  public floatingNavHide = true;
  public headerActive: boolean = false;
  public headerAreaHeight = 0;
  public loading = true;
  public popupData: any;
  public subscriptionRefArray: Subscription[] = [];
  public productTourActive: any;
  public exportClicked: any;
  public disableExport = false;
  public exportRemoval = [
    '/dashboard/partnership-overview',
    '/dashboard/lad-information-materials',
    '/dashboard/clinical-insights',
  ];
  public exportVisible = true;
  public isDisableScroll: boolean = true;
  public fiscalYearActive: any;

  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar;
  @ViewChild('top') public scrollableEl: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public sharedService: SharedService,
    public router: Router,
    private auth: AuthService,
    private analytics: AngularFireAnalytics,
    public analyticsService: AnalyticsService
  ) {
    this.analytics.setCurrentScreen(window.location.pathname);
  }
  // after page init then set to scroll top and bottom,depend on condition
  public ngAfterViewInit(): void {
    this.headerAreaHeight = window.innerHeight - 120;
    this.subscriptionRefArray.push(
      this.scrollbarRef.scrolled.subscribe((e) => {
        this.currentScroll = e.target.scrollTop;
        if (this.currentScroll >= 20) {
          this.sharedService.isNgScrollReachedMiddle.next(true);
        } else {
          this.sharedService.isNgScrollReachedMiddle.next(false);
        }
        if (
          e.target.scrollTop + e.target.clientHeight >=
          e.target.scrollHeight
        ) {
          this.sharedService.isNgScrollReachedBottom.next(true);
        } else {
          this.sharedService.isNgScrollReachedBottom.next(false);
        }
      })
    );
  }
  public async ngOnInit() {
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.exportVisible = !this.exportRemoval.find(
      (url) => url === snapshot.url
    );
    this.auth.afAuth.authState.subscribe((data: ProviderData) => {
      this.loading = !data;
    });
    this.sharedService.FloatingMenuEvent$.subscribe((NavVal: boolean) => {
      this.floatingNavHide = NavVal;
    });
    this.sharedService.exportClicked$.subscribe((NavVal: any) => {
      this.exportClicked = NavVal;
    });

    this.sharedService.isNgScrollReachedMiddle$.subscribe((x: boolean) => {
      this.headerActive = x;
    });

    this.sharedService.exportDisabled$.subscribe((NavVal: any) => {
      this.disableExport = NavVal;
    });

    this.sharedService.openOtherProgramMob$.subscribe((isOpen: boolean) => {
      this.isDisableScroll = !isOpen;
    });
    if (!this.router.url.includes('faq')) {
      this.sharedService.faqMob.next(false);
    }
    setTimeout(() => {
      if (!sessionStorage.getItem('showTour')) {
        this.productTourActive = true;
      }
    }, 45000);
    this.sharedService.yearFormat$.subscribe((format: any) => {
      if (format === true) {
        this.fiscalYearActive = true;
      }
    });

    (await this.analyticsService.getAnalyticsData()).subscribe((analytics) => {
      this.analyticsService.uploadJsonToFirebase(analytics, 'analytics-data');
    });
    const orgAnalytics = await this.analyticsService.getAnalytics(
      'organization',
      'organization-user'
    );
    if (orgAnalytics.exists) {
      await this.analyticsService.uploadOrgUsersToFirebase(
        orgAnalytics.data(),
        'organization_users'
      );
    }
  }

  // remove fitler data from session storage
  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
