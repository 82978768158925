import { Injectable } from '@angular/core';
import {
  LearnerAnalytics,
  LearnerAnalyticsFilterMap,
} from '../../components/model/customtypes-model.interface';

@Injectable({
  providedIn: 'root',
})
export class HospitalSharedService {
  // Retrieve parent hospital where the orgid and hospital id are same
  public getParentHospitalOrg(data: any[]) {
    return data.filter(
      (analytics) => analytics.orgId === analytics.filter.hospitalId
    );
  }

  // Check the parent org and child org are same
  public checkDuplicatesOnChildHospital(
    data: LearnerAnalytics[],
    filterObj: LearnerAnalyticsFilterMap,
    parentOrgName: string
  ) {
    let displayedParentOrgName = this.setParentHospitalName(parentOrgName);
    let displayedChildOrgName = parentOrgName;

    if (filterObj.hasOwnProperty('hospital')) {
      let hospitalList = filterObj['hospital'];

      const hospitalArray = Array.isArray(hospitalList)
        ? hospitalList
        : [hospitalList];

      if (
        (hospitalArray.length == 1 &&
          hospitalArray.includes(displayedParentOrgName)) ||
        (!hospitalArray.includes(displayedChildOrgName) &&
          hospitalArray.includes(displayedParentOrgName))
      ) {
        data = this.removeDuplicateChildOrganizations(data, parentOrgName);
      } else if (
        (hospitalArray.length == 1 &&
          hospitalArray.includes(displayedChildOrgName)) ||
        (hospitalArray.includes(displayedChildOrgName) &&
          !hospitalArray.includes(displayedParentOrgName))
      ) {
        data = this.removeParentOrganizations(data, parentOrgName);
      }

      filterObj = this.replaceParentOrgName(
        filterObj,
        parentOrgName,
        displayedParentOrgName
      );
    }

    return { data, filterObj };
  }

  // Set the parent hospital name
  public setParentHospitalName(parentOrgName: string) {
    return parentOrgName.concat(' - Parent');
  }

  // Read organization name from localStorage
  public getParentOrganizationName() {
    let organization = '';
    organization = localStorage.getItem('organization');

    if (organization && /\u00A0/g.test(organization)) {
      organization = organization.replace(/\u00A0/g, ' ');
    }

    return organization;
  }

  // Replacing the - Parent with the original org name to filter the data
  public replaceParentOrgName(
    filterObj: LearnerAnalyticsFilterMap,
    parentOrgName: string,
    displayedParentOrgName: string
  ) {
    if (filterObj.hasOwnProperty('hospital')) {
      if (typeof filterObj.hospital === 'string') {
        if (filterObj.hospital === displayedParentOrgName) {
          filterObj.hospital = parentOrgName;
        }
      } else if (Array.isArray(filterObj.hospital)) {
        filterObj.hospital = filterObj.hospital.map((hospitalName) =>
          hospitalName === displayedParentOrgName ? parentOrgName : hospitalName
        );
      }
    }

    return filterObj;
  }

  // Remove the same named child organization to get only the parent org datas
  public removeDuplicateChildOrganizations(
    data: LearnerAnalytics[],
    parentOrgName: string
  ): LearnerAnalytics[] {
    let childOrganizations = data.filter(
      (analytics) =>
        analytics.filter.hospital.toLowerCase() ==
          parentOrgName.toLowerCase() &&
        analytics.orgId != analytics.filter.hospitalId
    );

    if (childOrganizations.length > 0) {
      return data.filter(
        (item: LearnerAnalytics) =>
          !childOrganizations.some(
            (obj) => JSON.stringify(obj) === JSON.stringify(item)
          )
      );
    } else {
      return data;
    }
  }

  // Remove the parent organization to get only the child org datas
  public removeParentOrganizations(
    data: LearnerAnalytics[],
    parentOrgName: string
  ): LearnerAnalytics[] {
    let parentOrganizations = data.filter(
      (analytics) =>
        analytics.filter.hospital.toLowerCase() ==
          parentOrgName.toLowerCase() &&
        analytics.orgId == analytics.filter.hospitalId
    );

    if (parentOrganizations.length > 0) {
      return data.filter(
        (item: LearnerAnalytics) =>
          !parentOrganizations.some(
            (obj) => obj.filter.hospitalId === item.filter.hospitalId
          )
      );
    } else {
      return data;
    }
  }
}
