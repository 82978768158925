<header
  [class.customHeader]="isFaq || isVisibileOtherProgram || isVisibileDiversity"
>
  <div class="header-container">
    <app-pp-nav
      [popUpData]="contactDetailObj"
      [userData]="userData"
    ></app-pp-nav>
    <div
      class="content d-flex"
      [ngClass]="{ isScroll: headerActive ? true : null, dNone: disableExport }"
      *ngIf="!isFaq"
    >
      <ng-container
        *ngIf="
          !isFaq && !isVisibileOtherProgram && !isVisibileDiversity;
          else faq
        "
      >
        <div class="col-12" *ngFor="let item of userData">
          <h4 class="mb-2">
            Welcome <span>{{ item.FirstName | titlecase }}</span
            >,
          </h4>
          <p>Here’s what is happening with your partnership today</p>
        </div>
      </ng-container>
      <ng-template #faq>
        <div
          class="col-12 faq-header other-pgm-header"
          *ngIf="isVisibileOtherProgram"
        >
          <h3 class="faq-text">
            {{ otherProgramInfo.popupTitle }} - Other Programs
          </h3>
          <p class="subtitle">
            Total {{ otherProgramInfo.totalProgramCount }} Programs
          </p>
          <span
            class="icon-arrow-down backButton"
            (click)="backTodashboard()"
          ></span>
        </div>
        <div
          class="col-12 faq-header other-pgm-header"
          *ngIf="isVisibileDiversity"
        >
          <h3 class="faq-text">
            Diversity Details - {{ diversityData.graphTitle }}
          </h3>
          <p class="subtitle">Varied Statistics</p>
          <span
            class="icon-arrow-down backButton"
            (click)="backTodashboard()"
          ></span>
        </div>
      </ng-template>
    </div>
    <div
      class="content d-flex faq-content"
      *ngIf="isFaq && !isVisibileOtherProgram && !isVisibileDiversity"
    >
      <div class="col-12 faq-header">
        <img class="faq-img" [src]="faqImageUrl" alt="faq banner image" />
        <h3 class="faq-text">Frequently Asked Questions (FAQ)</h3>
        <span
          class="icon-arrow-down backButton"
          (click)="backTodashboard()"
        ></span>
        <p class="text">Learn it well to do it well.</p>
      </div>
    </div>
  </div>
</header>
<div class="feedback-btn" (click)="openQueryBox()" *ngIf="!hideButtons">
  <span>Questions/Feedback</span>
</div>
