import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DiversityService } from '../../services/diversity/diversity.service';
import { ChartType } from '../../services/enum/shared.enum';

@Component({
  selector: 'app-diversity-button',
  templateUrl: './diversity-button.component.html',
  styleUrls: ['./diversity-button.component.scss'],
})
export class DiversityButtonComponent {
  @Input() public loading = false;
  @Input() public diversityInfo: any[] = [];
  @Input() public chartType: ChartType;
  @Input() public graphTitle: string;
  @Output() public onOpenDiversity = new EventEmitter();

  constructor(
    public sharedService: SharedService,
    public diversityService: DiversityService
  ) {}
  // Emitted button click action
  public openPopup() {
    this.sharedService.diversityDetails.next(true);
    this.onOpenDiversity.emit(true);
    this.diversityService.createDiversityTableInfo(
      this.chartType,
      this.graphTitle,
      this.diversityInfo
    );
    this.sharedService.FloatingMenuEvent.next(false);
  }
}
