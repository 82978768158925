import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../../shared/services/api.service';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Diversity } from '../../../shared/components/model/customtypes-model.interface';
import {
  DiversityInfo,
  OtherProgramInfo,
} from '../../../shared/services/entity/shared.entity';
import { getFormattedCurrentDateTime } from '../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userData: any;
  public contactDetailObj: any;
  public userDataSubscription: Subscription;
  public productTourActive = false;
  public isScrolled = false;
  public headerActive: boolean;
  public disableExport = false;
  public isVisibileOtherProgram: boolean = false;
  public otherProgramInfo: OtherProgramInfo = new OtherProgramInfo();
  public isVisibileDiversity: boolean = false;
  public diversityDataInfo: Diversity[] = [];
  public diversityData: DiversityInfo = new DiversityInfo();
  public isFaq: boolean = false;
  public faqImageUrl: string = environment.assetDirectory.concat(
    '/assets/images/faq-img.png'
  );
  public userName: string;
  public hideButtons: boolean = false;
  constructor(
    public auth: AuthService,
    private sharedService: SharedService,
    private analytics: AngularFireAnalytics,
    private apiService: ApiService,
    public router: Router,
    public analyticsService: AnalyticsService
  ) {}
  @HostListener('window:scroll', [])
  public onScroll(): void {
    this.isScrolled = window.scrollY > 10;
  }
  // Initial load and get user details like emailId , profile pictures
  public async ngOnInit() {
    const role = localStorage.getItem('role');
    if (role == 'admin') {
      this.hideButtons = true;
    }
    this.sharedService.userDetails.subscribe((data) => {
      if (data) {
        const orgDetails = localStorage.getItem('userDetailId');
        if (orgDetails && JSON.parse(orgDetails)) {
          const currentOrg = JSON.parse(orgDetails);
          this.userData = currentOrg;
          this.apiService
            .getServices(currentOrg[0]?.Account?.Id)
            .subscribe((acr: any) => {
              this.contactDetailObj = acr.accountContactRelation;
              this.analytics.logEvent(
                `${currentOrg[0]?.FirstName} ${currentOrg[0]?.LastName} from ${currentOrg[0]?.Account?.Name}`
              );
            });
        } else {
          this.apiService.orgObservable.subscribe((data: any) => {
            if (data) {
              this.userData = data?.userDetails;
            }
          });
        }
      }
    });
    this.sharedService.productTourEvent$.subscribe((NavVal: any) => {
      this.productTourActive = NavVal;
    });

    this.sharedService.faqMob$.subscribe((isOpen: boolean) => {
      this.isFaq = isOpen;
    });

    this.sharedService.openOtherProgramMob$.subscribe((isOpen: boolean) => {
      this.isVisibileOtherProgram = isOpen;
      this.headerActive = false;

      if (this.isVisibileOtherProgram) {
        this.sharedService.otherProgramData$.subscribe(
          (programInfo: OtherProgramInfo) => {
            if (programInfo && Object.keys(programInfo).length) {
              this.otherProgramInfo = new OtherProgramInfo();
              this.otherProgramInfo = programInfo;
            } else {
              this.otherProgramInfo = new OtherProgramInfo();
            }
          }
        );
      }
    });

    this.sharedService.isNgScrollReachedMiddle$.subscribe((x: any) => {
      this.headerActive = x;
    });

    this.sharedService.exportDisabled$.subscribe((NavVal: any) => {
      this.disableExport = NavVal;
    });

    this.sharedService.diversityDetails$.subscribe(async (data: boolean) => {
      if (window.innerWidth < 575) {
        this.isVisibileDiversity = data;

        if (data) {
          this.isVisibileDiversity = data;
          this.sharedService.otherProgramData$.subscribe(
            async (diversityInfo) => {
              if (diversityInfo && Object.keys(diversityInfo).length) {
                this.diversityDataInfo = diversityInfo['data'];
                this.diversityData = this.diversityDataInfo[0].data;
              }
            }
          );
        }
      }
    });
  }
  // Unsubscribe subscripition

  public ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  public backTodashboard() {
    this.router.navigate([
      `dashboard/learner-insights/${this.sharedService.getOrgId()}`,
    ]);
    this.closeOtherProgramMob();
  }

  public closeOtherProgramMob() {
    this.sharedService.openOtherProgramMob.next(false);
    this.sharedService.diversityDetails.next(false);
    this.sharedService.FloatingMenuEvent.next(true);
    this.sharedService.otherProgramData.next({});
    this.sharedService.faqMob.next(false);
  }

  public openQueryBox() {
    this.sharedService.faqModalEvent.next(true);
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        'Question/Feedback clicked',
        time,
        sessionId
      );
    }
  }
}
