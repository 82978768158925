<div class="student-enrollments-wrap">
  <div class="filter-main-wrap">
    <div class="filter-title-wrap">
      <h5>Alumni</h5>
      <p>
        Employees who graduated from Chamberlain University or Walden University
      </p>
    </div>
    <div class="d-flex flex-column align-self-lg-stretch filter-sec">
      <div class="filter d-flex justify-content-end align-items-center"
      *ngIf="!isSmallerDevice; else diversityMobile" >
      <app-diversity-modal
          [chartType]="alumniChartType"
          [diversityInfo]="diversityInfo"
          [loading]="false"
          [graphTitle]="alumniChartType"
        ></app-diversity-modal>
        <app-filter-popup-modal
          class="popup-left"
          [loading]="loading"
          (popUpState)="getState($event)"
          (reset)="reset($event)"
          [title]="'Alumni'"
          [data]="filterLabels"
          [hospitals]="hospitalList"
          (selectedValues)="getSelectedValues($event)"
        >
        </app-filter-popup-modal>
      </div>
      <ng-template #diversityMobile>
        <div class="diversity-align">
          <app-filter-popup-modal
              [loading]="loading"
              (popUpState)="getState($event)"
              (reset)="reset($event)"
              [title]="'Alumni'"
              (selectedValues)="getSelectedValues($event)"
              [data]="filterLabels"
            ></app-filter-popup-modal>
          <app-diversity-modal
            [chartType]="alumniChartType"
            [diversityInfo]="diversityInfo"
            [loading]="false"
            [graphTitle]="alumniChartType"
          ></app-diversity-modal>
        </div>
      </ng-template>
    </div>
  </div>
  <app-filter-chips
    class="chip-container mt-3 mb-3"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <app-info-graphic
    [chartData]="chartDataCount"
    [chartType]="'halfdoughnut'"
    [otherProgramDetails]="otherProgramDetails"
    [labelInfo]="labelInfo"
    [totalYearWiseInfo]="totalYearWiseInfo"
  >
  </app-info-graphic>
</div>
