<div
  #editBottomWrap
  class="edit-bottom-wrap d-flex justify-content-between"
  *ngIf="cancelOrderFalse"
>
  <div class="edit-left-wrap">
    <button class="cancel-btn" (click)="orderReset()">Reset</button>
    <button class="cancel-btn-mob" (click)="orderReset()">
      <span class="icon-Reset-icon"></span>
    </button>
  </div>
  <div class="edit-right-wrap">
    <button class="cancel-btn me-4" (click)="cancelOrder()">Cancel</button>
    <button class="save-btn" (click)="saveChildOrder()">Save</button>
  </div>
</div>
