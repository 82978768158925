import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CallbackComponent } from './modules/callback/callback.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { EmailVerficationComponent } from './modules/login/email-verfication/email-verfication.component';
import { ForgotpasswordComponent } from './modules/login/forgotPassword/forgotpassword.component';
import { LoginComponent } from './modules/login/login.component';
import { SfLoaderComponent } from './modules/sf-loader/sf-loader.component';
import { FaqComponent } from './modules/dashboard/faq/faq.component';

// Redirect paths and Lazy loading
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'authenticating', component: SfLoaderComponent },
  { path: 'forgotPassword', component: ForgotpasswordComponent },
  { path: 'verifyEmail', component: EmailVerficationComponent },
  { path: 'faq', component: FaqComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
