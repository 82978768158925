import {
  AfterViewInit,
  Component,
  Inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../shared/services/api.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-sf-loader',
  templateUrl: './sf-loader.component.html',
  styleUrls: ['./sf-loader.component.scss'],
})
export class SfLoaderComponent implements AfterViewInit {
  @ViewChild('loader') public loaderTemp: TemplateRef<any>;
  private viewContainerRef: ViewContainerRef;
  constructor(
    viewContainerRef: ViewContainerRef,
    private apiService: ApiService,
    private router: Router,
    private analytics: AngularFireAnalytics,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngAfterViewInit(): void {}
}
