import { SharedService } from '../../shared/services/shared.service';
import { Component, HostBinding } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { ApiService } from '../../shared/services/api.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GeneralConfigurationService } from '../../shared/services/general-config/general-config-service';
import {
  getFormattedCurrentDateTime,
  convertDateToCustomFormat,
} from '../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../shared/services/analytics/analytics.service';
import { FirestoreService } from '../../shared/services/firebase/firebase-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @HostBinding('class') public classStyle = 'h-100 d-block';
  public loginForm: FormGroup = new FormGroup<any>({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9.]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,}$'),
    ]),
    password: new FormControl('', [Validators.required]),
  });

  public fieldPasswordType: boolean = false;
  public loading: boolean;
  public hostError: boolean = false;
  public hostErrorMsg: string;
  public timer: string = '';
  public minutes = 0;
  public hour = 0;
  public seconds = 0;

  public setTimer: string = '';
  public isStart: boolean = false;
  public timerInterval: any;
  public logoUrl: string = environment.assetDirectory.concat(
    '/assets/images/adtalem_logo.svg'
  );
  constructor(
    public auth: AuthService,
    public sharedService: SharedService,
    public service: ApiService,
    private analytics: AngularFireAnalytics,
    private generalConfigService: GeneralConfigurationService,
    private firestoreService: FirestoreService
  ) {
    window.addEventListener('logout', (event: CustomEvent) => {
      if (event.detail === 'logouttriggered') {
        this.analytics.logEvent(`user_portal_time_${this.timer}`);
        clearInterval(this.timerInterval);
      }
    });
  }

  public async login() {
    if (!this.loginForm.invalid) {
      const formValues = this.loginForm.getRawValue();
      this.auth
        .SignIn(formValues.email, formValues.password)
        .then((result) => {
          this.auth.SetUserData(result.user);
          this.analytics.logEvent('user_login');
          this.start();
          this.sharedService.localStorageSubject.next(null);
          this.sharedService.cacheCleared = false;
          this.analytics.logEvent('firebase_login');

          this.firestoreService.getUserRole(result.user.uid).subscribe(role => {
            localStorage.setItem('role', role);
            if (role == 'admin'){
              localStorage.setItem('providerId','admin');
            }else if (role == 'partner'){
              localStorage.setItem('providerId','firebase');
            }
          });
          setTimeout(() => {
            this.generalConfigService.saveUserLoginCount();
          }, 1000);
        })
        .catch((error) => {
          if (
            error?.message.includes('wrong-password') ||
            error?.message.includes('INVALID_PASSWORD')
          ) {
            this.loginForm.controls['password'].setErrors({
              invalidPassword: true,
            });
          } else if (error?.message.includes('user-not-found')) {
            this.loginForm.controls['email'].setErrors({ invalidEmail: true });
          } else {
            const splitted = error?.message.split(':');
            this.hostErrorMsg = splitted[1];
            this.hostError = true;
          }
        });
    }
  }
  public start() {
    this.isStart = true;
    this.timerInterval = setInterval(() => {
      if (this.minutes > 59) {
        // to increment the hour value
        this.hour = this.hour + 1;
        this.seconds = 0;
        this.minutes = 0;
      }

      if (this.seconds > 59) {
        // to calculate minutes
        this.minutes = this.minutes + 1;
        this.seconds = 0;
      }

      this.seconds = this.seconds + 1;
    }, 1000);
  }
  public toggleTypePassword() {
    this.fieldPasswordType = !this.fieldPasswordType;
  }


}
