import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CdnService } from '../../../../shared/services/cdn.service';
import {
  Alumni,
  AlumniAllData,
  AlumniDynamic,
  AlumniStudentDetails,
  FilterData,
  FilterItems,
  ProgramDynamicFilter,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';

@Component({
  selector: 'app-alumni-program',
  templateUrl: './alumni-program.component.html',
  styleUrls: ['./alumni-program.component.scss'],
})
export class AlumniProgramComponent extends CommonComponent implements OnInit {
  public chartDataCount: any;
  public selectedFilters: any;
  public filteredStudentDetails: AlumniStudentDetails = {};
  public filterLabels: any;
  public loading: boolean;
  public otherProgramDetails: any[] = [];
  public labelInfo: any[] = [];
  public totalInfo: any;
  public totalYearWiseInfo: any;
  public filterData: FilterData;
  public userName: string;
  public hospitalList: Alumni[];
  public sessionData: AlumniDynamic;
  public yearFormat: boolean = false;
  public alumniDynamicAll: AlumniAllData;
  constructor(
    as: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private sharedService: SharedService,
    private analytics: AngularFireAnalytics,
    private cdnService: CdnService,
    private analyticsService: AnalyticsService
  ) {
    super(as, tss, ar);
  }

  @Input() set alumniData(data: any) {
    if (data) {
      this.filterData = data;
    }
  }

  // Get labels for current organisation for studentEnrollment
  public async ngOnInit() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData('alumniByProgramFilter'),
        format
      );
    });

    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );
    // getting filter data studentEnrollment
    this.alumniDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'alumniByProgramDynamicAll'
    );
    this.getYearFormat();
    const defaultFilter = this.filteredStudentDetails?.data?.alumniByProgram[0];
    this.chartDataCount = this.defineChartObj(defaultFilter?.programYear);
    this.sessionData = this.cdnService.getSessionData('alumniByProgramDynamic');
    if (this.filterData) {
      this.getSelectedValues(this.filterData);
    }
  }
  // Get and arrange the data according to predefined programs, and calculate values of programs according to years
  public defineChartObj(data: any) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    this.createOtherProgramDetails(data ? data : []);

    if (data) {
      const programCounts = {};

      predefinedPgms.forEach((program) => {
        programCounts[program] = data
          .map((entry) => entry.programsCount[program] || null)
          .reduce((accumulator: any, reducer: any) => accumulator + reducer, 0);
      });

      const OTHERS: any = data
        ?.map((q: any) => {
          return Object.entries(q.programsCount)
            .filter((obj: any) => !predefinedPgms.includes(obj[0]))
            .map((b: any) => b[1])
            .reduce(
              (accumulator: any, reducer: any) => accumulator + reducer,
              0
            );
        })
        .reduce((accumulator: any, reducer: any) => accumulator + reducer, 0);

      let chartData: any[] = Object.keys(programCounts).map((program) => ({
        labels: program,
        data: programCounts[program],
      }));

      chartData.push({ labels: 'OTHERS', data: OTHERS });
      return {
        chartData,
      };
    } else {
      return null;
    }
  }
  // get selected filter data for user selected combinations
  public getSelectedValues($event: any) {
    this.selectedFilters = $event;
    let filterData: any = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['alumni'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Alumni_Filter_${$event?.Start_Year}_${$event?.Institution}_${$event?.Location}_${$event?.Hospital}`
    );

    const orderOfKeys = ['fiscal_year', 'institution', 'location', 'hospital'];
    if (this.filteredStudentDetails?.data?.alumniByProgram?.length === 1) {
      this.filteredStudentDetails?.data?.alumniByProgram?.push(
        ...this.sessionData?.data?.alumniByProgram
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: Object.values($event)[i] };
      });
    }

    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        JSON.parse(
          JSON.stringify(this.filteredStudentDetails?.data?.alumniByProgram)
        ),
        orderOfKeys,
        'alumniProgram',
        this.yearFormat
      )
      .then((filter: any) => {
        this.getDataForChart(filter);
      });
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Alumni_Filter_${this.analyticsService.convertArrayToString(
          $event?.Start_Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  public async getDataForChart(filter: any) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(filter?.programYear);
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }
  // Reset filter data
  public reset($event: any) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: any) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: any) {
    if ($event === 'open') {
      this.hospitalList = this.sessionData?.data.alumniByProgram;
      this.filterLabels = this.filterLabels.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: any) {
    this.getSelectedValues({ ...this.selectedFilters, ...$event });
    const index: any = this.filterLabels.findIndex(
      (x: any) => x.filterLabel === Object.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(aluminiPrograms: any[]) {
    this.labelInfo = aluminiPrograms
      .map(({ year }) => year)
      .sort((a, b) => a - b);
    this.otherProgramDetails = this.getOtherProgramDetails(aluminiPrograms);
    this.getTotalOtherProgramCount(this.labelInfo, this.otherProgramDetails);
  }

  // To filter all other program details
  public getOtherProgramDetails(data) {
    const exclusionList = new Set(['RNBSN', 'RNMSN', 'MSN', 'DNP']);

    // Create an object to store counts for each program across years
    const programCounts = {};

    // Extract all years
    const years = data.map((entry) => entry.year);

    // Initialize counts for each program for all years to zero
    data.forEach((entry) => {
      Object.keys(entry.programsCount)
        .filter((program) => !exclusionList.has(program))
        .forEach((program) => {
          if (!programCounts[program]) {
            programCounts[program] = {};
            years.forEach((year) => {
              programCounts[program][year] = 0; // Initialize count to zero
            });
          }
        });
    });

    // Update counts based on available data
    data.forEach((entry) => {
      Object.entries(entry.programsCount)
        .filter(([program]) => !exclusionList.has(program))
        .forEach(([program, count]) => {
          programCounts[program][entry.year] = count;
        });
    });

    // Construct the final array of objects
    const result: any = Object.entries(programCounts).map(
      ([program, counts]) => {
        if (typeof counts !== 'object' || counts === null) {
          return { program }; // Only include program if counts is not an object
        }
        return {
          program,
          ...counts, // Spread counts if it's an object
        };
      }
    );

    const newData = result.map(({ program, ...rest }) => {
      const Total = Object.values(rest).reduce((acc, val) => acc + val, 0);
      return { program, Total, ...rest };
    });

    return newData;
  }

  public getTotalOtherProgramCount(yearsToSum, result) {
    this.totalYearWiseInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
  }

  public checkYearFormat() {
    let tempAlumniAll = JSON.parse(
      JSON.stringify(this.alumniDynamicAll.data.alumniByProgram[0])
    );
    if (this.yearFormat) {
      delete tempAlumniAll.calendar;
      tempAlumniAll = {
        ...tempAlumniAll,
        programYear: tempAlumniAll.fiscal.programYear,
      };
      delete tempAlumniAll.fiscal;
    } else {
      delete tempAlumniAll.fiscal;
      tempAlumniAll = {
        ...tempAlumniAll,
        programYear: tempAlumniAll.calendar.programYear,
      };
      delete tempAlumniAll.calendar;
    }
    if (Object.keys(this.filteredStudentDetails).length == 0) {
      this.filteredStudentDetails = {
        data: {
          alumniByProgram: [tempAlumniAll],
        },
      };
    } else {
      const index = this.filteredStudentDetails.data.alumniByProgram.findIndex(
        (item) => {
          const filter: ProgramDynamicFilter = item.filter;
          return (
            filter.fiscal_year == 'all' &&
            filter.institution == 'all' &&
            filter.location == 'all' &&
            filter.hospital == 'all'
          );
        }
      );
      if (index > -1) {
        this.filteredStudentDetails.data.alumniByProgram[index] = tempAlumniAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }
}
