import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';
import { ChartType, DiversityFilter } from '../enum/shared.enum';
import { Diversity } from '../../components/model/customtypes-model.interface';
import { LearnerDiversityService } from './learner-analytics/learner-diversity.service';

@Injectable({
  providedIn: 'root',
})
export class DiversityService {
  constructor(
    public sharedService: SharedService,
    public learanerDiversityService: LearnerDiversityService
  ) {}

  public async createDiversityTableInfo(
    chartType: ChartType,
    graphTitle: string,
    sessionData: any
  ) {
    let tableData: Diversity[] = [];
    switch (chartType) {
      case ChartType.learnerAnalytics: {
        tableData =
          await this.learanerDiversityService.createLearnerDiversityTableInfo(
            graphTitle,
            sessionData
          );
        break;
      }
      default:
        this.handleDefaultCase();
    }

    this.sharedService.otherProgramData.next({ data: tableData });
  }

  public async getDiversityFilter() {
    return Object.values(DiversityFilter);
  }

  public handleDefaultCase() {
    return [];
  }
}
