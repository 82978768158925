<div class="change-password-popup" *ngIf="ShowChangePasswordPopup">
  <div class="change-password-popup-sub-wrap">
    <div class="modal-header">
      <button (click)="closePopup()">
        <img alt="close" [src]="closeButtonUrl" />
      </button>
    </div>
    <div class="modal-body">
      <h4>Do you want to change your password...?</h4>
      <p>A link will be sent to your email</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="checkMail()">
        Change Password
      </button>
      <small class="text-danger d-block mt-2 text-center" *ngIf="hostError">{{
        hostErrorMsg
      }}</small>
    </div>
    <p class="popup-error" *ngIf="mailNotSend">
      Reset Mail not sent, please check internet connection and try again
    </p>
  </div>
</div>

<div class="change-password-popup" *ngIf="ShowCheckMailPopup">
  <div class="change-password-popup-sub-wrap">
    <div class="modal-header">
      <button (click)="closePopup()">
        <img alt="close" [src]="closeButtonUrl" />
      </button>
    </div>
    <div class="modal-body">
      <h4>Check your mail…!</h4>
      <p>Reset password link sent to your email.</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn back-to-login-btn btn-primary"
        (click)="logout()"
      >
        Back to login
      </button>
    </div>
  </div>
</div>

<div class="hospital-analytics-popup" *ngIf="showhospitalAnalyticsPopup">
  <div class="hospital-analytics-popup-sub-wrap">
    <div class="modal-header border-bottom">
      <div class="title">
        <h4>Hospital Analytics</h4>
      </div>
      <p (click)="clearAll()">Clear All</p>
      <button (click)="closePopup()">
        <img alt="close" [src]="closeButtonUrl" />
      </button>
    </div>
    <div class="filter w-100 d-flex justify-content-end align-items-center">
      <div class="search">Quick Search</div>
      <div class="custom-ng-select">
        <ng-select
          [clearable]="false"
          class="search-box"
          [placeholder]="placeholderValue"
          (input)="onInputChange($event)"
          (click)="search()"
          (keyup)="onSearch($event)"
          [(ngModel)]="selectedHospital"
        >
        </ng-select>
      </div>
      <div
        *ngIf="isPopupVisible"
        class="filter-card search-card shadow-lg reusable"
      >
        <div class="popupHead">
          <div class="container title-container">
            <div class="d-flex flex-row">
              <div class="popupHead-title">
                <div class="popup">
                  <ul>
                    <li
                      *ngFor="let hospital of filteredHospitals"
                      [value]="selectedHospital"
                      (click)="selectHospital(hospital)"
                    >
                      {{ hospital }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tour-popup" *ngIf="showTourWelcome">
  <div class="tour-popup-sub-wrap">
    <ng-container *ngIf="loaderTrue; else elsetemplate">
      <div class="loader">
        <div class="spinner-border" role="status"></div>
      </div>
    </ng-container>
    <ng-template #elsetemplate>
      <div class="modal-header">
        <button (click)="closePopup()">
          <img alt="close" [src]="closeButtonUrl" />
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ contentItems[currentIndex] }}</h4>
        <p class="subtitle">
          {{ bodyContentItems[currentIndex]?.substring(31, 107) }}<br />
          {{ bodyContentItems[currentIndex]?.substring(108) }}
        </p>
        <div class="tour-image-wrap">
          <img class="logo" [src]="imageUrls[currentIndex]" alt="tour-img" />
        </div>
        <p>{{ contentDescriptionItems[currentIndex] }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="getStarted()">
          Get Started
        </button>
      </div>
    </ng-template>
  </div>
</div>

<div class="tour-content-popup" *ngIf="showTourNavigate">
  <div
    class="tour-content-popup-sub-wrap"
    [ngClass]="{ lastSlide: lastSlideActive }"
  >
    <div class="modal-header">
      <button (click)="closePopup()">
        <img alt="close" [src]="closeButtonUrl" />
      </button>
    </div>
    <div class="modal-body">
      <h4 class="web">{{ contentItems[currentIndex] }}</h4>
      <h4 class="mob">{{ contentMobItems[currentIndex] }}</h4>
      <div class="carouseal-image">
        <img class="web" [src]="imageUrls[currentIndex]" alt="images" />
        <img class="mob" [src]="imageMobUrls[currentIndex]" alt="images" />
      </div>
      <p
        class="carouseal-title web"
        [innerHTML]="getDescription(currentIndex)"
      ></p>
      <p
        class="carouseal-title mob"
        [innerHTML]="getDescription(currentIndex)"
      ></p>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="!exploreWindow"
        type="button"
        class="btn btn-secondary"
        (click)="previousNavigation()"
      >
        Previous
      </button>
      <div class="carousel">
        <span
          *ngFor="let items of contentItems; let i = index"
          [class.active]="i === currentIndex - 1"
          class="dot"
        ></span>
      </div>
      <button
        *ngIf="!exploreWindow"
        type="button"
        class="btn btn-primary"
        (click)="nextNavigation()"
      >
        Next
      </button>
      <button
        *ngIf="exploreWindow"
        type="button"
        class="btn btn-primary"
        (click)="explore()"
      >
        Let’s Explore
      </button>
    </div>
  </div>
</div>
