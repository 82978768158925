import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SFTokenInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const mail: any = localStorage.getItem('ppUserEmail');
    const isLoggedIn = localStorage.getItem('ppUserToken');
    const isApiUrl = request.url.startsWith(environment.sfBaseURL);

    if (isLoggedIn && isApiUrl && mail) {
      request = request.clone({
        setHeaders: {
          jwttoken: isLoggedIn,
          email: mail,
        },
      });
    }

    return next.handle(request);
  }
}
