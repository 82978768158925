import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../../shared/services/api.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlTransformPipe } from '../../../../shared/pipe/url-transform.pipe';
import { SharedService } from '../../../../shared/services/shared.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';

@Component({
  selector: 'app-programs-slider',
  templateUrl: './programs-slider.component.html',
  styleUrls: ['./programs-slider.component.scss'],
})
export class ProgramsSliderComponent implements AfterViewInit {
  @ViewChild('nav', { read: DragScrollComponent })
  public ds: DragScrollComponent;
  public openModal: boolean;
  public leftNavDisabled = false;
  public rightNavDisabled = false;
  public partnershipProgram: any;
  public env = environment.sfBaseURL;
  public contactDetailObj: any = [];
  public subscriptionRefArray: Subscription[] = [];
  public contactLoader = true;
  public images: any;
  public subscription: Subscription;
  public statusArray: any[] = [];
  public dataLoader: boolean = true;
  public userName: string;

  constructor(
    public apiService: ApiService,
    private http: HttpClient,
    public url: UrlTransformPipe,
    private analytics: AngularFireAnalytics,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService
  ) {}

  @Input() set programInfo(data: any) {
    if (data.length) {
      this.sliderFiterStatus(data);
      this.getTheProgramImages();
    }

    this.dataLoader = false;
  }

  public ngOnInit(): void {
    this.subscription = this.sharedService.mobNavEvent.subscribe(
      (message: any) => {}
    );
  }

  public ngAfterContentInit(): void {
    this.getData();
  }

  // Getting user detail id and user information
  public ngAfterViewInit() {
    // Starting ngx-drag-scroll from specified index(0)
    setTimeout(() => {
      this.ds.moveTo(0);
    }, 0);
  }

  // Open contact modal
  public openContact() {
    this.sharedService.menuBarEvent.next(true);
    this.sharedService.FloatingMenuEvent.next(false);
    this.openModal = true;
    this.analytics.logEvent('View: Strategic Partner Executive');
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        'Contact your Strategic Partner Executives clicked',
        time,
        sessionId
      );
    }
  }

  // Close contact modal
  public closeModal($event: any) {
    this.openModal = $event;
  }

  // getting partner status and content with account id
  public getData() {
    const userDetailId: any = localStorage.getItem('userDetailId');
    const Id = userDetailId ? JSON.parse(userDetailId)[0]?.Account?.Id : null;
    this.dataLoader = true;

    this.subscriptionRefArray.push(
      this.apiService.getServices(Id).subscribe((data: any) => {
        this.contactLoader = false;
        this.contactDetailObj = data.accountContactRelation;
      })
    );
  }
  // contentKey is a unique property
  public trackByContentKey(index: number, data: any): string {
    return data.contentKey; // Assuming that userDetailId is a unique property
  }

  // Id is a unique property
  public trackBycontactId(index: number, data: any): string {
    return data.Id;
  }

  // moving left drag scroll
  public moveLeft() {
    this.ds.moveLeft();
  }
  // moving right drag scroll
  public moveRight() {
    this.ds.moveRight();
  }

  public leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  public rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  public getFileFromUrl(url: any) {
    const apiHeader: HttpHeaders = new HttpHeaders(environment.header);
    return this.http
      .get(`${this.env}/image/${this.url.transform(url)}`, {
        headers: apiHeader,
        responseType: 'blob',
      })
      .subscribe((blob: Blob) => {
        this.convertBlobToBase64(blob);
      });
  }
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async sliderFiterStatus(slideprogram) {
    let filteredData = [];
    this.dataLoader = true;
    let currentDisplayedUniversities = [
      'Chamberlain University',
      'Walden University',
    ];
    let currentDisplayedStatus = ['Active', 'Expired'];

    this.sharedService.getUniversityStatus().subscribe((data) => {
      this.statusArray = data;
    });

    //  Filter Stage_c and university from array
    //  Filter university array string based on active and expired

    let filteredUniversities = this.statusArray
      .map((item) => ({
        universityName: item.title,
        Stage__C: item.status.find(
          (status) =>
            (item.title === 'Chamberlain University' &&
              status.RecordType.Name === 'CU Educational Agreement') ||
            (item.title === 'Walden University' &&
              status.RecordType.Name === 'WU Educational Agreement')
        )?.Stage__c, // Optional chaining to avoid errors if no status is found
      }))
      .filter(
        (item) =>
          item.Stage__C &&
          currentDisplayedUniversities.includes(item.universityName) &&
          currentDisplayedStatus.includes(item.Stage__C)
      )
      .map((item) => item.universityName); // Remove items with undefined Stage__C

    // Compare with all programSlider array university with filter unversity array string (Expired and active)

    filteredData = slideprogram.filter((item) =>
      filteredUniversities
        .map((item) => item.split(' ')[0])
        .includes(item.university.split(' ')[0])
    );

    this.partnershipProgram = filteredData;
    this.dataLoader = false;
  }

  public convertBlobToBase64(blob: Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      this.images.push(reader.result as string);
    };
    reader?.readAsDataURL(blob);
  }

  public async getTheProgramImages() {
    this.images = [];

    if (this.partnershipProgram.length) {
      this.partnershipProgram.sort((a, b) => {
        const numA = parseInt(a.Number, 15);
        const numB = parseInt(b.Number, 15);
        return numA - numB;
      });

      this.partnershipProgram.forEach((program) => {
        this.getFileFromUrl(program.url);
        program.percentage = Math.round(program.percentage?.split('%')[0]);
      });
    }
  }
}
