<div class="funnel-custom-chart-wrap">
  <ul>
    <ng-container *ngFor="let item of updatedData">
      <li
        *ngIf="item.value > 0"
        [ngStyle]="{ 'background-color': item.color }"
        [class]="item.name"
      >
        {{ item.value }}
      </li>
    </ng-container>
  </ul>
</div>
