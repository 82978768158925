<section
  class="year-format"
  [ngClass]="{ fiscalYearActive: fiscalYear }"
  *ngIf="!hideButtons"
>
  <div class="fiscal-year-wrap">
    <div class="fiscal-year-img">
      <span class="icon-Calendar"></span>
    </div>
    <div class="fiscal-year-view">
      <p>Your profile settings have been updated to Fiscal Year</p>
      <button (click)="closePopup()">
        <span class="icon-close-icon"></span>
      </button>
    </div>
  </div>
</section>
<section
  class="year-format"
  [ngClass]="{ calendarYearActive: calendarYear }"
  *ngIf="!hideButtons"
>
  <div class="fiscal-year-wrap">
    <div class="fiscal-year-img">
      <span class="icon-Calendar"></span>
    </div>
    <div class="fiscal-year-view">
      <p>Your profile settings have been updated to Calendar Year</p>
      <button (click)="closeCalendarPopup()">
        <span class="icon-close-icon"></span>
      </button>
    </div>
  </div>
</section>
