import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, firstValueFrom, finalize } from 'rxjs';
import {
  jsonToCsv,
  convertJsonToCsv,
  convertEventJsonToExcel,
  convertJsonToExcel,
} from '../functional-handler/functional-handler';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { ApiService } from '../api.service';
import { UserResponse } from '../../components/model/customtypes-model.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private apiService: ApiService
  ) {}

  public async getAnalyticsData(): Promise<Observable<any[]>> {
    return this.firestore.collection('analytics').valueChanges();
  }
  public async getOrgUserData(): Promise<Observable<any[]>> {
    return this.firestore.collection('organization').valueChanges();
  }
  public async getAnalytics(
    collectionName: string,
    documentId: string
  ): Promise<any> {
    return firstValueFrom(
      this.firestore.collection(collectionName).doc(documentId).get()
    );
  }
  public async addAnalytics(
    name: string,
    email: string,
    orgName: string,
    action: string,
    time: string,
    sessionId: string
  ) {
    const user = JSON.parse(localStorage.getItem('user'));
    const uid = user.uid;
    const analytics = await this.getAnalytics('analytics', uid);
    const analyticsData = analytics.data();
    if (analytics.exists) {
      const actions = {
        action: action,
        time: time,
        sessionId: sessionId,
      };
      analyticsData.logEvent.events.push(actions);
      this.firestore.collection('analytics').doc(uid).update(analyticsData);
    } else {
      if (uid) {
        const analyticsEvent = {
          logEvent: {
            events: [
              {
                action: action,
                time: time,
                sessionId: sessionId,
              },
            ],
            email: email,
            userName: name,
            orgName: orgName,
          },
        };
        this.firestore.collection('analytics').doc(uid).set(analyticsEvent);
      } else {
        throw new Error('Failed to create analytics');
      }
    }
  }

  public async addOrganizationData() {
    let orgDetails = [];
    let allOrgDetails = {
      data: [],
      Orgs: { orgNames: [], count: 0 },
      Users: { userNames: [], count: 0 },
    };
    let allAnalytics = [];
    const orgAnalytics = await this.getAnalytics(
      'organization',
      'organization-user'
    );
    (await this.apiService.getUserList()).subscribe(
      async (users: UserResponse) => {
        let userList = users.data.users;
        for (let i = 0; i < userList.length; i++) {
          await this.apiService
            .getUserDetailsByEmail(userList[i])
            .then(async (res: any) => {
              if (res.contactList && res.contactList.length) {
                const userName: string = `${res.contactList[0]?.FirstName} ${res.contactList[0]?.LastName}`;
                const orgName: string = `${res.contactList[0]?.Account.Name}`;
                const existingOrg = orgDetails.find(
                  (data) => data.orgName === orgName
                );
                if (existingOrg) {
                  if (!existingOrg.users.includes(userName)) {
                    existingOrg.users.push(userName);
                  }
                } else {
                  const orgData = {
                    orgName: orgName,
                    users: [userName],
                  };
                  orgDetails.push(orgData);
                }
              }
            });
        }
        const orgName = orgDetails.map(({ orgName }) => orgName);
        allOrgDetails.data = orgDetails;
        allOrgDetails.Orgs.orgNames = orgName;
        allOrgDetails.Orgs.count = orgName.length;
        const userName = orgDetails.map(({ users }) => users).flat();
        allOrgDetails.Users.userNames = userName;
        allOrgDetails.Users.count = userName.length;
        if (orgAnalytics.exists) {
          this.firestore
            .collection('organization')
            .doc('organization-user')
            .update(allOrgDetails);
        } else {
          this.firestore
            .collection('organization')
            .doc('organization-user')
            .set(allOrgDetails);
        }
      }
    );
  }

  public createBlobFromCsv(csv: string): Blob {
    return new Blob([csv], { type: 'text/csv' });
  }

  public uploadJsonToFirebase(jsonData: any[], fileName: string) {
    const csv = jsonToCsv(jsonData);
    const blob = this.createBlobFromCsv(csv);
    const eventBlob = convertEventJsonToExcel(jsonData);
    const filePath = `partner-portal/organization-analytics/${fileName}.csv`;
    const eventFilePath = `partner-portal/organization-analytics/${fileName}.xlsx`;
    const fileRef = this.storage.ref(filePath);
    const eventFileRef = this.storage.ref(eventFilePath);
    const task = this.storage.upload(filePath, blob);
    const eventTask = this.storage.upload(eventFilePath, eventBlob);

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {});
        })
      )
      .subscribe();
    eventTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          eventFileRef.getDownloadURL().subscribe((url) => {});
        })
      )
      .subscribe();
  }

  public uploadOrgUsersToFirebase(jsonData, fileName) {
    if (jsonData != undefined && jsonData != null) {
      this.uploadCsvToFirebase(jsonData, fileName);
      const excelBlob = convertJsonToExcel(jsonData);
      this.uploadExcelToFirebase(excelBlob, fileName);
    }
  }

  public uploadCsvToFirebase(jsonData, fileName) {
    const csv = convertJsonToCsv(jsonData);
    const blob = this.createBlobFromCsv(csv);
    const filePath = `partner-portal/organization-analytics/${fileName}.csv`;

    // Upload the CSV file to Firebase Storage
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, blob);

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {});
        })
      )
      .subscribe();
  }

  public uploadExcelToFirebase(file: Blob, fileName: string) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `partner-portal/organization-analytics/${fileName}.xlsx`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {});
      }
    );
  }

  public convertArrayToString(response: any): string {
    if (Array.isArray(response)) {
      return response.join('_');
    }
    return response;
  }
}
