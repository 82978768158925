import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { User } from '../interfaces/user.interface';
import { Subscription } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from '../../../environments/environment';
import { OAuthProvider, getAuth, signInWithRedirect } from 'firebase/auth';
import { SharedService } from '../../shared/services/shared.service';
import firebase from 'firebase/compat/app';
import { FirestoreService } from '../../shared/services/firebase/firebase-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData: any; // Save logged in user data
  public errorMessage = '';
  public subscriptionRefArray: Subscription[] = [];
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public apiService: ApiService,
    private analytics: AngularFireAnalytics,
    private shared: SharedService,
    private ngZone: NgZone,
    private firestoreService: FirestoreService
  ) {
    /* Saving user data in localStorage when
    logged in and setting up null when logged out */
    try {
      this.afAuth.authState.subscribe((user: any) => {
        if (user) {
          if (localStorage.getItem('ppUserEmail')) {
            user._delegate.email = localStorage.getItem('ppUserEmail');
          }
          this.userData = user;
          localStorage.setItem('user', JSON.stringify(this.userData));
          let role: string;
          const uid: string = this.userData.uid;
          this.firestoreService.getUserRole(uid).subscribe(data => {
            role = data;
            if (role == 'partner') {
              this.getOrgAndLogin(user._delegate?.email);
            } else if (role == 'admin') {
              this.adminPage(role);
            }
            this.removeApikey();
          });
        } else {
          localStorage.removeItem('user');
        }
      });
    } catch (error) {
      console.log('User error', error);
    }
  }

  // Sign in with email/password
  public async SignIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
  // Store user information in firebase storage
  public SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };

    return userRef.set(userData, {
      merge: true,
    });
  }
  // Sign out
  public SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
    });
  }

  public async salesforceLogin(): Promise<firebase.User | null> {
    return new Promise<firebase.User | null>((resolve) => {
      this.afAuth.onAuthStateChanged((user) => {
        this.ngZone.run(() => {
          resolve(user);
        });
        this.router.navigate(['/authenticating']);
        const provider: any = new OAuthProvider('oidc.sf-partner-openid');
        const auth = getAuth();
        signInWithRedirect(auth, provider);
      });
    });
  }

  public isAuthenticated() {
    const user = localStorage.getItem('user');
    return user !== null || this.router.url.includes('callback');
  }

  public ngOnDestroy() {
    if (this.subscriptionRefArray.length) {
      this.subscriptionRefArray.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  public getOrgAndLogin(email: string) {
    try {
      this.apiService.getUserDetails(email).subscribe(
        (res: any) => {
          if (res.contactList && res.contactList.length) {
            localStorage.setItem(
              'userDetailId',
              JSON.stringify([res.contactList[0]])
            );
            this.shared.userDetails.next(true);
            localStorage.setItem(
              'organization',
              res.contactList[0]?.Account?.Name
            );
            this.apiService
              .getServices(res.contactList[0]?.Account?.Id)
              .subscribe((acr: any) => {
                const obj = {
                  acr: acr.accountContactRelation,
                  userDetails: [res.contactList[0]] as any,
                };
                this.analytics.logEvent(
                  `${res.contactList[0]?.FirstName} ${res.contactList[0]?.LastName} from ${res.contactList[0]?.Account?.Name}`
                );
                localStorage.setItem(
                  'organization',
                  res.contactList[0]?.Account?.Name
                );
                this.apiService.hasOrgInfo.next(obj);
                let org: any;
                if (/\u00A0/g.test(res.contactList[0]?.Account?.Name)) {
                  const nonBreakingSpace =
                    res.contactList[0]?.Account?.Name.replace(/\u00A0/g, ' ');
                  org = JSON.parse(environment.partnerOrganizations).find(
                    (x: any) => x.name === nonBreakingSpace
                  );
                } else {
                  org = JSON.parse(environment.partnerOrganizations).find(
                    (x: any) => x.name === res.contactList[0]?.Account?.Name
                  );
                }
                if (
                  !this.router.url.includes('partnership-overview') &&
                  !this.router.url.includes('lad-information-materials') &&
                  !this.router.url.includes('faq') &&
                  !this.router.url.includes(
                    `dashboard/clinical-insights/${org?.id}`
                  )
                ) {
                  this.router.navigate([
                    `dashboard/partner-dashboard/${org?.id}`,
                  ]);
                }
              });
          } else {
            this.router.navigate(['dashboard/partner-dashboard/']);
          }
        },
        (error) => {
          this.router.navigate(['dashboard/partner-dashboard/']);
        }
      );
    } catch (error) {
      console.log('Login error', error);
    }
  }

  public adminPage(role: string) {
    localStorage.setItem(
      'userDetailId',
      JSON.stringify([{  
        "Id": "admin",
      }]),
    );
    this.shared.userDetails.next(true);
    localStorage.setItem(
      'organization',
      role
    );
    if (!this.router.url.includes(`dashboard/admin`)) {
      this.router.navigate([`dashboard/admin/org-list`]);
    }
  }

  public removeApikey() {
    const storedObjectString = localStorage.getItem('user');
    if (storedObjectString) {
      let userData = JSON.parse(storedObjectString);
      delete userData.apiKey;
      const modifiedObjectString = JSON.stringify(userData);
      localStorage.setItem('user', modifiedObjectString);
    }
  }
}
