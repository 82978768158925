import {
  Component,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-pp-button',
  templateUrl: './pp-button.component.html',
  styleUrls: ['./pp-button.component.scss'],
})
export class PpButtonComponent {
  @Input() public label: string = '';
  @Input() public loading = false;
  @Output() public btnClick = new EventEmitter();
  @ViewChild('toggleButton') public toggleButton!: ElementRef;

  // Emitted button click action
  public openPopup() {
    this.btnClick.emit();
  }
}
