<div class="mob-burger-wrap">
  <ul>
    <li *ngFor="let links of subNavItems; let i = index" class="nav-item">
      <ng-container *ngIf="links?.isRoute">
        <a
          (click)="popUp('close', i)"
          [routerLink]="links?.route"
          [class.active]="router.url.includes(links?.key)"
          class="nav-link {{ links?.key }}"
        >
          <img src="{{ links?.img }}" alt="{{ links?.name }}" />
        </a>
      </ng-container>
      <ng-container *ngIf="links?.contact">
        <a
          [class.active]="activeNav[i]"
          (click)="popUp('contact', i)"
          class="nav-link"
        >
          <img src="{{ links?.img }}" alt="{{ links?.name }}" />
        </a>
      </ng-container>
      <ng-container *ngIf="links?.settings">
        <a
          [class.active]="activeNav[i]"
          (click)="popUp('settings', i)"
          class="nav-link settings"
        >
          <img src="{{ links?.img }}" alt="{{ links?.name }}" />
        </a>
      </ng-container>
      <ng-container *ngIf="links?.profile">
        <a
          [class.active]="activeNav[i]"
          (click)="popUp('profile', i)"
          class="nav-link profile"
        >
          <img src="{{ links?.img }}" alt="{{ links?.name }}" />
        </a>
      </ng-container>
    </li>
  </ul>
</div>
