import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public calendarYear: boolean = false;
  public fiscalYear: boolean = false;
  constructor(public router: Router, public sharedService: SharedService) {}

  public ngOnInit() {
    this.sharedService.yearFormat$.subscribe((format: any) => {
      if (format === true) {
        this.fiscalYear = true;
        this.calendarYear = false;
      } else if (format === false) {
        this.calendarYear = true;
        this.fiscalYear = false;
      }
    });
  }
}
