import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private shared: ApiService) {}
  /**
   * Intercept http request
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.shared.show();
    /**
     * Intercept used to handle http request
     */
    return next.handle(request).pipe(finalize(() => this.shared.hide()));
  }
}
