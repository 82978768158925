import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public headerAreaHeight = 0;

  public async ngOnInit() {}

  public ngAfterViewInit(): void {
    this.headerAreaHeight = window.innerHeight - 120;
  }
}
