import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import {
  ChartDataType,
  ChartFilterDataUrlType,
  ChartFilterType,
} from './class-type/chart-type';

@Injectable({
  providedIn: 'root',
})
export class CdnService {
  constructor(public sharedService: SharedService) {}

  public appendSelectedAllToFilterData(chart: ChartFilterType): any[] {
    const sessionData = this.sharedService.sessionOperations.get('filterData');
    const filterData = sessionData?.[chart]?.data;
    return filterData?.map((item) => ({ ...item, selected: 'all' })) || [];
  }

  public changeTheLabel(
    collectedData: any[],
    valueToFind: string,
    valueToReplace: string
  ) {
    let filteredData = collectedData.find(
      (data) => data.filterLabel == valueToFind
    );

    if (filteredData) {
      filteredData.filterLabel = valueToReplace;
    }

    return collectedData;
  }

  public getTheInitialAllFilterData(chart: ChartDataType): any {
    const sessionData = this.sharedService.sessionOperations.get('filterData');
    return sessionData?.[chart];
  }

  public getSessionData(chart: ChartFilterDataUrlType) {
    const sessionData = this.sharedService.sessionOperations.get('filterData');
    return sessionData?.[chart];
  }
}
