import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { TransferStateService } from '@scullyio/ng-lib';
import { ActivatedRoute } from '@angular/router';
import { CommonComponent } from '../common-component';
import { SharedService } from '../../../../shared/services/shared.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CdnService } from '../../../../shared/services/cdn.service';
import {
  FilterData,
  FilterItems,
  ProgramDynamicFilter,
  StudentEnrollment,
  StudentEnrollmentAllData,
  StudentEnrollmentDynamic,
} from '../../../../shared/components/model/customtypes-model.interface';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import { ChartType } from '../../../../shared/services/enum/shared.enum';
import { StudentEnrollmentsService } from '../../../../shared/services/diversity/student-enrollments/student-enrollments.service';
@Component({
  selector: 'app-student-enrollments',
  templateUrl: './student-enrollments.component.html',
  styleUrls: ['./student-enrollments.component.scss'],
})
export class StudentEnrollmentsComponent
  extends CommonComponent
  implements OnInit
{
  public chartDataCount: any;
  public selectedFilters: any;
  public filteredStudentDetails: any = {};
  public otherProgramDetails: any[] = [];
  public totalInfo: any;
  public totalYearWiseInfo: any = [];
  public filterLabels: any;
  public loading: boolean;
  public filterData: FilterData;
  public userName: string;
  public hospitalList: StudentEnrollment[];
  public sessionData: StudentEnrollmentDynamic;
  public yearFormat: boolean = false;
  public studentDynamicAll: StudentEnrollmentAllData;
  public filterType: any = 'yearly';
  public currentWindowWidth: number;
  public enrollmentsChartType: ChartType = ChartType.studentEnrollment;
  public diversityInfo: any[] = [];
  public isSmallerDevice: boolean = false;
  public headerLabel: any[] = [];

  constructor(
    as: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private sharedService: SharedService,
    private analytics: AngularFireAnalytics,
    private cdnService: CdnService,
    private analyticsService: AnalyticsService,
    private studentEnrollmentsService: StudentEnrollmentsService
  ) {
    super(as, tss, ar);
    this.sharedService.applySeFilters = this.applySeFilters.bind(this);
  }

  @Input() set studentEnrollmentData(data: any) {
    if (data) {
      this.filterData = data;
    }
  }

  // Get labels for current organisation for studentEnrollment
  public ngOnInit(): void {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData(
          'studentEnrollmentFilter'
        ),
        format
      );
    });
    this.filterLabels = this.cdnService.changeTheLabel(
      this.filterLabels,
      'Year',
      'Start_Year'
    );
    this.yearReducer(this.filterLabels);

    // getting filter data studentEnrollment
    this.studentDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'studentEnrollmentDynamicAll'
    );
    this.getYearFormat();
    const defaultFilter =
      this.filteredStudentDetails?.data?.studentEnrollments[0];
    this.setDiversityDetails(defaultFilter?.programYear);
    if (defaultFilter) {
      Object.assign(defaultFilter, {
        otherPrograms: defaultFilter?.programYear,
      });
      this.chartDataCount = this.defineChartObj(defaultFilter);
    }

    if (window.innerWidth < 575) {
      this.isSmallerDevice = true;
    }
    this.sessionData = this.cdnService.getSessionData('studentEnrollment');
    if (this.filterData) {
      this.getSelectedValues(this.filterData, this.filterType);
    }
    this.currentWindowWidth = window.innerWidth;
  }

  // Get and arrange the data according to predefined programs, and calculate values of programs according to years
  public defineChartObj(data: any) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    const prgmData = data.programYear;
    if (prgmData && prgmData.length > 1) {
      prgmData.sort((y: any, x: any) => parseInt(y.year) - parseInt(x.year));
    }

    this.createOtherProgramDetails(
      data.otherPrograms ? data.otherPrograms : []
    );

    if (prgmData) {
      const chartLabels: any = prgmData.map((year: any) => year.year);
      const programCounts = {};

      predefinedPgms.forEach((program) => {
        programCounts[program] = prgmData.map(
          (entry) => entry.programsCount[program] || null
        );
      });

      const OTHERS: any = prgmData?.map((q: any) => {
        return Object.entries(q.programsCount)
          .filter((obj: any) => !predefinedPgms.includes(obj[0]))
          .map((b: any) => b[1])
          .reduce((accumulator: any, reducer: any) => accumulator + reducer, 0);
      });

      let chartData: any[] = Object.keys(programCounts).map((program) => ({
        labels: program,
        data: programCounts[program],
      }));

      chartData.push({ labels: 'OTHERS', data: OTHERS });

      return {
        labels: chartLabels,
        chartData,
      };
    } else {
      return null;
    }
  }
  // get selected filter data for user selected combinations
  public getSelectedValues($event: any, type: string) {
    this.selectedFilters = $event;
    let filterData: any = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['studentEnrollment'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Student_Enrollment_Filter_${$event?.Start_Year}_${$event?.Institution}_${$event?.Location}_${$event?.Hospital}`
    );
    const orderOfKeys = [
      'fiscal_year',
      'fiscal_month',
      'institution',
      'location',
      'hospital',
    ];
    if (this.filteredStudentDetails?.data?.studentEnrollments?.length === 1) {
      this.filteredStudentDetails?.data?.studentEnrollments?.push(
        ...this.sessionData?.data?.studentEnrollments
      );
    }
    this.filterLabels = this.filterLabels.map((x: any, i: number) => {
      if ($event[x.filterLabel] != null) {
        return { ...x, selected: $event[x.filterLabel] };
      }
      return { ...x, selected: 'all' };
    });
    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        JSON.parse(
          JSON.stringify(this.filteredStudentDetails?.data?.studentEnrollments)
        ),
        orderOfKeys,
        'studentEnrollment',
        this.yearFormat,
        type
      )
      .then((filter: any) => {
        this.getDataForChart(filter);
      });
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Student_Enrollment_Filter_${this.analyticsService.convertArrayToString(
          $event?.Start_Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Quarter
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  public async getDataForChart(filter: any) {
    if (filter) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(filter);
      if (filter?.filteredData) {
        let diversityInfo =
          await this.studentEnrollmentsService.createStudentEnrollmentDiversityDetails(
            JSON.parse(JSON.stringify(filter?.filteredData))
          );
        this.setDiversityDetails(diversityInfo);
      } else {
        this.setDiversityDetails(filter?.programYear);
      }
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }
  // Reset filter data
  public reset($event: any) {
    if ($event) {
      this.filterLabels = this.filterLabels?.map((x: any) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: any) {
    if ($event === 'open') {
      this.hospitalList = this.sessionData?.data.studentEnrollments;
      this.filterLabels = this.filterLabels?.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: any) {
    this.getSelectedValues(
      { ...this.selectedFilters, ...$event },
      this.filterType
    );
    const index: any = this.filterLabels?.findIndex(
      (x: any) => x.filterLabel === Object?.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(studentEnrollments: any[]) {
    const lables = studentEnrollments
      .map(({ year }) => year)
      .sort((a, b) => a - b);
    this.otherProgramDetails = this.getOtherProgramDetails(studentEnrollments);
    this.headerLabel = JSON.parse(JSON.stringify(lables));
    if (
      this.headerLabel[this.headerLabel.length - 1] == new Date().getFullYear()
    ) {
      this.headerLabel[this.headerLabel.length - 1] = `${
        this.headerLabel[this.headerLabel.length - 1]
      }* YTD`;
    }
    this.getTotalOtherProgramCount(lables, this.otherProgramDetails);
  }

  // To filter all other program details
  public getOtherProgramDetails(data) {
    const exclusionList = new Set(['RNBSN', 'RNMSN', 'MSN', 'DNP']);

    // Create an object to store counts for each program across years
    const programCounts = {};

    // Extract all years
    const years = data.map((entry) => entry.year);

    // Initialize counts for each program for all years to zero
    data.forEach((entry) => {
      Object.keys(entry.programsCount)
        .filter((program) => !exclusionList.has(program))
        .forEach((program) => {
          if (!programCounts[program]) {
            programCounts[program] = {};
            years.forEach((year) => {
              programCounts[program][year] = 0; // Initialize count to zero
            });
          }
        });
    });

    // Update counts based on available data
    data.forEach((entry) => {
      Object.entries(entry.programsCount)
        .filter(([program]) => !exclusionList.has(program))
        .forEach(([program, count]) => {
          programCounts[program][entry.year] = count;
        });
    });

    // Construct the final array of objects
    const result = Object.entries(programCounts).map(([program, counts]) => {
      if (typeof counts !== 'object' || counts === null) {
        return { program }; // Only include program if counts is not an object
      }
      return {
        program,
        ...counts, // Spread counts if it's an object
      };
    });

    return result;
  }

  public getTotalOtherProgramCount(yearsToSum, result) {
    this.totalInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
    this.totalYearWiseInfo = this.totalInfo;
    this.totalInfo.unshift('Total');
  }

  public yearReducer(filterLabel: FilterItems[]) {
    let yearLabel: any = filterLabel.find(
      (x: FilterItems) =>
        x.filterLabel === 'Year' || x.filterLabel === 'Start_Year'
    );
    if (
      yearLabel &&
      yearLabel.filterItems &&
      Array.isArray(yearLabel.filterItems)
    ) {
      yearLabel.filterItems = yearLabel.filterItems.slice(-8);
      yearLabel.filterItems.unshift('all');
    }
  }

  public checkYearFormat() {
    let tempStudentEnrollmentAll = JSON.parse(
      JSON.stringify(this.studentDynamicAll.data.studentEnrollments[0])
    );
    if (this.yearFormat) {
      delete tempStudentEnrollmentAll.calendar;
      tempStudentEnrollmentAll = {
        ...tempStudentEnrollmentAll,
        programYear: tempStudentEnrollmentAll.fiscal.programYear,
      };
      delete tempStudentEnrollmentAll.fiscal;
    } else {
      delete tempStudentEnrollmentAll.fiscal;
      tempStudentEnrollmentAll = {
        ...tempStudentEnrollmentAll,
        programYear: tempStudentEnrollmentAll.calendar.programYear,
      };
      delete tempStudentEnrollmentAll.calendar;
    }
    if (Object.keys(this.filteredStudentDetails).length == 0) {
      this.filteredStudentDetails = {
        data: {
          studentEnrollments: [tempStudentEnrollmentAll],
        },
      };
    } else {
      const index =
        this.filteredStudentDetails.data.studentEnrollments.findIndex(
          (item) => {
            const filter: ProgramDynamicFilter = item.filter;
            return (
              filter.fiscal_year == 'all' &&
              filter.institution == 'all' &&
              filter.location == 'all' &&
              filter.hospital == 'all'
            );
          }
        );
      if (index > -1) {
        this.filteredStudentDetails.data.studentEnrollments[index] =
          tempStudentEnrollmentAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }

  public applySeFilters(data) {
    const { type, filters } = data;
    this.selectedFilters = filters;
    this.filterType = type;
    if (type == 'yearly') {
      this.getSelectedValues(filters, this.filterType);
    } else if (type == 'quarterly') {
      this.getSelectedValues(filters, this.filterType);
    } else {
      this.getSelectedValues(filters, this.filterType);
    }
  }

  public openPopup(event) {
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Student Enrollment Graph`,
        time,
        sessionId
      );
    }
    this.hospitalList = this.sessionData?.data.studentEnrollments;
    const data = {
      status: true,
      title: 'Student Enrollments',
      data: this.filterLabels,
      hospitals: this.hospitalList,
    };
    this.sharedService.openSeFilterPopup.next(data);
  }

  public async setDiversityDetails(sessionData) {
    this.diversityInfo = sessionData;
  }
}
