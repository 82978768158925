<div class="card">
  <div class="programs-main-wrap">
    <div class="filter-title-wrap">
      <h5>Programs Included in Partnership</h5>
      <p>
        See the programs included in your partnership agreements by institution
        with the partner tuition rate.
      </p>
    </div>
    <div class="programs-contact-wrap">
      <p>Want to Explore More Learning Options?</p>
      <div class="border-pseudo align-items-center d-flex adtalem-contact-wrap">
        <button class="btn contact-button" (click)="openContact()">
          <span class="icon-adtalem-contacts me-2"></span>
          Contact your Strategic Partner Executives
        </button>
      </div>
    </div>
  </div>

  <div class="d-block">
    <div
      class="slider-wrap"
      [ngClass]="{ leftDis: leftNavDisabled, rightDis: rightNavDisabled }"
    >
      <div class="spinner-border" role="status" *ngIf="contactLoader"></div>
      <drag-scroll
        [scrollbar-hidden]="true"
        [drag-scroll-y-disabled]="true"
        [scroll-x-wheel-enabled]="false"
        [drag-disabled]="false"
        (reachesLeftBound)="leftBoundStat($event)"
        (reachesRightBound)="rightBoundStat($event)"
        #nav
      >
        <ng-container
          *ngFor="
            let items of partnershipProgram;
            let i = index;
            trackBy: trackByContentKey
          "
        >
          <ng-container *ngIf="items.percentage <= 99">
            <div class="slide" drag-scroll-item>
              <div class="slide-title-wrap">
                <div class="slide-top">
                  <p>{{ items.university }}</p>
                  <small>{{ items.percentage }}% Off</small>
                </div>
                <div class="slide-bottom">
                  <p title="{{ items.pgmname }}">{{ items.pgmname }}</p>
                </div>
              </div>
              <img class="slider-image" [src]="images[i]" alt="images" />
            </div>
          </ng-container>
        </ng-container>
      </drag-scroll>
      <button
        class="drag-left-btn"
        type="button"
        value=""
        [disabled]="leftNavDisabled"
        (click)="moveLeft()"
      >
        <span class="icon-arrow-down"></span>
        <span class="leftspan">left</span>
      </button>
      <button
        class="drag-right-btn"
        type="button"
        value=""
        [disabled]="rightNavDisabled"
        (click)="moveRight()"
      >
        <span class="icon-arrow-down"></span>
        <span class="rightspan">right</span>
      </button>
    </div>
  </div>
</div>

<app-popup-modal
  [scrollDirection]="'vertical'"
  [openModal]="openModal"
  [title]="'Strategic Partner Executive'"
  [subtitle]="'Contact your Strategic Partner Executives'"
  (closeModal)="closeModal($event)"
  [url]="'Consultant'"
>
  <div style="max-height: 200px">
    <div class="d-flex align-items-center justify-content-center">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="contactLoader"
      ></div>
    </div>
    <div class="d-flex align-content-around">
      <div class="card-wrap">
        <div
          class="card card-style"
          [hidden]="cards?.RoleInTerritory2 !== 'Strategic Partner Executive'"
          *ngFor="
            let cards of contactDetailObj;
            let i = index;
            trackBy: trackBycontactId
          "
        >
          <ng-container>
            <div class="card-main-wrap mt-1 mb-2">
              <div class="row">
                <div class="col card-content-wrap">
                  <div class="card-block">
                    <p class="contact-card-name">{{ cards?.User?.Name }}</p>
                  </div>
                </div>
              </div>
              <div class="card-content-wrap mb-0">
                <div class="row">
                  <div class="col">
                    <div class="card-block mb-0">
                      <p class="m-auto">Email</p>
                      <a
                        href="mailto:{{ cards.User.Email }}"
                        class="h-link font-child"
                        >{{ cards.User.Email }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-popup-modal>
