import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import { inject } from '@angular/core';
import { SharedService } from './shared/services/shared.service';

export class CustomReuseStrategy implements RouteReuseStrategy {
  public sharedService = inject(SharedService);

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig?.path === 'partnership-overview';
  }

  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle
  ): void {
    if (this.shouldDetach(route) && !this.sharedService.cacheCleared) {
      this.sharedService.routeCache.set(this.getRouteKey(route), handle);
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      this.shouldDetach(route) &&
      this.sharedService.routeCache.has(this.getRouteKey(route))
    );
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.sharedService.routeCache.get(this.getRouteKey(route)) || null;
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    return (
      future.routeConfig === current.routeConfig &&
      this.sharedService.routeCache.has(this.getRouteKey(current))
    );
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.routeConfig?.path || '';
  }
}
