import { Injectable } from '@angular/core';
import { AnticipatedGrateDate, Diversity, GraduationProgramYear, TableHeader } from '../../../../shared/components/model/customtypes-model.interface';
import { DiversityAgeHeaders, DiversityEthinicityHeaders, DiversityFilter, DiversityGenderHeaders, DiversityRaceHeaders, GraphColor, YearQuarter } from '../../enum/shared.enum';
import { DiversityHeaderTypes, DiversityType } from '../../class-type/chart-type';
import { DiversityInfo } from '../../entity/shared.entity';

@Injectable({
  providedIn: 'root'
})
export class AnticipatedGradService {

  public async createAnticipatedGradDateDiversityTableInfo(
    chartType: string,
    sessionData: GraduationProgramYear[]
  ): Promise<Diversity[]> {
    let tableData: Diversity[] = [];

    for (let filterType of Object.values(DiversityFilter)) {
      switch (filterType) {
        case DiversityFilter.ethnicity: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityEthinicityHeaders,
            'ethnicity'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.race: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityRaceHeaders,
            'race'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.gender: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityGenderHeaders,
            'gender'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        case DiversityFilter.age: {
          let diversityInfo = await this.createDiversityInfo(
            filterType,
            chartType,
            sessionData,
            DiversityAgeHeaders,
            'age'
          );

          tableData.push({ name: filterType, data: diversityInfo });
          break;
        }
        default:
          this.handleDefaultCase();
      }
    }
    return tableData;
  }


  public async createTableHeaders(
    tableHeaders: DiversityHeaderTypes
  ): Promise<TableHeader[]> {
    let headerLabels: TableHeader[] = [];
    const raceHeaderArray = Object.keys(tableHeaders).map((key, index) => ({
      name: tableHeaders[key],
      value: key,
      color: GraphColor[index],
    }));

    headerLabels = [...raceHeaderArray];
    return headerLabels;
  }

  public async createDiversityInfo(
    diversityType: DiversityFilter,
    chartType: string,
    sessionData: any,
    headers: DiversityHeaderTypes,
    objToFid: DiversityType
  ): Promise<DiversityInfo> {
    let diversityInfo = new DiversityInfo();
    let tableHeaderInfo = await this.createTableHeaders(headers);

    let tableDataInfo = await this.createProgramDiversityData(
      sessionData,
      tableHeaderInfo,
      objToFid
    );

    diversityInfo.composeDiversityData(
      diversityType,
      tableDataInfo,
      tableHeaderInfo,
      chartType
    );
    return diversityInfo;
  }

  public async createProgramDiversityData(
    data: any[],
    tableHeaderInfo: TableHeader[],
    objToFid: DiversityType
  ) {
    let filterPrgmYears: {} = {};
    let resultData: {} = {};
    data.forEach((entry) => {
      const anticipatedGradYear = entry?.anticipatedGradYear;
      if (anticipatedGradYear != null) {
        if (filterPrgmYears[anticipatedGradYear] == null) {
          filterPrgmYears[anticipatedGradYear] = {};
        }
        const anticipatedGradQuarter =
          YearQuarter[entry?.anticipatedGradQuarter];
        if (
          filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter] == null
        ) {
          filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter] = [];
        }
        filterPrgmYears[anticipatedGradYear][anticipatedGradQuarter].push(
          entry
        );
      }
    });


    Object.keys(filterPrgmYears).forEach((year: any) => {
      const yearData = filterPrgmYears[year];
      Object.keys(yearData).forEach((quarter: string) => {
        const quarterData = filterPrgmYears[year][quarter];
        const key = `${quarter} - ${year}`;
        const obj = this.summeriseArray(quarterData, objToFid);
        if (obj != null) {
          obj['year'] = year;
          obj['quarter'] = quarter;
          obj['diversityName'] = key;
          resultData[key] = obj;
        }
      });
    });

    const dataArray = Object.entries(resultData);

    // Sort the array by keys (which represent the year and quarter names)
    dataArray.sort(([keyA], [keyB]) => {
      const [quarterA, yearA] = keyA.split(' - ');
      const [quarterB, yearB] = keyB.split(' - ');

      if (yearA !== yearB) {
        return parseInt(yearA) - parseInt(yearB);
      } else {
        const quarterMap = {
          'Jan-Mar': 1,
          'Apr-Jun': 2,
          'Jul-Sep': 3,
          'Oct-Dec': 4,
        };
        return quarterMap[quarterA] - quarterMap[quarterB];
      }
    });

    const result = Object.keys(Object.fromEntries(dataArray))
      .sort((y: string, x: string) => {
        const obj1 = resultData[y];
        const obj2 = resultData[x];
        return parseInt(obj1.year) - parseInt(obj2.year);
      })
      .map((key) => {
        const { year, totalCount, quarter, ...rest } = resultData[key];
        const total = Object.values(rest).reduce(
          (partialSum: number, value: any) => {
            if (isNaN(value)) {
              return partialSum;
            }
            return partialSum + parseInt(value);
          },
          0
        );
        return { ...rest, totalCount: total };
      });
    return result;
  }

  public async createAnticipatedGradYearDiversityDetails(
    data: AnticipatedGrateDate[]
  ) {
    return data
      .flatMap((obj) => obj.programYear)
      .reduce((acc, curr: GraduationProgramYear) => {
        const existingProgram = this.findExistingProgram(acc, curr);

        if (existingProgram) {
          this.updateDiversityDetails(
            existingProgram.diversityDetails,
            curr.diversityDetails
          );
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
  }

  public findExistingProgram(
    acc: GraduationProgramYear[],
    curr: GraduationProgramYear
  ) {
    return acc.find(
      (item) =>
        item.anticipatedGradYear === curr.anticipatedGradYear &&
        item.anticipatedGradQuarter === curr.anticipatedGradQuarter
    );
  }

  public updateDiversityDetails(targetDetails, sourceDetails) {
    for (const key in sourceDetails) {
      if (Object.hasOwnProperty.call(sourceDetails, key)) {
        const element = sourceDetails[key];

        for (const subKey in element) {
          if (Object.hasOwnProperty.call(element, subKey)) {
            const value = element[subKey];
            targetDetails[key][subKey] += value;
          }
        }
      }
    }
  }

  public summeriseArray(data, objToFid) {
    const result = {};
    data.forEach((row) => {
      const obj = row['diversityDetails'][objToFid];
      Object.keys(obj).forEach((key) => {
        if (result[key] != null) {
          result[key] = result[key] + obj[key];
        } else {
          result[key] = obj[key];
        }
      });
    });
    return result;
  }

  public handleDefaultCase() {
    return [];
  }
}
