<div *ngIf="productTourActive || productTourNotNowActive"></div>
<section
  class="produce-tour-main-wrap"
  [ngClass]="{ ProdActive: productTourActive }"
  *ngIf="!hideButtons"
>
  <div class="product-tour-wrap">
    <div class="tour-icon"><span class="icon-Product-tour"></span></div>
    <div class="tour-details-wrap">
      <p>Hi, Liking Partner Portal ?</p>
      <b>Want to see what all you’ve got here ?</b>
    </div>
  </div>
  <div class="product-tour-btn-wrap">
    <button class="btn" (click)="notNowPopupOpen()">Not Now</button>
    <button class="btn btn-primary" (click)="productTourPopupOpen()">
      Take a tour
    </button>
  </div>
</section>

<section
  class="not-now-main-wrap"
  [ngClass]="{ NotNowActive: productTourNotNowActive }"
>
  <div class="not-now-product-tour">
    <img alt="not now" [src]="imageUrl" />
  </div>
  <div class="not-now-wrap">
    <div class="not-now-globe-wrap">
      <div class="tour-icon"><span class="icon-Product-tour"></span></div>
    </div>
    <div class="not-now-content-wrap">
      <p>No problem, visit product tour anytime from profile</p>
      <span class="icon-close-icon" (click)="notNowPopupClose()"></span>
    </div>
  </div>
</section>
