import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BehaviorSubject, map, Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class FirestoreService{
    public adminUser:BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public adminUser$ = this.adminUser.asObservable();

    constructor(private firestore: AngularFirestore, private afAuth: AngularFireAuth) { }
  // Method to get users list from Firestore
  public getUsers(): Observable<any[]> {
    return this.firestore.collection('users').valueChanges();
  }

  public getUserRole(uid: string): Observable<string | undefined> {
    return this.firestore.collection('users').doc(uid).valueChanges().pipe(
      map((user: any) => user?.role)
    );
  }

  // Method to get a user by email
  public getUserByEmail(email: string): Observable<any[]> {
    return this.firestore.collection('users', ref => ref.where('email', '==', email))
      .valueChanges();
  }
  
}