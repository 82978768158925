<div class="student-enrollments-wrap">
  <div class="filter-main-wrap">
    <div class="filter-title-wrap">
      <h5>Student Enrollment</h5>
      <p>
        Employees who have started with<br />
        Chamberlain University or Walden University
      </p>
    </div>
    <div class="d-flex flex-column align-self-lg-stretch filter-sec">
      <div class="filter d-flex justify-content-end align-items-center">
        <app-pp-button
          #button
          [loading]="loading"
          label="Filter"
          (click)="openPopup($event)"
        >
        </app-pp-button>
      </div>
    </div>
  </div>
  <app-filter-chips
    class="chip-container mt-3 mb-3"
    (emitRemovedFilter)="closedFilters($event)"
    [selectedFilters]="selectedFilters"
  ></app-filter-chips>
  <app-info-graphic
    [chartData]="chartDataCount"
    [chartType]="'bar'"
    [otherProgramDetails]="otherProgramDetails"
    [totalInfo]="totalInfo"
    [totalYearWiseInfo]="totalYearWiseInfo"
    [selectedFilters]="selectedFilters"
  >
  </app-info-graphic>
</div>
