import { Injectable } from '@angular/core';
import {
  Program,
  ChartOtherProgramInfo,
  TableHeader,
  OtherProgramTitle,
} from '../components/model/customtypes-model.interface';
import { ChartType, StudenProgramTooltip } from './enum/shared.enum';
import { OtherProgramInfo } from './entity/shared.entity';
import { SharedService } from './shared.service';
@Injectable({
  providedIn: 'root',
})
export class OtherProgramService {
  constructor(public sharedService: SharedService) {}

  public getProgramWithContent(): Program[] {
    return [
      {
        name: 'RNBSN',
        content: StudenProgramTooltip.rnbsn,
      },
      {
        name: 'RNMSN',
        content: StudenProgramTooltip.rnmsn,
      },
      {
        name: 'MSN',
        content: StudenProgramTooltip.msn,
      },
      {
        name: 'DNP',
        content: StudenProgramTooltip.dnp,
      },
      {
        name: 'Other',
        content: StudenProgramTooltip.other,
      },
    ];
  }

  public createTableHeaderNames(headerInfo: string[]): TableHeader[] {
    let headerLabels: TableHeader[] = [];
    let customHeader: TableHeader = {
      name: 'Program Name',
      value: 'program',
    };
    headerLabels.push(customHeader);

    if (headerInfo.length) {
      headerInfo.forEach((header) => {
        let customHeader: TableHeader = {
          name: header,
          value: header.toString().includes('* YTD')
            ? header.toString().replace('* YTD', '')
            : header,
        };
        headerLabels.push(customHeader);
      });
    }

    return headerLabels;
  }

  public assignTheNullValueToZero(headerInfo: TableHeader[], dataInfo) {
    const lables = headerInfo.map(({ value }) => value);

    return dataInfo.map((obj) => {
      // Use reduce to add the missing keys with a value of zero
      return lables.reduce(
        (acc, key) => {
          // If key is missing, add it with value 0, otherwise, keep the existing value
          acc[key] = obj.hasOwnProperty(key) ? obj[key] : 0;
          return acc;
        },
        { ...obj }
      ); // Spread operator to retain other properties of the object
    });
  }

  public bindDataToStorage(
    otherProgramTitleInfo: OtherProgramTitle,
    otherProgramDetails: ChartOtherProgramInfo[],
    labelInfo: string[],
    totalInfo: any[],
    totalYearWiseInfo: number[],
    isNeedSummary: boolean,
    isNeedScroll: boolean
  ) {
    this.sharedService.otherProgramData.next({});
    let updatedLabelInfo = this.createTableHeaderNames(labelInfo);
    let updatedOtherProgramDetails = this.assignTheNullValueToZero(
      updatedLabelInfo,
      otherProgramDetails
    );
    let otherProgramInfo = new OtherProgramInfo();
    otherProgramInfo.composeOtherProgramData(
      updatedOtherProgramDetails,
      updatedLabelInfo,
      totalInfo,
      updatedOtherProgramDetails.length,
      totalYearWiseInfo,
      isNeedSummary,
      isNeedScroll
    );
    otherProgramInfo.setPopupTitle(
      otherProgramTitleInfo.popupTitle,
      otherProgramTitleInfo.popupTotalTitle
    );
    this.sharedService.otherProgramData.next(otherProgramInfo);
  }
}
