import * as XLSX from 'xlsx';
import {
  Organization,
  UserData,
} from '../../components/model/customtypes-model.interface';

export function getFormattedCurrentDateTime(): string {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear().toString();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

export function convertDateToCustomFormat(dateTimeStr: string): string {
  const [datePart, timePart] = dateTimeStr.split(' ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');

  return `${month}${day}${year}${hours}${minutes}${seconds}`;
}

export function jsonToCsv(jsonData: any[]): string {
  const csvRows: string[] = [];

  const headers = [
    'Organization Name',
    'User Name',
    'User Email',
    'Events',
    'Timespan',
    'Session ID',
  ];
  csvRows.push(headers.join(','));
  const allEvents: any[] = [];

  jsonData.forEach((item) => {
    const { logEvent } = item;
    if (Array.isArray(logEvent.events)) {
      logEvent.events.forEach((event: any) => {
        allEvents.push({
          orgName: logEvent.orgName,
          userName: logEvent.userName,
          email: logEvent.email,
          action: event.action,
          time: event.time,
          sessionId: event.sessionId,
        });
      });
    } else {
      const event = logEvent.events;
      allEvents.push({
        orgName: logEvent.orgName,
        userName: logEvent.userName,
        email: logEvent.email,
        action: event.action,
        time: event.time,
        sessionId: event.sessionId,
      });
    }
  });
  allEvents.sort((a, b) => {
    const [monthA, dayA, yearA, hourA, minuteA, secondA] = a.time
      .match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/)
      .slice(1);
    const [monthB, dayB, yearB, hourB, minuteB, secondB] = b.time
      .match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/)
      .slice(1);

    let dateA: any = new Date(
      `${yearA}-${monthA}-${dayA}T${hourA}:${minuteA}:${secondA}`
    );
    let dateB: any = new Date(
      `${yearB}-${monthB}-${dayB}T${hourB}:${minuteB}:${secondB}`
    );
    return dateB - dateA;
  });
  allEvents.forEach((event) => {
    const row: string[] = [
      event.orgName,
      event.userName,
      event.email,
      event.action,
      event.time,
      event.sessionId,
    ];
    csvRows.push(row.join(','));
  });

  return csvRows.join('\n');
}

export function convertJsonToCsv(json: UserData): string {
  const headers = ['Organizations', 'Users'];
  const csvRows: string[] = [headers.join(',')];

  json.data.forEach((item: Organization) => {
    item.users.forEach((user: string) => {
      const row = [item.orgName, user];
      csvRows.push(row.join(','));
    });
  });

  return csvRows.join('\n');
}

export function convertJsonToExcel(json: UserData): Blob {
  const data = json.data.flatMap((item: Organization) =>
    item.users.map((user: string) => ({
      Organization: item.orgName,
      Users: user,
    }))
  );

  const headers = ['Organization', 'Users'];

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
    header: headers,
  });
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Users per Organization');

  const wbout: ArrayBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
  });
  return new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
}

export function convertEventJsonToExcel(json: any): Blob {
  const data = json
    .map((item: any) => {
      const logEvent = item.logEvent;
      const events = Array.isArray(logEvent.events)
        ? logEvent.events
        : [logEvent.events];

      return events.map((event: any) => ({
        Organization: logEvent.orgName,
        'User Name': logEvent.userName,
        'User Email': logEvent.email,
        Events: event.action,
        Time: event.time,
        'Session ID': event.sessionId,
      }));
    })
    .flat();

  data.sort((a, b) => {
    const [monthA, dayA, yearA, hourA, minuteA, secondA] = a.Time.match(
      /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/
    ).slice(1);
    const [monthB, dayB, yearB, hourB, minuteB, secondB] = b.Time.match(
      /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/
    ).slice(1);

    let dateA: any = new Date(
      `${yearA}-${monthA}-${dayA}T${hourA}:${minuteA}:${secondA}`
    );
    let dateB: any = new Date(
      `${yearB}-${monthB}-${dayB}T${hourB}:${minuteB}:${secondB}`
    );
    return dateB - dateA;
  });
  const headers = [
    'Organization',
    'User Name',
    'User Email',
    'Events',
    'Timespan',
    'Session ID',
  ];
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
    header: headers,
  });
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Log Events');

  const wbout: ArrayBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
  });
  return new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
}
