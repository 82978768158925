<div
  class="half-doughnut-main-wrap"
  [ngClass]="{ lightColor: graphLightColor }"
>
  <div class="custom-legend">
    <ul>
      <li *ngFor="let item of programInfo">
        <span></span>
        <div>
          <small>{{ item.content }}</small
          >{{ item.name }}
        </div>
        <div
          *ngIf="item.name == 'Other'"
          class="question-align pointer-cursor margin-left-10 question-icon-container"
          (click)="openOtherProgramPopup()"
        >
          <span class="question-icon-wraper icon-Others-Icon"> </span>
        </div>
      </li>
    </ul>
  </div>
  <div class="canvas">
    <canvas
      baseChart
      class="stacked-half-doughnut-chart"
      [data]="chartData"
      [plugins]="chartPlugin"
      [options]="chartOption"
      [type]="chartType"
      width="100%"
    >
    </canvas>
  </div>
</div>
