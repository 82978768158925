import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { ApiService } from '../../../../shared/services/api.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { CommonComponent } from '../common-component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ApplicantsToEntrollment } from '../../../../shared/services/enum/shared.enum';
import { CdnService } from '../../../../shared/services/cdn.service';
import { getFormattedCurrentDateTime } from '../../../../shared/services/functional-handler/functional-handler';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import {
  ApplicantsToEnrollment,
  ApplicantsToEnrollmentAllData,
  ApplicantsToEnrollmentDynamic,
  FilterData,
  FilterItems,
  ProgramDynamicFilter,
} from '../../../../shared/components/model/customtypes-model.interface';

@Component({
  selector: 'app-applicant-enrollment',
  templateUrl: './applicants-to-enrollment.component.html',
  styleUrls: ['./applicants-to-enrollment.component.scss'],
})
export class ApplicantsToEnrollmentComponent
  extends CommonComponent
  implements OnInit
{
  public chartDataCount: any;
  public selectedFilters: any;
  public filterCombinations: any = {};
  public filterLabels: any;
  public loading: boolean;
  public graphLightColor;
  public yearFormat: boolean = false;
  public applicantDynamicAll: ApplicantsToEnrollmentAllData;
  public appToEntrolInfo: any[] = [
    {
      name: 'Enrollments',
      content: ApplicantsToEntrollment.entrollment,
    },
    {
      name: 'Applicants',
      content: ApplicantsToEntrollment.applicants,
    },
  ];
  public otherProgramDetails: any[] = [];
  public totalInfo: any[] = [];
  public labelInfo: any[] = [];
  public filterData: FilterData;
  public userName: string;
  public hospitalList: ApplicantsToEnrollment[];
  public applicantsSessionData: ApplicantsToEnrollmentDynamic;

  constructor(
    apiService: ApiService,
    tss: TransferStateService,
    ar: ActivatedRoute,
    private sharedService: SharedService,
    private analytics: AngularFireAnalytics,
    private cdnService: CdnService,
    private analyticsService: AnalyticsService
  ) {
    super(apiService, tss, ar);
  }

  @Input() set applicantsToEnrollmentData(data: any) {
    if (data) {
      this.filterData = data;
    }
  }

  public ngOnInit(): void {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.filterLabels = this.sharedService.removeYearFromFilter(
        this.cdnService.appendSelectedAllToFilterData(
          'applicantsToEnrollmentFilter'
        ),
        format
      );
    });

    // Getting filter data for applicantsToEnrollment
    this.applicantDynamicAll = this.cdnService.getTheInitialAllFilterData(
      'applicantsToEnrollmentAll'
    );
    // Getting the Fiscal or Calendar Year Format
    this.getYearFormat();

    const defaultFilter =
      this.filterCombinations?.data?.ApplicantsToEnrollments[0];
    this.chartDataCount = this.defineChartObj(
      defaultFilter?.ApplicantsToEnrollment
    );

    this.sharedService.lightColorMode$.subscribe((NavVal: any) => {
      this.graphLightColor = NavVal === true ? true : false;
    });
    this.applicantsSessionData = this.cdnService.getSessionData(
      'applicantsToEnrollment'
    );
    if (this.filterData) {
      this.getSelectedValues(this.filterData);
    }
  }
  // Get and arrange the data according to predefined programs, and calculate values of Enrollment, Applications
  public defineChartObj(data: any) {
    const predefinedPgms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];
    this.createOtherProgramDetails(data ? data : []);

    if (data && Array.isArray(data)) {
      // Calculate total for others program except predefinedPgms
      const otherPrograms = data.reduce((accumulator, currentProgram) => {
        if (!predefinedPgms.includes(currentProgram.program)) {
          if (!accumulator['Other']) {
            accumulator['Other'] = {
              Enrollment: 0,
              Applicants: 0,
            };
          }
          accumulator['Other'].Enrollment +=
            currentProgram.programCount.Enrollment;
          accumulator['Other'].Applicants +=
            currentProgram.programCount.Applicants;
        }
        return accumulator;
      }, {});
      // --Calculate total for predefinedPgms program
      const chartLabels: any = [...predefinedPgms, 'Other'];

      const chartData = predefinedPgms.map((program) => {
        const entry = data.find((item) => item.program === program);
        return entry
          ? { data: entry.programCount }
          : { data: { Enrollment: 0, Applicants: 0 } };
      });

      // --Adding other program count in chart label and data
      chartData.push({ data: otherPrograms.Other });
      return {
        labels: chartLabels,
        chartData,
      };
    } else {
      return {
        labels: [],
        chartData: [],
      };
    }
  }
  public async getDataForChart(data: any) {
    if (data) {
      this.loading = false;
      this.chartDataCount = this.defineChartObj(data.ApplicantsToEnrollment);
    } else {
      this.loading = false;
      this.chartDataCount = null;
    }
  }

  // get selected filter data for user selected combinations
  public getSelectedValues($event: any) {
    this.selectedFilters = $event;
    let filterData: any = localStorage.getItem('filters');
    if (filterData == null) {
      filterData = '{}';
    }
    let selectedFilters = JSON.parse(filterData);
    selectedFilters['applicantsToEnrollment'] = { ...this.selectedFilters };
    localStorage.setItem('filters', JSON.stringify(selectedFilters));
    this.loading = true;
    this.analytics.logEvent(
      `Applicant_to_enrollments_filter_${$event.Year}_${$event?.Institution}_${$event?.Location}`
    );
    const orderOfKeys = ['fiscal_year', 'institution', 'location', 'hospital'];
    if (this.filterCombinations?.data?.ApplicantsToEnrollments?.length === 1) {
      this.filterCombinations?.data?.ApplicantsToEnrollments?.push(
        ...this.applicantsSessionData?.data?.ApplicantsToEnrollments
      );
      this.filterLabels = this.filterLabels.map((x: any, i: number) => {
        return { ...x, selected: Object.values($event)[i] };
      });
    }

    this.sharedService
      .getSelectedFilterData(
        this.selectedFilters,
        JSON.parse(
          JSON.stringify(this.filterCombinations?.data?.ApplicantsToEnrollments)
        ),
        orderOfKeys,
        'applicantsEnrollment',
        this.yearFormat
      )
      .then((data: any) => {
        this.getDataForChart(data);
      });
    const orgDetails: any = JSON.parse(localStorage.getItem('userDetailId'));
    this.userName = `${orgDetails[0].FirstName} ${orgDetails[0].LastName}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const email: string = user.email;
    const orgName: string = localStorage.getItem('organization');
    const time: string = getFormattedCurrentDateTime();
    const sessionId: string = localStorage.getItem('sessionId');
    if (localStorage.getItem('providerId') === 'firebase') {
      this.analyticsService.addAnalytics(
        this.userName,
        email,
        orgName,
        `Applicants_to_enrollments_filter_${this.analyticsService.convertArrayToString(
          $event?.Year
        )}_${this.analyticsService.convertArrayToString(
          $event?.Institution
        )}_${this.analyticsService.convertArrayToString(
          $event?.Location
        )}_${this.analyticsService.convertArrayToString($event?.Hospital)}`,
        time,
        sessionId
      );
    }
  }
  // Reset filter data
  public reset($event: any) {
    if ($event) {
      this.filterLabels = this.filterLabels.map((x: any) => {
        return { ...x, selected: 'all' };
      });
    }
  }
  // Display the filter labels in column according to the number of filter labels
  public getState($event: any) {
    if ($event === 'open') {
      this.hospitalList =
        this.applicantsSessionData?.data.ApplicantsToEnrollments;
      this.filterLabels = this.filterLabels.map(
        (filterItems: FilterItems, i: number) => {
          if (i === 0) {
            return { ...filterItems, column: 'col-2' };
          } else if (i === 3) {
            return { ...filterItems, column: 'col-4' };
          } else {
            return { ...filterItems, column: 'col-3' };
          }
        }
      );
    }
  }

  public closedFilters($event: any) {
    this.getSelectedValues({ ...this.selectedFilters, ...$event });
    const index: any = this.filterLabels.findIndex(
      (x: any) => x.filterLabel === Object.keys($event)[0]
    );
    this.filterLabels[index].selected = 'all';
  }

  public createOtherProgramDetails(applicantsEnrollments: any[]) {
    this.otherProgramDetails = this.getOtherProgramDetails(
      applicantsEnrollments
    );
  }

  public getOtherProgramDetails(data) {
    const excludedPrograms = ['RNBSN', 'RNMSN', 'MSN', 'DNP'];

    return data
      .filter((entry) => !excludedPrograms.includes(entry.program))
      .map((entry) => {
        let conversionRate = 0;

        if (entry.programCount.Applicants > 0) {
          conversionRate = parseInt(
            (
              (entry.programCount.Enrollment / entry.programCount.Applicants) *
              100
            ).toFixed(0)
          );
        }
        let conversionRateInPercentage = conversionRate.toString().concat(`%`);
        Object.assign(entry, { Percentage: conversionRateInPercentage });
        const { programCount, ...rest } = entry;
        return { ...rest, ...programCount };
      });
  }

  public getTotalOtherProgramCount(yearsToSum, result) {
    this.totalInfo = yearsToSum.map((year) => {
      const sum = result.reduce((acc, obj) => acc + (obj[year] || 0), 0);
      return sum;
    });
    this.totalInfo.unshift('Total');
  }

  public checkYearFormat() {
    let tempApplicantsToEnrollmentAll = JSON.parse(
      JSON.stringify(this.applicantDynamicAll.data.ApplicantsToEnrollments[0])
    );
    if (this.yearFormat) {
      delete tempApplicantsToEnrollmentAll.calendar;
      tempApplicantsToEnrollmentAll = {
        ...tempApplicantsToEnrollmentAll,
        ApplicantsToEnrollment:
          tempApplicantsToEnrollmentAll.fiscal.ApplicantsToEnrollment,
      };
      delete tempApplicantsToEnrollmentAll.fiscal;
    } else {
      delete tempApplicantsToEnrollmentAll.fiscal;
      tempApplicantsToEnrollmentAll = {
        ...tempApplicantsToEnrollmentAll,
        ApplicantsToEnrollment:
          tempApplicantsToEnrollmentAll.calendar.ApplicantsToEnrollment,
      };
      delete tempApplicantsToEnrollmentAll.calendar;
    }
    if (Object.keys(this.filterCombinations).length == 0) {
      this.filterCombinations = {
        data: {
          ApplicantsToEnrollments: [tempApplicantsToEnrollmentAll],
        },
      };
    } else {
      const index =
        this.filterCombinations.data.ApplicantsToEnrollments.findIndex(
          (item) => {
            const filter: ProgramDynamicFilter = item.filter;
            return (
              filter.fiscal_year == 'all' &&
              filter.institution == 'all' &&
              filter.location == 'all' &&
              filter.hospital == 'all'
            );
          }
        );
      if (index > -1) {
        this.filterCombinations.data.ApplicantsToEnrollments[index] =
          tempApplicantsToEnrollmentAll;
      }
    }
  }

  public getYearFormat() {
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      this.yearFormat = format;
      this.checkYearFormat();
    });
  }

  @HostListener('document:salesForceLogin', ['$event'])
  public onSalesforceLogin() {
    this.ngOnInit();
  }
}
