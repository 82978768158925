import {
  ChartOtherProgramInfo,
  CustomCardConfig,
  TableHeader,
} from '../../components/model/customtypes-model.interface';
import {
  ChartType,
  CustomCardDefaultOrder,
  CustomCardWebDefaultOrder,
} from '../enum/shared.enum';

export class OtherProgramInfo {
  public popupTitle: string = '';
  public otherPrograms: ChartOtherProgramInfo[] = [];
  public labelInfo: TableHeader[] = [];
  public isNeedSummary: boolean = false;
  public totalInfo: any[] = [];
  public totalProgramCount: number = 0;
  public totalYearWiseInfo: any[] = [];
  public isNeedScroll: boolean = false;
  public popupTotalTitle: string = '';

  public composeOtherProgramData(
    otherPrograms: ChartOtherProgramInfo[],
    labelInfo: TableHeader[],
    totalInfo: any[],
    totalProgramCount: number,
    totalYearWiseInfo: any[],
    isNeedSummary: boolean,
    isNeedScroll: boolean
  ) {
    this.otherPrograms = otherPrograms;
    this.labelInfo = labelInfo;
    this.isNeedSummary = isNeedSummary;
    this.totalInfo = totalInfo;
    this.totalProgramCount = totalProgramCount;
    this.totalYearWiseInfo = totalYearWiseInfo;
    this.isNeedScroll = isNeedScroll;
  }

  public setPopupTitle?(popupTitle: ChartType, popupTotalTitle: string) {
    this.popupTitle = popupTitle;
    this.popupTotalTitle = popupTotalTitle;
  }
}

export class DiversityInfo {
  public diversityInfo: any[] = [];
  public labelInfo: TableHeader[] = [];
  public diversityType: string;
  public graphTitle: string = '';

  public composeDiversityData?(
    chartType: string,
    diversityInfo: any[],
    labelInfo: TableHeader[],
    graphTitle: string
  ) {
    this.diversityType = chartType;
    this.diversityInfo = diversityInfo;
    this.labelInfo = labelInfo;
    this.graphTitle = graphTitle;
  }
}

export class ArrowChartOptions {
  public chartOptionXMin: number = 0;
  public chartOptionXMax: number = 0;
  public isDisableLeftArrow: boolean = false;
  public isDisableRightArrow: boolean = true;
  public isDisableArrows: boolean = false;

  public setChartMinMax?(chartOptionXMin: number, chartOptionXMax: number) {
    this.chartOptionXMin = chartOptionXMin;
    this.chartOptionXMax = chartOptionXMax;
  }

  public setChartArrow?(
    isDisableLeftArrow: boolean,
    isDisableRightArrow: boolean,
    isDisableArrows?: boolean
  ) {
    this.isDisableLeftArrow = isDisableLeftArrow;
    this.isDisableRightArrow = isDisableRightArrow;

    if (isDisableArrows != null && isDisableArrows != undefined) {
      this.isDisableArrows = isDisableArrows;
    }
  }
}

export class GeneralConfigurarion {
  public isLightColorMode: boolean = true;
  public isFiscalYear: boolean = false;
  public loggedInCount: number = 0;
  public name: string;
  public email: string;
  public items: CustomCardConfig[] = Object.keys(CustomCardDefaultOrder).map(
    (key, index) => ({
      id: index,
      order: index,
      component:
        CustomCardDefaultOrder[key as keyof typeof CustomCardDefaultOrder],
    })
  );
  public webItems: CustomCardConfig[] = Object.keys(
    CustomCardWebDefaultOrder
  ).map((key, index) => ({
    id: index,
    order: index,
    component:
      CustomCardWebDefaultOrder[key as keyof typeof CustomCardWebDefaultOrder],
  }));

  public setColorMode?(isLightColorMode: boolean) {
    this.isLightColorMode = isLightColorMode;
  }

  public setUserInfo(name: string, email: string) {
    this.name = name;
    this.email = email;
  }

  public setCardConfig?(items: CustomCardConfig[]) {
    this.items = items;
  }
}
