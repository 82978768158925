<div id="partner-dashboard">
  <ng-container *ngIf="pageLoad">
    <ng-container
      *ngIf="
        !router.url.includes('login') &&
        !router.url.includes('forgotPassword') &&
        !router.url.includes('verifyEmail')
      "
    >
      <app-header class="d-block" #header></app-header>
    </ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>
